import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
    TextField, Button, Checkbox, Typography, Paper, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import CobDocVerification from './CobDocVerification/CobDocVerification';
import RiseLoading from '../Loader/RiseLoader';
import { CSVLink } from "react-csv";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;  // Use onPageChange here

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </>
    );
}


const ClientOnBoarding = () => {
    const [refNo, setRefNo] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [verificationDocsData, setVerificationDocsData] = useState([]);
    const [verificationPopUp, setVerificationPopUp] = useState(false);
    const [docByRefId, setDocByRefId] = useState([]);
    const [showLoader, setShowLoader] = useState();
    const [opsRemarks, setOpsRemarks] = useState('');
    const [openOpsRemarks, setOpenOpsRemarks] = useState(false);
    const [remRefId, setRemRefId] = useState();
    const [opsRemarksById, setOpsRemarksById] = useState([]);
    const [openViewOpsRemarks, setOpenViewOpsRemarks] = useState(false);
    const [gfsId, setGfsId] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        viewVerificationDashboard();
    }, [])

    useEffect(() => {
        setFilteredData(verificationDocsData);
    }, [verificationDocsData])

    const viewVerificationDashboard = async () => {
        setShowLoader(true);
        const headers = {
            "Content-Type": "application/json",
        };

        await axios
            .get(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/LeadClients/GetAll",
                { headers }
            )
            .then((res) => {
                setVerificationDocsData(res.data);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log('Error:', err);
                setShowLoader(false);
            });
    }


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSearch = () => {
        setPage(0);
        let filtered = verificationDocsData;

        if (refNo) {
            filtered = filtered.filter((doc) =>
                doc.id.toString().includes(refNo)
            );
        }

        if (fromDate && toDate) {
            const from = new Date(fromDate);
            const to = new Date(toDate);

            from.setHours(0, 0, 0, 0);
            to.setHours(23, 59, 59, 999);

            filtered = filtered.filter((doc) => {
                const docDate = new Date(doc.addedOn);
                docDate.setHours(0, 0, 0, 0);

                return docDate >= from && docDate <= to;
            });
        }

        setFilteredData(filtered);
    };


    const handleReset = () => {
        setRefNo('');
        setFromDate('');
        setToDate('');
        setFilteredData(verificationDocsData);
    };

    const handleRefNoClick = async (id, gfsID) => {

        if (gfsID) {
            setGfsId(gfsID + "-" + id);
            setVerificationPopUp(true);
            setDocByRefId([]);
        } else {
            setShowLoader(true);
            const headers = {
                "Content-Type": "application/json",
            };

            await axios
                .get(
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/LeadClients/GetLeadWithDocs?refId=" + id,
                    { headers }
                )
                .then((res) => {
                    setDocByRefId(res.data);
                })
                .catch((err) => {
                    console.log('Error:', err);
                    setShowLoader(false);
                });
        }
    };


    useEffect(() => {
        if (docByRefId && docByRefId.leadClients) {
            setVerificationPopUp(true);
            setShowLoader(false);
        }
    }, [docByRefId])

    const handleAddRemClick = (id) => {
        setOpsRemarks("");
        setShowLoader(true);
        setRemRefId(id)
    }

    useEffect(() => {
        if (remRefId && remRefId > 0) {
            setShowLoader(false);
            setOpenOpsRemarks(true)
        }
    }, [remRefId])

    const saveOpsRemarks = async () => {

        setOpenOpsRemarks(false)

        const headers = {
            "Content-Type": "application/json",
        };

        await axios
            .post(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/LeadClientRemark/Insert",
                JSON.stringify({
                    refId: remRefId,
                    remark: opsRemarks
                }),
                { headers }
            )
            .then((res) => {
                setOpenOpsRemarks(false);
                setOpsRemarks("")
                viewVerificationDashboard();
            })
            .catch((err) => {
                console.log('Error:', err);
                setShowLoader(false);
            });
    }

    const handleViewRemClick = async (id) => {
        setShowLoader(true);
        const headers = {
            "Content-Type": "application/json",
        };

        await axios
            .get(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/LeadClientRemark/GetByRefId?RefId=" + id,
                { headers }
            )
            .then((res) => {
                setShowLoader(false);
                setOpsRemarksById(res.data);
                setOpenViewOpsRemarks(true);
            })
            .catch((err) => {
                console.log('Error:', err);
                setOpenViewOpsRemarks(true);
                setShowLoader(false);
            });
    }

    // useEffect(() => {
    //     if (opsRemarksById) {
    //         setOpenViewOpsRemarks(true);
    //     }
    // }, [opsRemarksById])

    const [csvData, setCsvData] = useState([]);
    const [readyToDownload, setReadyToDownload] = useState(false);

    const headers = [
        { label: "SR no.", key: "srNo" },
        { label: "Reference No.", key: "id" },
        { label: "Date", key: "addedOn" },
        { label: "Dox Rec", key: "docRec" },
        { label: "Dox Verified", key: "isVerified" },
        { label: "Active", key: "active" },
        { label: "Live", key: "live" },
        { label: "Status", key: "status" },
        { label: "Client Remarks", key: "clientRemark" },
        { label: "Agency Name", key: "orgName" }
    ];

    const prepareCSVData = () => {
        const data = filteredData.map((row, index) => ({
            srNo: index + 1,
            id: row.id,
            addedOn: formatDate(row.addedOn),
            docRec: row.docRec ? "Yes" : "No",
            isVerified: row.isVerified ? "Yes" : "No",
            active: row.active ? "Yes" : "No",
            live: row.live ? "Yes" : "No",
            status:
                row.isVerified && row.active ? (
                    row.live ? (
                        "Onboarding Completed"
                    ) : (
                        "Pending with CSG"
                    )
                ) : (
                    "Pending with Accounts"
                ),
            clientRemark: row.clientRemark || "",
            orgName: row.orgName || ""
        }));
        setCsvData(data);
        setReadyToDownload(true);
    };

    useEffect(() => {
        if (readyToDownload) {
            document.getElementById('csv-download-link').click();
            setReadyToDownload(false);
        }
    }, [readyToDownload]);

    const handleDownload = () => {
        prepareCSVData();
    };


    return (
        <Box p={3}>
            <h4 className='mb-4'>Client Onboarding Queue</h4>
            {/* <div className='mt-2 mb-3 text-bold' style={{}}>Client Onboarding Queue</div> */}
            <Box display="flex" gap={2} alignItems="center" mb={3}>
                <TextField
                    label="Ref No."
                    variant="outlined"
                    size="small"
                    className="rounded bg-light border"
                    value={refNo}
                    onChange={(e) => setRefNo(e.target.value)}
                />
                <Typography>OR</Typography>
                <TextField
                    label="From Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    className="rounded bg-light border"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                />
                <TextField
                    label="To Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    className="rounded bg-light border"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                />
                <Button
                    className='srn-btn'
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                >
                    Search
                </Button>
                <Button
                    className='srn-btn'
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                >
                    Reset
                </Button>
                <Button
                    className='srn-btn'
                    variant="contained"
                    color="secondary"
                    onClick={handleDownload}
                >
                    Download
                </Button>

                <CSVLink
                    headers={headers}
                    data={csvData}
                    filename={`report_${new Date().toISOString()}.csv`}
                    id="csv-download-link"
                    style={{ display: 'none' }}
                />

            </Box>

            <TableContainer component={Paper} className="border border-3 rounded">
                <Table className='table table-bordered'>
                    <TableHead>
                        <TableRow>
                            <TableCell>SR no.</TableCell>
                            <TableCell>Reference No. & Date</TableCell>
                            <TableCell align='center'>Dox Rec</TableCell>
                            <TableCell align='center'>Dox Verified</TableCell>
                            <TableCell align='center'>Active</TableCell>
                            <TableCell align='center'>Live</TableCell>
                            <TableCell>OPS Remarks</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Client Remarks</TableCell>
                            <TableCell>Agency Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData && filteredData.length > 0 ? (
                            filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow key={row.id} >
                                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                        <TableCell>
                                            <Box display="flex" flexDirection="column">
                                                <Typography style={{ cursor: "pointer", color: "#9f155a" }} onClick={() => handleRefNoClick(row.id, row.gfsId)}>{row.id}</Typography>
                                                <Typography>{formatDate(row.addedOn)}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align='center' onClick={() => handleRefNoClick(row.id, row.gfsId)}>
                                            {row.docRec ? <span>&#10004;</span> : <span style={{ fontSize: "18px" }}>&#10799;</span>}
                                        </TableCell >
                                        <TableCell align='center' onClick={() => handleRefNoClick(row.id, row.gfsId)}>
                                            {row.isVerified ? <span>&#10004;</span> : <span style={{ fontSize: "18px" }}>&#10799;</span>}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {row.active ? <span>&#10004;</span> : <span style={{ fontSize: "18px" }}>&#10799;</span>}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {row.live ? <span>&#10004;</span> : <span style={{ fontSize: "18px" }}>&#10799;</span>}
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" flexDirection="column">
                                                <Typography color="primary" style={{ fontSize: "14px", cursor: "pointer" }} onClick={() => handleAddRemClick(row.id)}>+ Add rem</Typography>
                                                <Typography variant="caption" color="textSecondary" style={{ cursor: "pointer" }} onClick={() => handleViewRemClick(row.id)}>View Rem</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {row.isVerified && row.active ? (
                                                row.live ? (
                                                    "Onboarding Completed"
                                                ) : (
                                                    "Pending with CSG"
                                                )
                                            ) : (
                                                "Pending with Accounts"
                                            )}
                                        </TableCell>
                                        <TableCell>{row.clientRemark}</TableCell>
                                        <TableCell>{row.orgName}</TableCell>
                                    </TableRow>
                                ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <Typography align="center">No records found</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                ActionsComponent={TablePaginationActions}
            />

            <Dialog className="" style={{ inset: "40px" }} open={verificationPopUp} onClose={() => setVerificationPopUp(false)} maxWidth="md" fullWidth={!!(docByRefId && docByRefId.leadClients)} >
                <DialogTitle>
                    <div className='d-flex justify-content-between'>
                        {docByRefId && docByRefId.leadClients ? (<div>Client Onboarding - Document Verification</div>) : (<div></div>)}
                        <div><Button onClick={() => setVerificationPopUp(false)} color="inherit">
                            <CloseIcon />
                        </Button>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className=''>
                    {docByRefId && docByRefId.leadClients ?
                        (<CobDocVerification
                            docByRefId={docByRefId}
                            formatDate={formatDate}
                            setVerificationPopUp={setVerificationPopUp}
                            viewVerificationDashboard={viewVerificationDashboard}
                            handleRefNoClick={handleRefNoClick}
                        />) :
                        (
                            <>
                                <div className='p-4'>
                                    GFS ID <strong>{gfsId && gfsId.split("-")[0]}</strong> Already Created for RefID: <strong>{gfsId && gfsId.split("-")[1]}</strong>
                                </div>
                            </>
                        )
                    }
                </DialogContent>
            </Dialog>
            <Dialog className="terms-dialog" open={openOpsRemarks} onClose={() => setOpenOpsRemarks(false)} maxWidth="md" >
                <DialogTitle>
                    <div className='d-flex justify-content-between'>
                        <div>Add Remarks</div>
                        <div><Button onClick={() => setOpenOpsRemarks(false)} color="inherit">
                            <CloseIcon />
                        </Button></div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        className='rounded border'
                        multiline
                        fullWidth
                        style={{ height: "200px", minWidth: "500px" }}
                        value={opsRemarks}
                        onChange={(e) => setOpsRemarks(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <div>
                        <div></div>
                        <div>
                            <Button onClick={saveOpsRemarks} color="inherit" className='srn-btn'>
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog className="terms-dialog" open={openViewOpsRemarks} onClose={() => setOpenViewOpsRemarks(false)} maxWidth="lg" fullWidth >
                <DialogTitle>
                    <div className='d-flex justify-content-between'>
                        <div>View Remarks</div>
                        <div><Button onClick={() => setOpenViewOpsRemarks(false)} color="inherit">
                            <CloseIcon />
                        </Button></div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Table className='table table-bordered'>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Sr. No</TableCell>
                                <TableCell align="left">Remark</TableCell>
                                <TableCell align="left">Added By</TableCell>
                                <TableCell align="left">Added Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {opsRemarksById.length > 0 ? (
                                opsRemarksById.map((remark, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left">{index + 1}</TableCell>
                                        <TableCell align="left">{remark.remark}</TableCell>
                                        <TableCell align="left">{remark.added_By}</TableCell>
                                        <TableCell align="left">{new Date(remark.added_Date).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">No remarks found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>


                </DialogContent>
            </Dialog>
            {showLoader && <RiseLoading />}
        </Box>
    );
};

export default ClientOnBoarding


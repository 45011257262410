import React, { useEffect, useState } from "react";
import "./ClientDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { IconButton, InputAdornment } from "@material-ui/core";
import countries from "../../../data/Country";
import TimeZones from "../../../data/TimeZones";
import Currencies from "../../../data/Currencies";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { Link } from "react-router-dom";
import AntTabs from "../../../hooks/useTabsStyles";
import AntTab from "../../../hooks/useTabStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import UserTable from "../../ManageUsers/UserTable/UserTable";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import useConfigurationTableCell from "../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../hooks/useConfigurationTableRow";
// import Alert from "@material-ui/lab/Alert";
import Chip from "@material-ui/core/Chip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import PaymentCycleModel from "../PaymentCycleModel/PaymentCycleModel";
import ClientPayment from "../ClientPayment/ClientPayment";
import SyncIcon from "@mui/icons-material/Sync";
import { checkIfUserStaff, isUserHasPermission } from "../../../services/AuthService";
import Snackbar from "@material-ui/core/Snackbar";
import RiseLoading from "../../Loader/RiseLoader";
import MuiAlert from "@material-ui/lab/Alert";
import AddUser from "../../ManageUsers/AddUser/AddUser";
import Drawer from "@material-ui/core/Drawer";
import MultiEmailProvider from "../../../providers/MultiEmail/MultiEmailProvider";
import airlineDetails from "../../../data/AirlineDetails";
import supplier from "../../../data/Supplier";
import PasswordStrengthBar from "react-password-strength-bar";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Dialog from "@mui/material/Dialog";
import Otp from "../../Login/Otp/Otp";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="ml-8 mr-8">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MustContainItem = (props) => {
  const { data } = props;
  const label = data[0];
  const meetsReq = data[1];

  const setClass = () => {
    const classArr = ["must-line"];
    if (meetsReq) classArr.push("cross-out");
    return classArr.join(" ");
  };

  return (
    <div className="MustContainItem">
      <div className="must-item">
        <li className="must-text">{label}</li>
        <div className={setClass()}></div>
      </div>
    </div>
  );
};

export default function ClientDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
  searchCategories,
  l1CacheGroups,
  searchComGroup,
  markupGroup,
  AlluserProfiles,
  pccList,
  paymentgatewayList,
  clientClick,
  value,
  setValue,
  addUserdrawer,
  setaddUserdrawer,
  railSearchCategories,
  railMarkupGroup,
  discountGroup,
  serviceFeesGroup,
  supplierCommissionGroups,
}) {
  const flag = "client";
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [userAction, setUserAction] = useState("");
  const [clientId, setclientId] = useState(0);
  const [ClientType, setClientType] = useState("");
  const [clientName, setclientName] = useState("");
  const [clientCategory, setClientCategory] = useState("");
  const [Email, setEmail] = useState("");
  const [subUserEmail, setSubUserEmail] = useState("");
  const [AlternativeEmail, setAlternativeEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Currency, setCurrency] = useState("");
  const [searchCategory, setsearchCategory] = useState(0);
  const [suppCommissionGroup, setsuppCommissionGroup] = useState(0);
  const [l1CacheGroup, setL1CacheGroup] = useState(0);
  const [markupGroupId, setMarkupGroup] = useState(0);
  const [Address1, setAddress1] = useState("");
  const [TimeZone, setTimeZone] = useState("");
  const [country, setcountry] = useState("");
  const [displayCountry, setDisplayCountry] = useState("");
  const [Address2, setAddress2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [isActive, setisActive] = useState("false");
  const [isActive1, setIsActive1] = useState();
  const [railSearchCategoty, setRailSearchCategoty] = useState("");
  const [railMarkup, setRailMarkup] = useState("");
  const [discount, setDiscount] = useState("");
  const [serviceFees, setServiceFees] = useState("");
  const [railSupplierCommissionGroup, setRailSupplierCommissionGroup] = useState("");

  const [accountsTab, setAccountsTab] = useState(0);
  const [invalidEmails, setinvalidEmails] = useState([]);
  const [alterEmailError, setAlterEmailError] = useState(false);
  const [addUserAction, setAddUserAction] = useState("view");
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState("0");
  // const [paymentgatewayList, setPaymentGatewayList] = useState([]);

  const accountsTabHandleChange = (event, newTabValue) => {
    setAccountsTab(newTabValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [userProfiles, setuserProfiles] = useState(null);

  const classes = useStyles();
  const [selectedUserItem, setSelectedUserItem] = useState();
  const [page, setPage] = React.useState(1);

  const [region, setRegion] = useState("");
  const [PaymentType, setPaymentType] = useState("");
  const [Applied, setApplied] = useState("Include");
  const [ClientRefId, setClientRefId] = useState("");
  const [GoLiveDate, setGoLiveDate] = useState("");
  const [QuoteSLA, setQuoteSLA] = useState(0);
  const [TicketSLA, setTicketSLA] = useState(0);
  const [CancellationQuoteSLA, setCancellationQuoteSLA] = useState(0);
  const [CancellationSLA, setCancellationSLA] = useState(0);
  const [VoidSLA, setVoidSLA] = useState(0);
  const [ReIssueQuoteSLA, setReIssueQuoteSLA] = useState(0);
  const [ReIssueSLA, setReIssueSLA] = useState(0);

  const [AccountManagerId, setAccountManagerId] = useState("");
  const [SalseManagerId, setSalseManagerId] = useState("");
  const [OperationManagerId, setOperationManagerId] = useState("");

  const [csTaxName, setcsTaxName] = useState("");
  const [csTaxType, setcsTaxType] = useState("Percentage");
  const [csTaxValue, setcsTaxValue] = useState("");
  const [csTaxApplicableOn, setcsTaxApplicableOn] = useState("Base");

  const [SecondaryCurrencyCode, setSecondaryCurrencyCode] = useState("");

  const [PAN, setPAN] = useState("");
  const [GST, setGST] = useState("");
  const [AadharCard, setAadharCard] = useState("");
  const [OwnerShipCertificate, setOwnerShipCertificate] = useState("");
  const [PaymentCycle, setPaymentCycle] = useState("");
  const [StatementDate, setStatementDate] = useState("");

  const [VoidingFee, setVoidingFee] = useState("0.0");
  const [IssuenceFee, setIssuenceFee] = useState("0.0");
  const [AdminFee, setAdminFee] = useState("0.0");
  const [CancellationFee, setCancellationFee] = useState("0.0");
  const [ClientApprovalStatus, setClientApprovalStatus] =
    useState("ClientRegistered");
  const [message, setMessage] = useState("");
  const [saveStatusCode, setSaveStatusCode] = useState("");
  const [IsResStatus, setResStatus] = useState(false);
  const [filesList, setFilesList] = useState(null);
  const [historyList, setHistoryList] = useState(null);
  const [holdBookings, setHoldBookings] = useState("false");
  const [holdBookingChargeRules, setHoldBookingChargeRules] = useState(false);
  const [holdBookingCharges, setHoldBookingCharges] = useState(0);
  const [holdBookingChargesType, setholdBookingChargesType] = useState(false);
  const [iBossBooksCountry, setIBossBooksCountry] = useState("");

  const [basePCC, setBasePcc] = useState("");
  const [accountBalanceId, setaccountBalanceId] = useState("1");
  const [iBossRefId, setIBossRefId] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [documentType, setdocumentType] = useState();
  const [apiClientId, setApiClientId] = useState("");
  const [searchComOptions, setsearchComOptions] = useState([]);
  const [searchCatOptions, setsearchCatOptions] = useState([]);
  const [l1CacheOptions, setL1CacheOptions] = useState([]);
  const [markupOptions, setmarkupOptions] = useState([]);
  const [railSearchCatOption, setRailSearchCatOption] = useState([]);
  const [railMarkupOption, setRailMarkupOption] = useState([]);
  const [discountGroupOption, setDiscountGroupOption] = useState([]);
  const [serviceFeesGroupOptions, setServiceFeesGroupOptions] = useState([]);
  const [railSupplierCommissionGroupOptions, setRailSupplierCommissionGroupOptions] = useState([]);

  const [checked, setChecked] = React.useState({
    flight: true,
    rail: false,
  });
  const handleCheckboxChange = (event) => {
    if (event.target.name === 'flight') {
      setChecked({ flight: true, rail: false, })
    }
    else {
      setChecked({ flight: false, rail: true, })
    }
    // setChecked((prevState) => {
    //   return { ...prevState, [event.target.name]: event.target.checked };
    // });
    // if (checked.flight === true && checked.rail === true) {
    //   setProductType('Flight,Rail')
    // } else {
    //   setProductType(`${checked.flight === true ? 'Flight' : ""}${checked.rail === true ? 'Rail' : ""}`)
    // }
  };
  const openAddUserDrawer = (item) => {
    setSelectedUserItem(item);
    setaddUserdrawer(true);
  };
  const [emails, setEmails] = useState([]);
  const [isLiveTicket, setisLiveTicket] = useState(false);
  const [isRailServiceActive, setIsRailServiceActive] = useState('false');
  const [isRailServiceLive, setIsRailServiceLive] = useState('false');

  const statusOptions = [
    { type: "Active", value: "true" },
    { type: "In-Active", value: "false" },
  ];
  const ClientCategoryLists = [
    "Soto",
    "Local"
  ]
  const clientTypeList = [
    "API",
    "GFS",
    "GSA",
    "PSA",
    "Branch",
    "Corporate",
    "Demo",
  ];
  const paymentTypeList = ["Prepaid", "Postpaid"];
  const appliedList = ["Include", "Exclude"];
  const calTypes = ["Percentage", "Value"];
  const applicableOnList = [
    "Base",
    "Tax",
    "Total",
    "Opportunity",
    "ServiceFee",
    "Commission",
    "CommissionAndServiceFee",
  ];
  const taxList = ["AIT", "GST", "Other", "VAT"];
  const regions = [
    { label: "APEC", key: "APEC" },
    { label: "EMEA", key: "EMEA" },
    { label: "LAD", key: "LAD" },
    { label: "NA", key: "NA" },
    { label: "None", key: "" },
  ];
  const [showLoader, setShowLoader] = useState(false);
  const [holdLabelText, setHoldLabeltext] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [alerttext, setalerttext] = useState("");
  const [isApprovalBtnDisabled, setisApprovalBtnDisabled] = useState(false);
  const [isuploadBtnDisabled, setisuploadBtnDisabled] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [suppliers, setSuppliers] = useState([]);
  const [airlines, setAirlines] = useState([]);
  const [holdDays, setHoldDays] = useState("");
  const [importPNRConfig, setImportPNRConfig] = useState(false);
  const [gds, setGDS] = useState([]);
  const [gdsPCC, setGdsPcc] = useState("");
  const gdsSupplier = ["Sabre", "Galileo", "Amadeus"];
  const [passthroughConfig, setPassthroughConfig] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [title, setTitle] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [productType, setProductType] = useState('');

  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters
  const [passwordMatch, setPasswordMatch] = useState(false); // passwords match
  const [isTAC, setTAC] = useState(false);
  const [rolename, setRolename] = useState("");
  const [maxDob, setMaxDob] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [subUserStatus, setSubUserStatus] = useState(false);
  const [eye, seteye] = useState(true);
  const [DOBerror, setDOBerror] = useState(true);
  const [allNotValid, setAllNotValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [otpPopUp, setOtpPopUp] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [resOtpTime, setResOtpTime] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [expired, setExpired] = useState(0);
  const [resMsg, setResMsg] = useState("");
  const [notValid, setNotValid] = useState(false);
  const [disableOtpInput, setDisableOtpInput] = useState(false);
  const [retryCount, setRetryCount] = useState(false);


  const mustContainData = [
    ["An uppercase letter (A-Z)", containsUL],
    ["A lowercase letter (a-z)", containsLL],
    ["A number (0-9)", containsN],
    ["A special character (!@#$)", containsSC],
    ["At least 8 characters", contains8C],
    ["Passwords match", passwordMatch],
  ];

  const validatePassword = () => {


    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    let hasMinLength = false;
    let passwordsMatch = false;

    // has uppercase letter
    if (password.toLowerCase() !== password) hasUppercase = true;

    // has lowercase letter
    if (password.toUpperCase() !== password) hasLowercase = true;

    // has number
    if (/\d/.test(password)) hasNumber = true;

    // has special character
    if (/[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password)) hasSpecialChar = true;

    // has 8 characters
    if (password.length >= 8) hasMinLength = true;

    // passwords match
    if (password !== "" && password === confirmPassword) passwordsMatch = true;

    // Update state variables based on local variables
    setContainsUL(hasUppercase);
    setContainsLL(hasLowercase);
    setContainsN(hasNumber);
    setContainsSC(hasSpecialChar);
    setContains8C(hasMinLength);
    setPasswordMatch(passwordsMatch);

    // all validations passed
    if (hasUppercase && hasLowercase && hasNumber && hasSpecialChar && hasMinLength && passwordsMatch) {
      setAllNotValid(false);
    } else {
      setAllNotValid(true);
    }
  };

  const titleOptions = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Ms', label: 'Ms' },
  ];

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  async function getRoles() {
    if (clientId > 0) {
      try {
        const clientRolesResponse = await axios.get(
          `${process.env.REACT_APP_CORE_URL}api/Configuration/ClientPermissions/GetClientRoles/${clientId}`
        );

        let rolesArr = [];
        if (clientRolesResponse.data.length > 0) {
          rolesArr = clientRolesResponse.data.map(role => ({
            roleId: role.roleId,
            name: role.roleName
          }));
          setRoleList(rolesArr);


          const hasFinanceTeamRole = clientRolesResponse.data.some(role => role.roleName === "FinanceTeamRole");

          if (!hasFinanceTeamRole) {
            const commonUserRolesResponse = await axios.get(
              `${process.env.REACT_APP_CORE_URL}api/Configuration/ClientPermissions/FetchCommonUserRoles/${clientId}`
            );
          }
        } else {
          const commonUserRolesResponse = await axios.get(
            `${process.env.REACT_APP_CORE_URL}api/Configuration/ClientPermissions/FetchCommonUserRoles/${clientId}`
          );
        }
      } catch (error) {
        console.error("Error fetching roles:", error);

        try {
          const commonUserRolesResponse = await axios.get(
            `${process.env.REACT_APP_CORE_URL}api/Configuration/ClientPermissions/FetchCommonUserRoles/${clientId}`
          );
        } catch (fetchError) {
          console.error("Error fetching common user roles:", fetchError);
        }
      }
    }
  }


  function isImportPNRHandler(e) {
    var isImportPNR = e.target.value === "true" ? true : false;

    setImportPNRConfig(isImportPNR);
  }

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  const toggleAddUserDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setaddUserdrawer(value);
  };
  async function getClients() {
    if (clientId > 0) {
      let filter = "ClinetId==" + clientId;
      await axios
        .get(
          process.env.REACT_APP_IDENTITY_SERVER +
          "ids/User?page=" +
          (page === 0 ? 1 : page) +
          "&pageSize=50&filters=" +
          filter
        )
        .then((response) => {
          setuserProfiles(response.data);
          getHistory();
          //loadPaymentDetails();
          getFiles();
        });
    }
  }


  async function getFiles() {
    let filter = "RefId==" + selectedItem.clientpforile.id.toString();
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/File?filters=" +
        filter
      )
      .then((response) => {
        setFilesList(response.data);
      });
  }

  useEffect(() => {
    setMessage("");
    setFile("");
    setFileName("");
    setResStatus(false);
    setdocumentType("");
    setEmails();
    if (action === "edit" || action === "view") {
      setValue(0);
      setclientId(selectedItem.clientpforile.id);
      setClientType(selectedItem.clientpforile.clientType);
      setClientCategory(selectedItem.clientpforile.clientCategory);
      setclientName(selectedItem.clientpforile.clientName);
      setEmail(selectedItem.clientpforile.email);
      setAlternativeEmail(selectedItem.clientpforile.alternativeEmail);
      setPhoneNumber(selectedItem.clientpforile.phoneNumber);
      setCurrency(selectedItem.clientpforile.currencyCode);
      setHoldLabeltext(
        "Hold Charges in " + selectedItem.clientpforile.currencyCode
      );
      setTimeZone(selectedItem.clientpforile.timeZone);
      setcountry(selectedItem.clientpforile.countryCode);
      const item = countries.find((v) => v.code === country) || {};
      setDisplayCountry(item.label);
      setsearchCategory(Number(selectedItem.clientpforile.searchCategoryIds));
      setsuppCommissionGroup(
        Number(selectedItem.clientpforile.supplierCommissionGroupId)
      );
      setMarkupGroup(Number(selectedItem.clientpforile.markupGroupId));
      setAddress1(selectedItem.clientpforile.address1);
      setAddress2(selectedItem.clientpforile.address2);
      setcity(selectedItem.clientpforile.city);
      setstate(selectedItem.clientpforile.state);
      setpostalCode(selectedItem.clientpforile.postalCode);
      setisActive(selectedItem.clientpforile.isActive.toString());
      setisLiveTicket(selectedItem.clientpforile.isLiveTicket.toString());
      setIsRailServiceLive(selectedItem.clientpforile.isRailServiceLive.toString());
      setIsRailServiceActive(selectedItem.clientpforile.isRailServiceActive.toString());
      setRegion(selectedItem.clientpforile.region);
      setPaymentType(selectedItem.clientpforile.paymentType);
      setApplied(selectedItem.clientpforile.applied);
      setClientRefId(selectedItem.clientpforile.clientRefId);
      setGoLiveDate(selectedItem.clientpforile.goLiveDate);
      setQuoteSLA(selectedItem.clientpforile.quoteSLA);
      setTicketSLA(selectedItem.clientpforile.ticketSLA);
      setCancellationQuoteSLA(selectedItem.clientpforile.cancellationQuoteSLA);
      setCancellationSLA(selectedItem.clientpforile.cancellationSLA);
      setVoidSLA(selectedItem.clientpforile.voidSLA);
      setReIssueQuoteSLA(selectedItem.clientpforile.reIssueQuoteSLA);
      setReIssueSLA(selectedItem.clientpforile.reIssueSLA);
      setAccountManagerId(selectedItem.clientpforile.accountManagerId);
      setSalseManagerId(selectedItem.clientpforile.salseManagerId);
      setOperationManagerId(selectedItem.clientpforile.operationManagerId);

      setcsTaxName(selectedItem.clientpforile.csTaxName);
      setcsTaxType(selectedItem.clientpforile.csTaxType);
      setcsTaxValue(selectedItem.clientpforile.csTaxValue);
      setcsTaxApplicableOn(selectedItem.clientpforile.csTaxApplicableOn);

      setSecondaryCurrencyCode(
        selectedItem.clientpforile.secondaryCurrencyCode
      );
      setPAN(selectedItem.clientpforile.pan);
      setGST(selectedItem.clientpforile.gst);
      setAadharCard(selectedItem.clientpforile.AadharCard);
      setOwnerShipCertificate(selectedItem.clientpforile.ownerShipCertificate);
      setPaymentCycle(selectedItem.clientpforile.paymentCycle);
      setStatementDate(selectedItem.clientpforile.statementDate);
      setVoidingFee(selectedItem.clientpforile.voidingFee);
      setIssuenceFee(selectedItem.clientpforile.issuenceFee);
      setAdminFee(selectedItem.clientpforile.adminFee);
      setCancellationFee(selectedItem.clientpforile.cancellationFee);
      setClientApprovalStatus(selectedItem.clientpforile.clientApprovalStatus);
      setHoldBookings(selectedItem.clientpforile.holdBookings === true ? "true" : "false");


      setholdBookingChargesType(
        selectedItem.clientpforile.holdCharge > 0 ? true : false
      );
      setHoldBookingCharges(selectedItem.clientpforile.holdCharge);
      setHoldBookingChargeRules(
        selectedItem.clientpforile.isHoldChargeRefundable
      );
      setIBossBooksCountry(selectedItem?.clientpforile?.iBossBooksCountry ? selectedItem?.clientpforile?.iBossBooksCountry : "None");
      setSelectedPaymentGateway(selectedItem.clientpforile.paymentGateWayListId);
      setBasePcc(selectedItem.clientpforile.basePCC);
      setaccountBalanceId(selectedItem.clientpforile.accountBalanceId);
      setIBossRefId(selectedItem.clientpforile.iBossRefId);
      setApiClientId(selectedItem.clientpforile.apiClientId);
      setImportPNRConfig(selectedItem.clientpforile.clientImportPNR);
      setGDS(selectedItem.clientpforile.supplierNameType);
      setGdsPcc(selectedItem.clientpforile.pcc);
      setPassthroughConfig((selectedItem.clientpforile.ccPassThrough).toString());
      setL1CacheGroup(selectedItem.clientpforile.l1CacheGroupId);

      setRailSearchCategoty(selectedItem.clientpforile.railSearchCategoryIds);
      setRailMarkup(selectedItem.clientpforile.railMarkupGroupId);
      setDiscount(selectedItem.clientpforile.discountGroupId);
      setServiceFees(selectedItem.clientpforile.serviceFeeGroupId);
      setRailSupplierCommissionGroup(selectedItem.clientpforile.railSupplierCommissionGroupId);

      if (
        selectedItem?.clientpforile?.supplierName !== undefined &&
        selectedItem?.clientpforile?.supplierName !== "" && selectedItem?.clientpforile?.supplierName !== "null"
      ) {
        let list = [];
        selectedItem?.clientpforile?.supplierName
          .split(",")
          .map((air) =>
            list.push(supplier.find((el) => el.label == air.trim()))
          );
        setSuppliers(list);
      } else {
        setSuppliers([]);
      }

      if (
        selectedItem?.clientpforile?.airlineName !== undefined &&
        selectedItem?.clientpforile?.airlineName !== ""
      ) {
        let list = [];
        selectedItem?.clientpforile?.airlineName
          .split(",")
          .map((air) =>
            list.push(airlineDetails.find((el) => el.Code == air.trim()))
          );
        setAirlines(list);
      } else {
        setAirlines([]);
      }

      if (selectedItem?.clientpforile && selectedItem?.clientpforile.productType && selectedItem?.clientpforile.productType === "Rail") {
        setChecked({ flight: false, rail: true });
      }

      setHoldDays(selectedItem.clientpforile.holdPeriod);
      refreshSubUserForm();
      // getClients();
    } else {
      setValue(0);
      setclientId(selectedItem ? selectedItem.clientpforile.id : 0);
      setClientType("");
      setClientCategory("");
      setclientName("");
      setEmail("");
      setAlternativeEmail("");
      setPhoneNumber("");
      setCurrency("");
      setsearchCategory(0);
      setsuppCommissionGroup(0);
      setMarkupGroup(0);
      setAddress1("");
      setTimeZone("");
      setcountry("");
      setAddress2("");
      setcity("");
      setstate("");
      setpostalCode("");
      setisActive("false");
      setisLiveTicket("false");
      setIsRailServiceActive("false");
      setIsRailServiceLive("false");
      setRegion("");
      setPaymentType("");
      setApplied("Include");
      setClientRefId("");
      setGoLiveDate("");
      setQuoteSLA(0);
      setTicketSLA(0);
      setCancellationQuoteSLA(0);
      setCancellationSLA(0);
      setVoidSLA(0);
      setReIssueQuoteSLA(0);
      setReIssueSLA(0);
      setAccountManagerId("");
      setSalseManagerId("");
      setOperationManagerId("");
      setcsTaxName("");
      setcsTaxType("Percentage");
      setcsTaxValue("0.00");
      setcsTaxApplicableOn("Base");
      setSecondaryCurrencyCode("");
      setPAN("");
      setGST("");
      setAadharCard("");
      setOwnerShipCertificate("");
      setPaymentCycle("");
      setStatementDate("");
      setVoidingFee("");
      setIssuenceFee("");
      setAdminFee("");
      setCancellationFee("");
      setClientApprovalStatus("ClientRegistered");
      setHoldBookings("false");
      setHoldBookingChargeRules(false);
      setHoldBookingCharges(0);
      setholdBookingChargesType(false);
      setIBossBooksCountry("None");
      setBasePcc("");
      setIBossRefId("");
      setaccountBalanceId(1);
      setApiClientId("");
      setuserProfiles(null);
      setImportPNRConfig(false);
      setGDS([]);
      setGdsPcc("");
      setSuppliers("");
      setAirlines("");
      setHoldDays("");
      setPassthroughConfig("false");
      setL1CacheGroup(0);
      setRailSearchCategoty("");
      setDiscount("");
      setServiceFees("");
      setRailMarkup("");
      setRailSupplierCommissionGroup("");
      setChecked({ flight: true, rail: false });
    }
    let temp1 = [],
      temp2 = [],
      temp3 = [],
      temp4 = [],
      temp5 = [],
      temp6 = [],
      temp7 = [],
      temp8 = [];

    let obj = {
      name: "None",
      id: 0,
    };

    temp1.push(obj);
    temp2.push(obj);
    temp3.push(obj);
    temp4.push(obj);
    temp5.push(obj);
    temp6.push(obj);
    temp7.push(obj);
    temp8.push(obj);
    searchCategories?.map((categoria) => {
      temp1.push(categoria.searchCategories);
    });
    searchComGroup?.map((comm) => {
      temp2.push(comm.supplierCommissionGroups);
    });
    markupGroup?.map((markup) => {
      temp3.push(markup.markupGroup);
    });

    railSearchCategories?.map((cat) => {
      temp4.push(cat.searchCategories);
    })
    railMarkupGroup?.map((markup) => {
      temp5.push(markup.markupGroup);
    })
    discountGroup?.map((discount) => {
      temp6.push(discount.discountGroup);
    })
    serviceFeesGroup?.map((serviceFee) => {
      temp7.push(serviceFee.serviceFeeGroup);
    })
    supplierCommissionGroups?.map((supplierCommission) => {
      temp8.push(supplierCommission.supplierCommissionGroups);
    })

    setsearchComOptions(temp2);
    setsearchCatOptions(temp1);
    setmarkupOptions(temp3);
    setRailSearchCatOption(temp4);
    setRailMarkupOption(temp5);
    setDiscountGroupOption(temp6);
    setServiceFeesGroupOptions(temp7);
    setRailSupplierCommissionGroupOptions(temp8);

  }, [action, displayCountry, clientId, clientClick, selectedItem]);

  useEffect(() => {
    getRoles();
    getClients();
  }, [clientId])


  //,searchCatOptions,searchComOptions
  function handleRegisterUser(mode) {
    const user = JSON.parse(localStorage.getItem("user"));

    const headers = {
      "Content-Type": "application/json",
    };
    let url =
      process.env.REACT_APP_IDENTITY_SERVER + "ids/User/RegisterNewUser/";

    axios
      .post(
        url,
        JSON.stringify({
          email: subUserEmail,
          isStaff: clientId > 0 ? false : true,
          clientId: clientId,
          status: subUserStatus === "true" ? true : false,
          firstName: firstName,
          lastName: lastName,
          dob: dob,
          contactNumber: contactNumber,
          title: title,
          password: password,
          IsEmailConfirmed: true,
          UserId: user["user_id"],
          OTP: (otp.join('') === '' || mode === "new") ? 0 : otp.join(''),
        }),
        {
          headers,
        }
      )
      .then((res) => {
        console.log('res', res);
        if (res.data.message && res.data.statusCode !== 200) {
          if (res.data.message.toLowerCase().includes("invalid otp")) {
            setResMsg("Invalid OTP");
            setOtp(new Array(4).fill(''));
            setNotValid(true);
          }
          else if ((res.data.message).toLowerCase().includes("retry count:")) {
            setResMsg("Too many incorrect OTP attempts. Please try after sometime");
            localStorage.setItem("otpFail", true);
            setDisableOtpInput(true);
            setRetryCount(true);
            setOtp(new Array(4).fill(''));
            setNotValid(true);
          }
          else {
            setSaveStatusCode(res.data.statusCode);
            setMessage(res.data.message);
          }
        }
        else {
          if (res.data.message.includes("Not Activated") && otp.join('') === '' && (subUserStatus === "true" || subUserStatus)) {
            const dateTimeString = res.data.message.slice(14);
            const expiryTime = new Date(dateTimeString);
            const expiryTimeUTC = new Date(expiryTime.getTime() - expiryTime.getTimezoneOffset() * 60000);
            setResOtpTime(expiryTimeUTC);
            setOtpPopUp(true);
            setShowOtp(true);
          }
          else if (otp.join('') !== '' && (subUserStatus === "true" || subUserStatus)) {
            setOtpPopUp(false);
            setOtp(new Array(4).fill(''));
            setMessage(res.data.message);
            setSaveStatusCode(res.data.statusCode);
            updateUserRole(res.data.userId);
            setResStatus(true);
            setAction("");
            // getClients(); 
            // setTimeout(refreshSubUserForm, 5000);
          }
          else {
            setMessage(res.data.message);
            setSaveStatusCode(res.data.statusCode);
            updateUserRole(res.data.userId);
            setResStatus(true);
            // getClients();
            setTimeout(refreshSubUserForm, 5000);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message.toLowerCase().includes("invalid otp")) {
          setResMsg("Invalid OTP");
          setOtp(new Array(4).fill(''));
          setNotValid(true);
        }
        else {
          getClients();
          setMessage(err.response.data.message);
          setResStatus(false);
        }
      });
  }

  async function updateUserRole(userId) {
    const user = JSON.parse(localStorage.getItem("user"));

    const headers = {
      "Content-Type": "application/json",
    };
    let url = process.env.REACT_APP_IDENTITY_SERVER + "ids/UserRoles/AddRolesToUser/" + userId + "/" + user["user_id"] + "/" + role + "/" + isActive + "/" + (otp.join('') === '' ? 0 : otp.join(''));

    axios
      .put(url,
        JSON.stringify({}),
        {
          headers,
        }
      )
      .then((res) => {

        handleSnackbarToggle('User detail updated successfully', 'success');
        // getClients();

      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
        setAction("view");
        // handleSnackbarToggle(err.response.data.detail,'error');
      });

  }


  const refreshSubUserForm = () => {
    setSubUserEmail("");
    setSubUserStatus(false);
    setFirstName("");
    setLastName("");
    setDob("");
    setContactNumber("");
    setTitle("");
    setPassword("");
    setRole([]);
    setConfirmPassword("");
    setContainsUL(false);
    setContainsLL(false);
    setContainsN(false);
    setContainsSC(false);
    setContains8C(false);
    setPasswordMatch(false);
    setMessage("");
  }

  useEffect(() => {
    if (AlternativeEmail && AlternativeEmail.length == 0) {
      setinvalidEmails([]);
      setAlterEmailError(false);
    }
  }, [AlternativeEmail]);


  async function handleSubmit() {
    setisApprovalBtnDisabled(true);
    const headers = {
      "Content-Type": "application/json",
    };
    if (action === "edit" || action === "view") {

      setShowLoader(true);
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/ClientProfile/" +
          selectedItem.clientpforile.id,
          JSON.stringify({
            id: selectedItem.clientpforile.id,
            clientType: ClientType,
            clientCategory: clientCategory,
            clientName: clientName,
            email: Email,
            alternativeEmail: AlternativeEmail,
            phoneNumber: PhoneNumber,
            logoUrl: "",
            currencyCode: Currency,
            timeZone: TimeZone,
            searchCategoryIds:
              searchCategory === null
                ? "0"
                : isNaN(searchCategory.toString()) ? "0" : searchCategory.toString(),
            supplierCommissionGroupId:
              suppCommissionGroup === null
                ? "0"
                : isNaN(suppCommissionGroup.toString()) ? "0" : suppCommissionGroup.toString(),
            markupGroupId:
              markupGroupId === null
                ? "0"
                : isNaN(markupGroupId.toString()) ? "0" : markupGroupId.toString(),
            address1: Address1,
            address2: Address2,
            apiClientId: selectedItem.clientpforile.apiClientId,
            state: state,
            postalCode: postalCode,
            countryCode: country,
            city: city,
            isActive: isActive == "true" ? true : false,
            isLiveTicket: isLiveTicket == "true" ? true : false,
            createdBy: selectedItem.clientpforile.createdBy,
            created: selectedItem.clientpforile.created,
            region: region,
            paymentType: PaymentType,
            applied: Applied,
            goLiveDate: new Date(),
            quoteSLA: QuoteSLA,
            ticketSLA: TicketSLA,
            cancellationQuoteSLA: CancellationQuoteSLA,
            cancellationSLA: CancellationSLA,
            voidSLA: VoidSLA,
            reIssueQuoteSLA: ReIssueQuoteSLA,
            reIssueSLA: ReIssueSLA,
            accountManagerId: AccountManagerId,
            salseManagerId: SalseManagerId,
            operationManagerId: OperationManagerId,
            countrySpecificTaxName: csTaxName === undefined ? "" : csTaxName,
            countrySpecificTaxType: csTaxType === undefined ? "" : csTaxType,
            countrySpecificTaxValue:
              csTaxValue === undefined ? "0.00" : csTaxValue,
            countrySpecificTaxApplicableOn:
              csTaxApplicableOn === undefined ? "Base" : csTaxApplicableOn,
            secondaryCurrencyCode: SecondaryCurrencyCode,
            pan: PAN,
            gst: GST,
            AadharCard: AadharCard,
            ownerShipCertificate: OwnerShipCertificate,
            paymentCycle: PaymentCycle,
            statementDate: StatementDate,
            voidingFee: VoidingFee,
            issuenceFee: IssuenceFee,
            adminFee: AdminFee,
            cancellationFee: CancellationFee,
            clientApprovalStatus: ClientApprovalStatus,
            iBossRefId: iBossRefId === "" ? "0" : iBossRefId,
            holdBookings:
              holdBookings === "true"
                ? true
                : holdBookings === "false"
                  ? false
                  : holdBookings,
            supplierName: handleArrayToString(suppliers, "label"),


            airlineName: handleArrayToString(airlines, "Code"),

            holdPeriod: parseInt(holdDays),
            holdCharge: holdBookingCharges !== 0 ? holdBookingCharges : 0,
            isHoldChargeRefundable:
              holdBookingChargeRules === "true"
                ? true
                : holdBookingChargeRules === "false"
                  ? false
                  : holdBookingChargeRules,
            iBossBooksCountry: iBossBooksCountry ? iBossBooksCountry : "None",
            paymentGateWayListId: selectedPaymentGateway === undefined ?
              0 : selectedPaymentGateway,
            //paymentgatewayList: paymentgatewayList,
            basePCC: basePCC,
            accountBalanceId: accountBalanceId,

            clientImportPNR: importPNRConfig,
            supplierNameType: importPNRConfig == false ? 0 : gds,
            pcc: importPNRConfig == false ? "" : gdsPCC,
            ccPassThrough: passthroughConfig === "true" ? true : false,
            l1CacheGroupId: l1CacheGroup,
            productType: checked.flight ? "Flight" : "Rail",
            isRailServiceActive: isRailServiceActive === "true" ? true : false,
            isRailServiceLive: isRailServiceLive === "true" ? true : false,
            railSearchCategoryIds: railSearchCategoty?.toString(),
            railMarkupGroupId: railMarkup?.toString(),
            railSupplierCommissionGroupId: railSupplierCommissionGroup?.toString(),
            discountGroupId: discount?.toString(),
            serviceFeeGroupId: serviceFees?.toString(),
          }),
          {
            headers,
          }
        )
        .then((res) => {
          // console.log("res", res);
          setisApprovalBtnDisabled(false);
          setShowLoader(false);
          handleSnackbarToggle("Success", "success");
        })
        .catch((err) => {
          setisApprovalBtnDisabled(false);
          setShowLoader(false);
          handleSnackbarToggle(err.response.data.detail, "error");
        });
    } else {
      debugger;
      await axios
        .post(
          process.env.REACT_APP_CORE_URL + "api/Configuration/ClientProfile/",
          JSON.stringify({
            clientType: ClientType,
            clientCategory: clientCategory,
            clientName: clientName,
            email: Email,
            alternativeEmail: AlternativeEmail,
            phoneNumber: PhoneNumber,
            logoUrl: "",
            currencyCode: Currency,
            timeZone: TimeZone,
            searchCategoryIds:
              searchCategory === null ? "0" : searchCategory.toString(),
            supplierCommissionGroupId:
              suppCommissionGroup === null
                ? "0"
                : suppCommissionGroup.toString(),
            markupGroupId:
              markupGroupId === null ? "0" : markupGroupId.toString(),
            address1: Address1,
            address2: Address2,
            state: state,
            postalCode: postalCode,
            countryCode: country,
            city: city,
            isActive: isActive == "true" ? true : false,
            isLiveTicket: isLiveTicket == "true" ? true : false,
            region: region,
            paymentType: PaymentType,
            applied: Applied,
            goLiveDate: new Date(),
            quoteSLA: QuoteSLA,
            ticketSLA: TicketSLA,
            cancellationQuoteSLA: CancellationQuoteSLA,
            cancellationSLA: CancellationSLA,
            voidSLA: VoidSLA,
            reIssueQuoteSLA: ReIssueQuoteSLA,
            reIssueSLA: ReIssueSLA,
            accountManagerId: AccountManagerId,
            salseManagerId: SalseManagerId,
            operationManagerId: OperationManagerId,
            countrySpecificTaxName: csTaxName === undefined ? "" : csTaxName,
            countrySpecificTaxType: csTaxType === undefined ? "" : csTaxType,
            countrySpecificTaxValue:
              csTaxValue === undefined ? "0.00" : csTaxValue,
            countrySpecificTaxApplicableOn:
              csTaxApplicableOn === undefined ? "Base" : csTaxApplicableOn,
            secondaryCurrencyCode: SecondaryCurrencyCode,
            pan: PAN,
            gst: GST,
            AadharCard: AadharCard,
            ownerShipCertificate: OwnerShipCertificate,
            paymentCycle: PaymentCycle,
            statementDate: StatementDate,
            voidingFee: VoidingFee,
            issuenceFee: IssuenceFee,
            adminFee: AdminFee,
            cancellationFee: CancellationFee,
            clientApprovalStatus: ClientApprovalStatus,
            iBossRefId: iBossRefId === "" ? "0" : iBossRefId,
            holdBookings:
              holdBookings === "true"
                ? true
                : holdBookings === "false"
                  ? false
                  : holdBookings,
            supplierName: handleArrayToString(suppliers, "label"),


            airlineName: handleArrayToString(airlines, "Code"),

            holdPeriod: parseInt(holdDays),
            holdCharge: holdBookingCharges ? holdBookingCharges : 0,
            isHoldChargeRefundable:
              holdBookingChargeRules === "true"
                ? true
                : holdBookingChargeRules === "false"
                  ? false
                  : holdBookingChargeRules,
            iBossBooksCountry: iBossBooksCountry,
            paymentGateWayListId: selectedPaymentGateway === undefined ? 0 : selectedPaymentGateway,
            //paymentgatewayList: paymentgatewayList,
            basePCC: basePCC,
            accountBalanceId: accountBalanceId,
            clientImportPNR: importPNRConfig,
            supplierNameType: importPNRConfig == false ? 0 : gds,
            pcc: importPNRConfig == false ? "" : gdsPCC,
            ccPassThrough: passthroughConfig === "true" ? true : false,
            l1CacheGroupId: l1CacheGroup,
            productType: checked.flight ? "Flight" : "Rail",
            isRailServiceActive: isRailServiceActive === "true" ? true : false,
            isRailServiceLive: isRailServiceLive === "true" ? true : false,
            railSearchCategoryIds: railSearchCategoty?.toString(),
            railMarkupGroupId: railMarkup?.toString(),
            railSupplierCommissionGroupId: railSupplierCommissionGroup?.toString(),
            discountGroupId: discount?.toString(),
            serviceFeeGroupId: serviceFees?.toString(),
          }),
          {
            headers,
          }
        )
        .then((res) => {
          // console.log(res);
          setisApprovalBtnDisabled(false);
          setShowLoader(false);
          handleSnackbarToggle("Success", "success");
        })
        .catch((err) => {
          setisApprovalBtnDisabled(false);
          handleSnackbarToggle(err.response.data.detail, "error");
        });
    }
    // your submit logic
    //getClients();
  }

  async function handleSendApproval() {
    setShowLoader(true);
    setisApprovalBtnDisabled(true);
    await axios
      .get(
        process.env.REACT_APP_IDENTITY_SERVER +
        "ids/User/GetAllUsersInPermission?permission=ClientFinanceApproval"
      )
      .then((response) => {
        setisApprovalBtnDisabled(false);
        // console.log(response.data);
        var roles = [];
        for (var i in response.data) {
          let approver = {
            userId: response.data[i].userId,
            email: response.data[i].email,
          };
          roles.push(approver);
        }

        PendingApprovalQueue(roles);
        setShowLoader(false);
      })
      .catch((err) => {
        const error =
          err.response.data == undefined
            ? "please Contact Admin"
            : err.response.data;
        setisApprovalBtnDisabled(false);
        setShowLoader(false);
      });
  }
  async function getIBossID() {
    const headers = {
      "Content-Type": "application/json",
    };
    let url =
      process.env.REACT_APP_IDENTITY_SERVER +
      "api/Configuration/ClientProfile/CreateIBosssId/" +
      selectedItem.clientpforile.id.toString();

    await axios
      .post(url, {
        headers,
      })
      .then((response) => {
        // console.log(response);
        setIBossRefId(response.data.iBossRefId);
        handleSnackbarToggle("Success", "success");
      })
      .catch((err) => {
        console.log(err);
        let temp = err.response.data.split(":");
        // setAlert({
        //   open: true,
        //   message: temp[0],
        //   // message: "oops!! " + "Email confirmation failed or User already registered, please ask admin to add this email",
        //   severity: "error",
        // });
        handleSnackbarToggle(temp[1], "error");
      });
  }
  async function PendingApprovalQueue(approverdetails) {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/PendingApprovalQueue/SendApprovalRequest",
        JSON.stringify({
          approvalConfigId: selectedItem.clientpforile.id.toString(),
          approvalConfigurationType: "Client",
          approvalMessage:
            "Please approve this Client -" +
            selectedItem.clientpforile.clientName,
          approvalPermissionType: "ClientFinanceApproval",
          pendingApprovalQueueId: 0,
          historyStatus: "WaitingForApproval",
          ApproverDetails: approverdetails,
        }),
        {
          headers,
        }
      )
      .then((res) => {
        // console.log(res);
        handleSnackbarToggle("Success", "success");
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        handleSnackbarToggle(err.response.data, "error");
      });
  }

  async function getHistory() {
    let filter =
      "ConfigurationRefId==" +
      selectedItem.clientpforile.id.toString() +
      ",ConfigurationType==0";
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ConfigurationHistory?sorts=created&filters=" +
        filter
      )
      .then((response) => {
        // console.log(response.data);
        setHistoryList(response.data);
      });
  }
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const uploadFile = async (e) => {
    if (file) {
      setisuploadBtnDisabled(true);
      // console.log(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);
      formData.append("fileBelongsTo", "Client");
      formData.append("refId", selectedItem.clientpforile.id.toString());
      formData.append("fileType", documentType);
      try {
        const res = await axios.post(
          process.env.REACT_APP_CORE_URL + "api/Configuration/File/",
          formData
        );
        // console.log("fileupload res", res);

        getFiles();
        await setAlert({
          open: true,
          message: "File Uploaded Successfully",
          // message: "Client’s user registered",
          severity: "success",
        });
        setisuploadBtnDisabled(false);
        setFile("");
        setFileName("");
      } catch (ex) {
        setisuploadBtnDisabled(false);
        setAlert({
          open: true,
          message: `file upload error`,
          severity: "error",
        });
      }
    } else {
      setisuploadBtnDisabled(false);
      <Alert onClose={handleSnackBarClose} severity="error">
        {"Kindly Upload a File"}
      </Alert>;
      setSnackbarOpen(true);
    }
  };


  const validateEmail = () => {
    if (AlternativeEmail) {
      var emails = AlternativeEmail.split(";");

      var temp = [];

      if (emails.length) {
        for (let i = 0; i < emails.length; i++) {
          if (!checkRegex(emails[i].trim())) {
            temp.push(emails[i].trim());
          }
        }
        setinvalidEmails(temp);
        setAlterEmailError(true);
      } else {
        setAlterEmailError(false);
      }
    } else {
      setinvalidEmails([]);
      setAlterEmailError(false);
    }
  };
  const checkRegex = (email) => {
    var re =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  };

  // useEffect(() => {
  //   getRoles();
  // }, [])


  const handleArrayToString = (arrayList, field) => {
    let strValue = "";
    if (arrayList !== undefined && arrayList.length > 0) {
      if (field === "value") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.value)
            : (strValue = strValue + "," + arr.value)
        );
      } else if (field === "Code") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.Code)
            : (strValue = strValue + "," + arr.Code)
        );
      } else if (field === "city_code") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.city_code)
            : (strValue = strValue + "," + arr.city_code)
        );
      } else if (field === "countrycode") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.code)
            : (strValue = strValue + "," + arr.code)
        );
      } else if (field === "raw") {
        //console.log(arrayList);
        arrayList.map((arr) =>
          strValue === "" ? (strValue = arr) : (strValue = strValue + "," + arr)
        );
      } else if (field === "label") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.label)
            : (strValue = strValue + "," + arr.label)
        );
      }
    }
    return strValue;
  };


  const calculateAdultDate = () => {
    const today = new Date();
    const adultDate = new Date(today.getFullYear() - 12, today.getMonth(), today.getDate());
    return adultDate.toISOString().split('T')[0];
  };
  const maxAdultDate = calculateAdultDate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOtpPopClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOtpPopUp(false);
    setOtp(new Array(4).fill(''));
  }

  useEffect(() => {

    const otpInvalid = localStorage.getItem("otpFail");
    if (otpInvalid && otpPopUp) {
      setDisableOtpInput(true);
      setNotValid(true);
      setResMsg("Too many incorrect OTP attempts. Please try after sometime");
    }
    else {
      setDisableOtpInput(false);
      setNotValid(false);
      setResMsg("");
    }
  }, [otpPopUp])



  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      {/* {action === "view" && (
      <>
      <AntTabs value={accountsTab} onChange={accountsTabHandleChange}>
        <AntTab label="Details" />
        <AntTab label="Add Fund" />
        <AntTab label="Temporary Credit" />
        <AntTab label="Client Status" />
        <AntTab label="Manual Credit/Debit" />
      </AntTabs>
      <TabPanel value={accountsTab} index={0}>
	      <div className="configuration-heading-container configuration-title-flex-container">
          <div>Client Details</div>
          {action === "view" && (
              <div className="configuration-heading-icons">
                <IconButton fontSize="small">
                  <EditRoundedIcon
                    fontSize="small"
                    className="icon-blue"
                    onClick={() => setAction("edit")}
                  ></EditRoundedIcon>
                </IconButton>
              </div>
          )}
        </div>
		    <ValidatorForm
          onError={(errors) => console.log(errors)}
          onSubmit={handleSubmit}
        >
			<div className="configuration-view-edit-drawer-container">
				<div className="configuration-view-edit-drawer-input-container"> 
				
				</div>
			</div>
        </ValidatorForm>
      </TabPanel>      
      <TabPanel value={accountsTab} index={1}>Add Fund</TabPanel>
      <TabPanel value={accountsTab} index={2}>Temporary Credit</TabPanel>
      <TabPanel value={accountsTab} index={3}>Client Status</TabPanel>
      <TabPanel value={accountsTab} index={4}>Manual Credit/Debit</TabPanel>
      </>
      )} */}
      <AntTabs value={value} onChange={handleChange}>
        <AntTab label="Details" />
        <AntTab label="Users" disabled={action === "new" ? true : false} />
        <AntTab
          label="API Account"
          disabled={action === "new" ? true : false}
        />
        <AntTab
          label="Document Vault"
          disabled={action === "new" ? true : false}
        />
        <AntTab label="Payment" disabled={action === "new" ? true : false} />
        <AntTab
          label="Approval History"
          disabled={action === "new" ? true : false}
        />
      </AntTabs>
      <TabPanel value={value} index={0}>
        <div className="configuration-heading-container configuration-title-flex-container">
          <div>Client Details</div>
          {isUserHasPermission("ClientWrite") && action === "view" && (
            <div className="configuration-heading-icons">
              <IconButton fontSize="small">
                <EditRoundedIcon
                  fontSize="small"
                  className="icon-blue"
                  onClick={() => setAction("edit")}
                ></EditRoundedIcon>
              </IconButton>
            </div>
          )}
        </div>
        <ValidatorForm
          onError={(errors) => console.log('errors', errors)}
          onSubmit={handleSubmit}
        >
          <div className="configuration-view-edit-drawer-container">
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <Autocomplete
                    id="clienttype-dd"
                    value={clientTypeList.find((v) => v === ClientType) || null}
                    onChange={(event, newValue) => {
                      setClientType(newValue ? newValue : "");
                    }}
                    options={clientTypeList}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="*Client Type"
                        value={ClientType}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        validators={["required"]}
                        errorMessages={["This Field is Required."]}
                      />
                    )}
                  />
                </div>
              )}

              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="*Client Name"
                    size="small"
                    color="secondary"
                    value={clientName}
                    onChange={(event) => setclientName(event.target.value)}
                    validators={["required"]}
                    errorMessages={["This Field is Required."]}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Client Type
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {ClientType}
                  </div>
                </div>
              )}

              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Client Name
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {clientName}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <Autocomplete
                    id="clienttype-dd"
                    value={ClientCategoryLists.find((v) => v === clientCategory) || null}
                    onChange={(event, newValue) => {
                      setClientCategory(newValue ? newValue : "");
                    }}
                    options={ClientCategoryLists}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="*Client Category"
                        value={clientCategory}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        validators={["required"]}
                        errorMessages={["This Field is Required."]}
                      />
                    )}
                  />
                </div>

              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Client Category
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {clientCategory}
                  </div>
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="*Email"
                    size="small"
                    color="secondary"
                    value={Email}
                    onChange={(event) => setEmail(event.target.value)}
                    // validators={["required", "matchRegexp:^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$"]}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "This Field is Required.",
                      "email is not valid",
                    ]}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">Email</div>
                  <div className="view-configuration-drawer-description ">
                    {Email}
                  </div>
                </div>
              )}
            </div>

            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1 margin-right-16">
                  <TextValidator
                    variant="outlined"
                    label="AlternativeEmail"
                    size="small"
                    color="secondary"
                    value={AlternativeEmail}
                    onChange={(event) =>
                      setAlternativeEmail(event.target.value)
                    }
                    // validators={["required", "isAlternativeEmail"]}
                    // errorMessages={[
                    //   "This Field is Required.",
                    //   "alternative email is not valid",
                    // ]}
                    onKeyUp={validateEmail}
                  // validators={["required"]}
                  // errorMessages={[
                  //   "This Field is Required."
                  // ]}
                  />
                  {invalidEmails.length !== 0 && alterEmailError && (
                    <p class="invalidEmail">
                      Invalid emails: {invalidEmails.join(", ")}{" "}
                    </p>
                  )}
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    AlternativeEmail
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {AlternativeEmail}
                  </div>
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <Autocomplete
                    id="timezone-select-demo"
                    value={Currencies.find((v) => v.code === Currency) || null}
                    onChange={(event, newValue) => {
                      setCurrency(newValue ? newValue.code : "");
                    }}
                    options={Currencies}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.name + " (" + option.code + ")"
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="*Currency"
                        value={Currency}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        validators={["required"]}
                        errorMessages={["This Field is Required."]}
                      />
                    )}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Currency
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {Currency}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1 margin-right-16">
                  <TextValidator
                    variant="outlined"
                    label="*Phone Number"
                    size="small"
                    color="secondary"
                    value={PhoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    validators={["required", "matchRegexp:^[0-9*#+-]+$"]}
                    errorMessages={[
                      "This Field is Required.",
                      "Invalid Phone number,(+/-/numbers)",
                    ]}
                  />
                </div>
              )}

              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1 margin-right-16">
                  <div className="view-configuration-drawer-title">
                    Phone Number
                  </div>
                  <div className="view-configuration-drawer-description">
                    {PhoneNumber}
                  </div>
                </div>
              )}

              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <Autocomplete
                    id="country-select-demo"
                    value={countries.find((v) => v.code === country) || null}
                    onChange={(event, newValue) => {
                      setcountry(newValue ? newValue.code : "");
                    }}
                    options={countries}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.label + " (" + option.code + ")"
                    }
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.label} ({option.code})
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="*Country"
                        value={country}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        validators={["required"]}
                        errorMessages={["This Field is Required."]}
                      />
                    )}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">Country</div>
                  <div className="view-configuration-drawer-description ">
                    {displayCountry}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1 margin-right-16">
                  <Autocomplete
                    id="timezone-select-demo"
                    value={
                      TimeZones.find((v) => v.value === TimeZone.toString()) ||
                      null
                    }
                    onChange={(event, newValue) => {
                      setTimeZone(newValue ? newValue.value : "");
                    }}
                    options={TimeZones}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="*TimeZone"
                        value={TimeZone}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        validators={["required"]}
                        errorMessages={["This Field is Required."]}
                      />
                    )}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1 margin-right-16">
                  <div className="view-configuration-drawer-title">
                    TimeZone
                  </div>
                  <div className="view-configuration-drawer-description">
                    {TimeZone}
                  </div>
                </div>
              )}

              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <Autocomplete
                    id="paymentType-dd"
                    value={
                      paymentTypeList.find((v) => v === PaymentType) || null
                    }
                    onChange={(event, newValue) => {
                      setPaymentType(newValue ? newValue : "");
                    }}
                    options={paymentTypeList}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="*Payment Type"
                        value={PaymentType}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        validators={["required"]}
                        errorMessages={["This Field is Required."]}
                      />
                    )}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Payment Type
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {PaymentType}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1 margin-right-16">
                  <Autocomplete
                    id="region-select-demo"
                    value={regions.find((v) => v.key === region) || null}
                    onChange={(event, newValue) => {
                      setRegion(newValue ? newValue.key : "");
                    }}
                    options={regions}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => (
                      <React.Fragment>{option.label}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="*Region"
                        value={region}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        validators={["required"]}
                        errorMessages={["This Field is Required."]}
                      />
                    )}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1 margin-right-16">
                  <div className="view-configuration-drawer-title">Region</div>
                  <div className="view-configuration-drawer-description ">
                    {region}
                  </div>
                </div>
              )}

              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <Autocomplete
                    id="Applied-dd"
                    value={appliedList.find((v) => v === Applied) || null}
                    onChange={(event, newValue) => {
                      setApplied(newValue ? newValue : "");
                    }}
                    options={appliedList}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="Applied"
                        value={Applied}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">Applied</div>
                  <div className="view-configuration-drawer-description ">
                    {Applied}
                  </div>
                </div>
              )}
            </div>

            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1 margin-right-16">
                  <TextValidator
                    variant="outlined"
                    label="*Address1"
                    size="small"
                    color="secondary"
                    value={Address1}
                    onChange={(event) => setAddress1(event.target.value)}
                    validators={["required"]}
                    errorMessages={["This Field is Required."]}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Address2"
                    size="small"
                    color="secondary"
                    value={Address2}
                    onChange={(event) => setAddress2(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1 margin-right-16">
                  <div className="view-configuration-drawer-title">
                    Address1
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {Address1}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Address2
                  </div>
                  <div className="view-configuration-drawer-description">
                    {Address2}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="City"
                    size="small"
                    color="secondary"
                    value={city}
                    onChange={(event) => setcity(event.target.value)}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="State"
                    size="small"
                    color="secondary"
                    value={state}
                    onChange={(event) => setstate(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">City</div>
                  <div className="view-configuration-drawer-description ">
                    {city}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">State</div>
                  <div className="view-configuration-drawer-description">
                    {state}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Postal Code"
                    size="small"
                    color="secondary"
                    value={postalCode}
                    onChange={(event) => setpostalCode(event.target.value)}
                  />
                </div>
              )}

              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Postal Code
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {postalCode}
                  </div>
                </div>
              )}

              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="OwnerShipCertificate"
                    size="small"
                    color="secondary"
                    value={OwnerShipCertificate}
                    onChange={(event) =>
                      setOwnerShipCertificate(event.target.value)
                    }
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    OwnerShipCertificate
                  </div>
                  <div className="view-configuration-drawer-description">
                    {OwnerShipCertificate}
                  </div>
                </div>
              )}
            </div>

            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="PAN"
                    size="small"
                    color="secondary"
                    value={PAN}
                    onChange={(event) => setPAN(event.target.value)}
                    validators={["matchRegexp:^[A-Z]{5}[0-9]{4}[A-Z]{1}$"]}
                    errorMessages={[
                      "Not in valid format - 1st 5 Letters in CAPS, then Next 4 Letters in Numbers & Last Letter in CAPS",
                    ]}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="GST"
                    size="small"
                    color="secondary"
                    value={GST}
                    onChange={(event) => setGST(event.target.value)}
                    validators={[
                      "matchRegexp:^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
                    ]}
                    errorMessages={[
                      "Not in valid format - 1st 2 letters should be in Numbers, Next 5 letters should be in Uppercase , next 4 letters should be in Numbers, Next Letter should be in Uppercase, 13th,15th letter should be in number or in Uppercase , 14th letter Should be capital",
                    ]}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">PAN</div>
                  <div className="view-configuration-drawer-description ">
                    {PAN}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">GST</div>
                  <div className="view-configuration-drawer-description">
                    {GST}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="AadharCard"
                    size="small"
                    color="secondary"
                    value={AadharCard}
                    onChange={(event) => setAadharCard(event.target.value)}
                    validators={[
                      "matchRegexp:^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
                    ]}
                    errorMessages={[
                      "Only 12 Numerical Values Allowed,No Alphabets or special characters allowed",
                    ]}
                  />
                </div>
              )}

              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    AadharCard
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {AadharCard}
                  </div>
                </div>
              )}
            </div>

            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Payment Gateway</InputLabel>
                    <Select
                      defaultValue="None"
                      labelId="Provider Names"
                      id="demo-simple-select"
                      value={selectedPaymentGateway}
                      label="Payment Gateway"
                      onChange={(event) => setSelectedPaymentGateway(event.target.value)}
                    >
                      {paymentgatewayList &&
                        paymentgatewayList?.length > 0 &&
                        paymentgatewayList?.map((gateway) => (
                          <MenuItem key={gateway.id} value={gateway.id}>
                            {gateway.provider}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      IBoss Country
                    </InputLabel>
                    <Select
                      defaultValue="IN"
                      labelId="IBossCountry"
                      id="demo-simple-select"
                      value={iBossBooksCountry}
                      label="IBoss Country"
                      onChange={(event) =>
                        setIBossBooksCountry(event.target.value)
                      }
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value="IN">India</MenuItem>
                      <MenuItem value="US">United States</MenuItem>
                      <MenuItem value="IT">Italy</MenuItem>
                      <MenuItem value="UAE">UAE</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="IBoss Reference Id"
                    size="small"
                    color="secondary"
                    value={iBossRefId}
                    onChange={(event) => setIBossRefId(event.target.value)}
                  // validators={["required", "isNumber"]}
                  // errorMessages={["value is required", "Not a valid number "]}
                  />
                  {/* <TextValidator
                    disabled
                    variant="outlined"
                    label="IBoss Reference Id"
                    size="small"
                    color="secondary"
                    value={iBossRefId}
                  /> */}
                </div>
              )}

              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Payment GateWay
                  </div>
                  <div className="view-configuration-drawer-description ">
                    <div className="view-configuration-drawer-description">
                      {paymentgatewayList &&
                        paymentgatewayList?.length > 0 &&
                        paymentgatewayList
                          .filter(
                            (gateway) => gateway.id === selectedPaymentGateway
                          )
                          .map((filteredGateway) => (
                            <div key={filteredGateway.id}>
                              {filteredGateway.provider}
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    IBoss Country
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {iBossBooksCountry ? iBossBooksCountry : "None"}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    IBoss Reference Id
                  </div>

                  {iBossRefId && iBossRefId != 0 ? (
                    <div className="view-configuration-drawer-description ">
                      <span>{iBossRefId}</span>
                    </div>
                  ) : (
                    <div
                      className="view-configuration-drawer-description "
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ paddingRight: "15px" }}>{iBossRefId}</span>{" "}
                      <SyncIcon
                        className="icon-fill pointer"
                        fontSize="small"
                        color="primary"
                        onClick={getIBossID}
                      ></SyncIcon>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="configuration-heading-container configuration-title-flex-container">
              <div>Select Product</div>
            </div>
            <div className="configuration-view-edit-drawer-input-container mt-2">
              <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1 align-center">

                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-labelledby="position"
                    defaultValue="flight"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel disabled={action === "view" ? true : false} value='flight' name="flight" control={<Radio />} label="Flight" onChange={handleCheckboxChange} checked={checked.flight} />
                    <FormControlLabel disabled={action === "view" ? true : false} value='rail' name="rail" control={<Radio />} label="Rail" onChange={handleCheckboxChange} checked={checked.rail} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="configuration-heading-container configuration-title-flex-container">
              <div>Service level Agreements</div>
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Quote SLA"
                    size="small"
                    color="secondary"
                    value={QuoteSLA}
                    onChange={(event) => setQuoteSLA(event.target.value)}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Ticket SLA"
                    size="small"
                    color="secondary"
                    value={TicketSLA}
                    onChange={(event) => setTicketSLA(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Quote SLA
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {QuoteSLA}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Ticket SLA
                  </div>
                  <div className="view-configuration-drawer-description">
                    {TicketSLA}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="CancellationQuote SLA"
                    size="small"
                    color="secondary"
                    value={CancellationQuoteSLA}
                    onChange={(event) =>
                      setCancellationQuoteSLA(event.target.value)
                    }
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Cancellation SLA"
                    size="small"
                    color="secondary"
                    value={CancellationSLA}
                    onChange={(event) => setCancellationSLA(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    CancellationQuote SLA
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {CancellationQuoteSLA}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Cancellation SLA
                  </div>
                  <div className="view-configuration-drawer-description">
                    {CancellationSLA}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Void SLA"
                    size="small"
                    color="secondary"
                    value={VoidSLA}
                    onChange={(event) => setVoidSLA(event.target.value)}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="ReIssueQuote SLA"
                    size="small"
                    color="secondary"
                    value={ReIssueQuoteSLA}
                    onChange={(event) => setReIssueQuoteSLA(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Void SLA
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {VoidSLA}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    ReIssueQuote SLA
                  </div>
                  <div className="view-configuration-drawer-description">
                    {ReIssueQuoteSLA}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="ReIssueSLA"
                    size="small"
                    color="secondary"
                    value={ReIssueSLA}
                    onChange={(event) => setReIssueSLA(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    ReIssue SLA
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {ReIssueSLA}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-heading-container configuration-title-flex-container">
              <div>Configurations</div>
            </div>

            {/* import pnr */}

            {checked.flight &&
              <>
                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      Allow Hold Bookings
                    </div>
                  )}
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input config-input-flex-1">
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="controlled-radio-buttons-group"
                          value={holdBookings}
                          onChange={(event) => setHoldBookings(event.target.value)}
                        >
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>

                    </div>
                  )}
                  {action === "view" && (
                    <><div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Allow Hold Bookings
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {holdBookings === "true" ? "Yes" : "No"}
                      </div>

                    </div>
                      <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                        <div className="view-configuration-drawer-title">
                          Supplier(s)
                        </div>
                        <div className="view-configuration-drawer-description">
                          {suppliers && suppliers.map((supplier, index) => (
                            <span key={supplier.value}>
                              {index > 0 && ', '}
                              {supplier.value}
                            </span>
                          ))}
                        </div>

                      </div>
                      <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                        <div className="view-configuration-drawer-title">
                          Airline(s)
                        </div>
                        <div className="view-configuration-drawer-description">
                          {airlines && airlines.map((airline, index) => (
                            <span key={airline.Name}>
                              {index > 0 && ', '}
                              {airline.Name}
                            </span>
                          ))}
                        </div>

                      </div>
                      <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                        <div className="view-configuration-drawer-title">
                          Allow Hold
                        </div>
                        <div className="view-configuration-drawer-description ">
                          {holdDays}
                        </div>

                      </div>
                    </>
                  )}
                </div>
                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      Import PCC Configuration
                    </div>
                  )}
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input config-input-flex-1">
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="controlled-radio-buttons-group"
                          value={importPNRConfig}
                          onChange={isImportPNRHandler}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Enable"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Disable"
                          />
                        </RadioGroup>

                        {importPNRConfig == true ? (
                          <>
                            <div className="d-flex mt-4">
                              <FormControl fullWidth className="holdBooking-select me-1">
                                <Autocomplete
                                  required
                                  variant="outlined"
                                  value={gdsSupplier.find((v) => v === gds) || null}
                                  onChange={(event, value) => setGDS(value)}
                                  options={gdsSupplier}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label="GDS"
                                    />
                                  )}
                                />
                              </FormControl>

                              <TextField
                                required
                                variant="outlined"
                                className="holdBooking-select"
                                label="PCC"
                                size="small"
                                color="secondary"
                                value={gdsPCC}
                                onChange={(event) => setGdsPcc(event.target.value)}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </div>
                  )}
                  {action === "view" && (
                    <>
                      <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                        <div className="view-configuration-drawer-title">
                          Import PCC Configuration
                        </div>
                        <div className="view-configuration-drawer-description ">
                          {importPNRConfig == true ? "Yes" : "No"}
                        </div>
                      </div>
                      {importPNRConfig == true ? (
                        <>
                          <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                            <div className="view-configuration-drawer-title">
                              GDS
                            </div>
                            <div className="view-configuration-drawer-description ">
                              {gds ? gds : ""}
                            </div>
                          </div>
                          <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                            <div className="view-configuration-drawer-title">
                              PCC
                            </div>
                            <div className="view-configuration-drawer-description ">
                              {gdsPCC ? gdsPCC : ""}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && holdBookings && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      Charge Type
                    </div>
                  )}
                  {action !== "view" && holdBookings && (
                    <div className="configuration-view-edit-drawer-input config-input-flex-1">
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="controlled-radio-buttons-group"
                          value={holdBookingChargesType}
                          onChange={(event) =>
                            setholdBookingChargesType(event.target.value)
                          }
                        >
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Nil"
                          />
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Chargable"
                          />
                        </RadioGroup>
                        {action !== "view" &&
                          holdBookings &&
                          holdBookingChargesType && (
                            <TextValidator
                              variant="outlined"
                              label={holdLabelText}
                              size="small"
                              style={{ maxWidth: "150px", marginTop: "10px" }}
                              color="secondary"
                              value={holdBookingCharges}
                              onChange={(event) =>
                                setHoldBookingCharges(event.target.value)
                              }
                              validators={["required", "isNumber"]}
                              errorMessages={[
                                "value is required",
                                "only numbers allowed",
                              ]}
                            />
                          )}
                      </FormControl>
                    </div>
                  )}
                  {action === "view" && holdBookings && (
                    <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                      <div className="view-configuration-drawer-title">Charges</div>
                      <div className="view-configuration-drawer-description ">
                        {holdBookingChargesType == true &&
                          holdBookingCharges > 0 ? (
                          <p style={{ margin: "0px" }}>
                            {Currency}
                            <span style={{ marginLeft: "5px" }}>
                              {holdBookingCharges}
                            </span>
                          </p>
                        ) : (
                          "Nil"
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            }



            {/* import pnr */}

            {/* passthrough config */}

            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  CC Passthrough
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="controlled-radio-buttons-group"
                      value={passthroughConfig}
                      onChange={(event) =>
                        setPassthroughConfig(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Enable"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Disable"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              {action === "view" && (
                <>
                  <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                    <div className="view-configuration-drawer-title">
                      CC Passthrough
                    </div>
                    <div className="view-configuration-drawer-description ">
                      {passthroughConfig == "true" ? "Yes" : "No"}
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* passthrough config */}

            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && holdBookings && holdBookingChargesType && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  Is Hold Charge Refundable
                </div>
              )}
              {action !== "view" && holdBookings && holdBookingChargesType && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="controlled-radio-buttons-group"
                      value={holdBookingChargeRules}
                      onChange={(event) =>
                        setHoldBookingChargeRules(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Not Refundable"
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Refundable"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              {action === "view" && holdBookings && holdBookingChargesType && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Is Hold Charge Refundable
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {holdBookingChargeRules == true ? "Yes" : "No"}
                  </div>
                </div>
              )}
            </div>
            {checked.flight &&
              <>
                {action !== "view" && (
                  <div className="configuration-view-edit-drawer-input-container">
                    {holdBookings === "true" &&
                      <>
                        <FormControl fullWidth className="me-1 holdBooking-select">
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            value={(suppliers && suppliers) || []}

                            onChange={(event, value) => setSuppliers(value)}
                            options={supplier.filter((item) => ['Galileo', 'Sabre', 'Amadeus', 'SabreRest', 'AkbarHoustonNDC'].includes(item.value))}
                            getOptionLabel={(option) => option.value}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Supplier"
                                placeholder="Select Supplier"
                                color="secondary"
                                size="small"
                              />
                            )}
                          />
                        </FormControl>

                        <FormControl fullWidth className="me-1 holdBooking-select">
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            value={(airlines && airlines) || []}

                            onChange={(event, value) => setAirlines(value)}
                            options={airlineDetails}
                            getOptionLabel={(option) =>
                              option.Code === "All"
                                ? "All"
                                : option.Name + " (" + option.Code + ")"
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Airlines"
                                placeholder="Select Airlines"
                                color="secondary"
                                size="small"
                              />
                            )}
                          />
                        </FormControl>

                        <FormControl fullWidth className="holdBooking-select">
                          <Autocomplete
                            fullWidth
                            value={holdDays}
                            onChange={(event, newValue) => {
                              setHoldDays(newValue);
                            }}
                            options={[3, 7, 14, 30]}
                            getOptionLabel={(option) => option ? "Allow Hold " + option + " days prior" : ""
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Allow Hold" variant="outlined" color="secondary"
                                size="small" />
                            )}

                          />

                        </FormControl>
                      </>

                    }
                  </div>
                )}
                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <Autocomplete
                        id="clienttype-dd"
                        value={
                          searchCatOptions.find((v) => v.id === searchCategory) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          setsearchCategory(newValue ? newValue.id : "");
                        }}
                        options={searchCatOptions}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(categoria) => categoria.name}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Search Categories"
                            value={searchCategory}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          // validators={["required"]}
                          // errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Search Category
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {searchCategories.find(
                          (scg) => scg.searchCategories.id === searchCategory
                        ) &&
                          searchCategories.find(
                            (scg) => scg.searchCategories.id === searchCategory
                          ).searchCategories.name}
                      </div>
                    </div>
                  )}
                  {action !== "view" && (
                    <div
                      className="configuration-view-edit-drawer-input config-input-flex-1">
                      <Autocomplete
                        id="clienttype-dd"
                        value={
                          markupOptions.find((v) => v.id === markupGroupId) || null
                        }
                        onChange={(event, newValue) => {
                          setMarkupGroup(newValue ? newValue.id : "");
                        }}
                        options={markupOptions}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(categoria) => categoria.name}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label=" Markup Groups"
                            value={markupGroupId}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          // validators={["required"]}
                          // errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Markup Group
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {markupGroup.find(
                          (scg) => scg.markupGroup.id === markupGroupId
                        ) &&
                          markupGroup.find(
                            (scg) => scg.markupGroup.id === markupGroupId
                          ).markupGroup.name}
                      </div>
                    </div>
                  )}
                </div>

                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && (
                    <div
                      className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1"
                    >
                      <Autocomplete
                        id="clienttype-dd"
                        value={
                          searchComOptions.find(
                            (v) => v.id === suppCommissionGroup
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setsuppCommissionGroup(newValue ? newValue.id : "");
                        }}
                        options={searchComOptions}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(categoria) => categoria.name}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Supplier Commission Categories"
                            value={suppCommissionGroup}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          // validators={["required"]}
                          // errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}

                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Supplier Commission Group
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {searchComGroup.find(
                          (scg) =>
                            scg.supplierCommissionGroups.id === suppCommissionGroup
                        ) &&
                          searchComGroup.find(
                            (scg) =>
                              scg.supplierCommissionGroups.id ===
                              suppCommissionGroup
                          ).supplierCommissionGroups.name}
                      </div>
                    </div>
                  )}




                  {action !== "view" && (
                    <><div className="configuration-view-edit-drawer-input config-input-flex-1">
                      <Autocomplete
                        id="base Pcc"
                        value={pccList.find((v) => v.value === basePCC) || null}
                        onChange={(event, newValue) => {
                          setBasePcc(newValue ? newValue.value : "");
                        }}
                        options={pccList}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Base PCC"
                            value={basePCC}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }} />
                        )} />
                    </div>
                    </>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Base PCC
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {basePCC}
                      </div>
                    </div>
                  )}
                </div>

                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <Autocomplete
                        id="Status select"
                        value={
                          isActive != ""
                            ? statusOptions.find((v) => v.value == isActive) || null
                            : ""
                        }
                        onChange={(event, newValue) => {
                          setisActive(newValue ? newValue.value : "");
                          setisLiveTicket(newValue && newValue.value === 'false' ? 'false' : '');
                        }}
                        options={statusOptions}
                        getOptionLabel={(option) => option.type}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Is Flight Active"
                            value={isActive}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            validators={["required"]}
                            errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}

                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Is Flight Active
                      </div>
                      <div className="view-configuration-drawer-description">
                        {isActive.toString()}
                      </div>
                    </div>
                  )}

                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input config-input-flex-1">
                      <Autocomplete
                        id="Status select"
                        value={
                          isLiveTicket != ""
                            ? statusOptions.find((v) => v.value == isLiveTicket) ||
                            null
                            : ""
                        }
                        getOptionDisabled={(option) => isActive === 'false' && option === statusOptions[0]}
                        onChange={(event, newValue) => {
                          setisLiveTicket(newValue ? newValue.value : "");
                        }}
                        options={statusOptions}
                        getOptionLabel={(option) => option.type}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Is Flight Live"
                            value={isLiveTicket}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            validators={["required"]}
                            errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1" style={{ padding: "0px 10px" }}>
                      <div className="view-configuration-drawer-title">Is Flight Live</div>
                      <div className="view-configuration-drawer-description">
                        {isLiveTicket.toString()}
                      </div>
                    </div>
                  )}
                </div>
                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input config-input-flex-1">
                      <Autocomplete
                        id="clienttype-dd"
                        value={l1CacheGroups && l1CacheGroups.find((v) => v.id === l1CacheGroup) || { l1GroupName: "None", id: null }}
                        onChange={(event, newValue) => {
                          setL1CacheGroup(newValue ? newValue.id : null);
                        }}
                        options={[{ l1GroupName: "None", id: null }, ...(l1CacheGroups || [])]}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.l1GroupName || "None"}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="L1 Cache Group"
                            value={l1CacheGroup}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          // validators={["required"]}
                          // errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        L1 Cache Group
                      </div>
                      <div className="view-configuration-drawer-description">
                        {l1CacheGroups.find((scg) => scg.id === l1CacheGroup)?.l1GroupName || "None"}
                      </div>
                    </div>
                  )}
                  <div className="view-configuration-drawer config-input-flex-1"></div>
                </div>
              </>
            }

            {checked.rail &&
              <>
                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <Autocomplete
                        id="Status select"
                        value={
                          isRailServiceActive != ""
                            ? statusOptions.find((v) => v.value == isRailServiceActive) ||
                            null
                            : ""
                        }
                        onChange={(event, newValue) => {
                          setIsRailServiceActive(newValue ? newValue.value : "");
                          setIsRailServiceLive(newValue && newValue.value === 'false' ? 'false' : '');
                        }}
                        options={statusOptions}
                        getOptionLabel={(option) => option.type}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Is Rail Active"
                            value={isRailServiceActive}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            validators={["required"]}
                            errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">Is Rail Active</div>
                      <div className="view-configuration-drawer-description">
                        {isRailServiceActive.toString()}
                      </div>
                    </div>
                  )}
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input config-input-flex-1">
                      <Autocomplete
                        id="Status select"
                        value={
                          isRailServiceLive != ""
                            ? statusOptions.find((v) => v.value == isRailServiceLive) ||
                            null
                            : ""
                        }
                        getOptionDisabled={(option) => isRailServiceActive === 'false' && option === statusOptions[0]}
                        onChange={(event, newValue) => {
                          setIsRailServiceLive(newValue ? newValue.value : "");
                        }}
                        options={statusOptions}
                        getOptionLabel={(option) => option.type}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Is Rail Live"
                            value={isRailServiceLive}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            validators={["required"]}
                            errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">Is Rail  Live</div>
                      <div className="view-configuration-drawer-description">
                        {isRailServiceLive.toString()}
                      </div>
                    </div>
                  )}
                </div>
                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <Autocomplete
                        id="rail_search_cat"
                        value={
                          railSearchCatOption.find((v) => v.id == railSearchCategoty) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          setRailSearchCategoty(newValue ? newValue.id : "");
                        }}
                        options={railSearchCatOption}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(categoria) => categoria.name}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Search Categories"
                            value={railSearchCategoty}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          // validators={["required"]}
                          // errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Search Category
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {railSearchCatOption.find(
                          (scg) => scg.id == railSearchCategoty
                        ) &&
                          railSearchCatOption.find(
                            (scg) => scg.id == railSearchCategoty
                          ).name}
                      </div>
                    </div>
                  )}
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <Autocomplete
                        id="markup_group"
                        value={
                          railMarkupOption.find((v) => v.id == railMarkup) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          setRailMarkup(newValue ? newValue.id : "");
                        }}
                        options={railMarkupOption}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(categoria) => categoria.name}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Markup Groups"
                            value={railMarkup}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          // validators={["required"]}
                          // errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Markup Groups
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {railMarkupOption.find(
                          (scg) => scg.id == railMarkup
                        ) &&
                          railMarkupOption.find(
                            (scg) => scg.id == railMarkup
                          ).name}
                      </div>
                    </div>
                  )}
                </div>
                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <Autocomplete
                        id="supplier_commission_Cat"
                        value={
                          railSupplierCommissionGroupOptions.find((v) => v.id == railSupplierCommissionGroup) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          setRailSupplierCommissionGroup(newValue ? newValue.id : "");
                        }}
                        options={railSupplierCommissionGroupOptions}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(categoria) => categoria.name}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Supplier Commission Categories"
                            value={railSupplierCommissionGroup}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          // validators={["required"]}
                          // errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Supplier Commission Categories
                      </div>
                      <div className="view-configuration-drawer-description">
                        {railSupplierCommissionGroupOptions.find(
                          (scg) => scg.id == railSupplierCommissionGroup
                        ) &&
                          railSupplierCommissionGroupOptions.find(
                            (scg) => scg.id == railSupplierCommissionGroup
                          ).name}
                      </div>
                    </div>
                  )}
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <Autocomplete
                        id="discount_group"
                        value={
                          discountGroupOption.find((v) => v.id == discount) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          setDiscount(newValue ? newValue.id : "");
                        }}
                        options={discountGroupOption}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(categoria) => categoria.name}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Discount Group"
                            value={discount}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          // validators={["required"]}
                          // errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Discount Group
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {discountGroupOption.find(
                          (scg) => scg.id == discount
                        ) &&
                          discountGroupOption.find(
                            (scg) => scg.id == discount
                          ).name}
                      </div>
                    </div>
                  )}
                </div>
                <div className="configuration-view-edit-drawer-input-container">
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <Autocomplete
                        id="service_fees_group"
                        value={
                          serviceFeesGroupOptions.find((v) => v.id == serviceFees) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          setServiceFees(newValue ? newValue.id : "");
                        }}
                        options={serviceFeesGroupOptions}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(categoria) => categoria.name}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label="Service Fees Group"
                            value={serviceFees}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          // validators={["required"]}
                          // errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-configuration-drawer config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        Service Fees Group
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {serviceFeesGroupOptions.find(
                          (scg) => scg.id == serviceFees
                        ) &&
                          serviceFeesGroupOptions.find(
                            (scg) => scg.id == serviceFees
                          ).name}
                      </div>
                    </div>
                  )}
                </div>
              </>
            }

            <div className="configuration-heading-container configuration-title-flex-container">
              <div>Fees</div>
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="*VoidingFee"
                    size="small"
                    color="secondary"
                    value={VoidingFee}
                    onChange={(event) => setVoidingFee(event.target.value)}
                    validators={["required", "isNumber"]}
                    errorMessages={["value is required", "Not a valid number "]}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="*IssuanceFee"
                    size="small"
                    color="secondary"
                    value={IssuenceFee}
                    onChange={(event) => setIssuenceFee(event.target.value)}
                    validators={["required", "isNumber"]}
                    errorMessages={["value is required", "Not a valid number "]}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    VoidingFee
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {VoidingFee}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    IssuanceFee
                  </div>
                  <div className="view-configuration-drawer-description">
                    {IssuenceFee}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="*Admin Fee"
                    size="small"
                    color="secondary"
                    value={AdminFee}
                    onChange={(event) => setAdminFee(event.target.value)}
                    validators={["required", "isNumber"]}
                    errorMessages={["value is required", "Not a valid number "]}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="*Cancellation Fee"
                    size="small"
                    color="secondary"
                    value={CancellationFee}
                    onChange={(event) => setCancellationFee(event.target.value)}
                    validators={["required", "isNumber"]}
                    errorMessages={["value is required", "Not a valid number "]}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    AdminFee
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {AdminFee}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Cancellation Fee
                  </div>
                  <div className="view-configuration-drawer-description">
                    {CancellationFee}
                  </div>
                </div>
              )}
            </div>


            <div className="configuration-heading-container configuration-title-flex-container">
              <div>Internal Contact</div>
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Account Manager
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={AccountManagerId}
                      onChange={(ev) => setAccountManagerId(ev.target.value)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {AlluserProfiles &&
                        AlluserProfiles.map((categoria) => (
                          <MenuItem key={categoria.id} value={categoria.id}>
                            {categoria.title && categoria.title}{" "}
                            {categoria.firstName && categoria.firstName}{" "}
                            {categoria.lastName && categoria.lastName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Sales Manager
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={SalseManagerId}
                      onChange={(ev) => setSalseManagerId(ev.target.value)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {AlluserProfiles &&
                        AlluserProfiles.map((categoria) => (
                          <MenuItem key={categoria.id} value={categoria.id}>
                            {categoria.title && categoria.title}{" "}
                            {categoria.firstName && categoria.firstName}{" "}
                            {categoria.lastName && categoria.lastName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    AccountManager
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {AlluserProfiles.find(
                      (scg) => scg.id === AccountManagerId
                    ) &&
                      AlluserProfiles.find((scg) => scg.id === AccountManagerId)
                        .title +
                      " " +
                      AlluserProfiles.find(
                        (scg) => scg.id === AccountManagerId
                      ).firstName +
                      " " +
                      AlluserProfiles.find(
                        (scg) => scg.id === AccountManagerId
                      ).lastName}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Sales Manager
                  </div>
                  <div className="view-configuration-drawer-description">
                    <div className="view-configuration-drawer-description ">
                      {AlluserProfiles.find(
                        (scg) => scg.id === SalseManagerId
                      ) &&
                        AlluserProfiles.find((scg) => scg.id === SalseManagerId)
                          .title +
                        " " +
                        AlluserProfiles.find(
                          (scg) => scg.id === SalseManagerId
                        ).firstName +
                        " " +
                        AlluserProfiles.find(
                          (scg) => scg.id === SalseManagerId
                        ).lastName}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input config-input-flex-1">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Operation Manager
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={OperationManagerId}
                      onChange={(ev) => setOperationManagerId(ev.target.value)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {AlluserProfiles &&
                        AlluserProfiles.map((categoria) => (
                          <MenuItem key={categoria.id} value={categoria.id}>
                            {categoria.title && categoria.title}{" "}
                            {categoria.firstName && categoria.firstName}{" "}
                            {categoria.lastName && categoria.lastName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Operation Manager
                  </div>
                  <div className="view-configuration-drawer-description">
                    <div className="view-configuration-drawer-description ">
                      {AlluserProfiles.find(
                        (scg) => scg.id === OperationManagerId
                      ) &&
                        AlluserProfiles.find(
                          (scg) => scg.id === OperationManagerId
                        ).title +
                        " " +
                        AlluserProfiles.find(
                          (scg) => scg.id === OperationManagerId
                        ).firstName +
                        " " +
                        AlluserProfiles.find(
                          (scg) => scg.id === OperationManagerId
                        ).lastName}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="configuration-heading-container configuration-title-flex-container">
              <div>Country Specific Tax</div>
            </div>
            <div className="configuration-view-edit-drawer-input-container">
            {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <Autocomplete
                  id="TaxName"
                  value={
                    taxList.find((v) => v === csTaxName) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    setcsTaxName(newValue ? newValue : "");
                  }}
                  options={taxList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Tax Name"
                      value={csTaxName}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                </div>
              )}
            {action !== "view" && (
              <div className="configuration-view-edit-drawer-input config-input-flex-1">                
                <TextValidator
                  variant="outlined"
                  label="Tax Value"
                  size="small"
                  color="secondary"
                  value={csTaxValue}
                  validators={['required','minNumber:0',csTaxType=="Percentage"? 'maxNumber:100':'maxNumber:9999999999999999','matchRegexp:^\\d{0,9}\.?\\d{0,2}$']}
                  errorMessages={['required','Min value is 0',csTaxType=="Percentage"? "Max value is 100":"",'Only 2 digits after decimal is allowed']}
                  onChange={(event) => setcsTaxValue(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">Tax Name</div>
                  <div className="view-configuration-drawer-description">
                    {csTaxName}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">Tax Value</div>
                  <div className="view-configuration-drawer-description">
                    {csTaxValue}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="TaxType"
                  value={
                    calTypes.find((v) => v === csTaxType) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    setcsTaxType(newValue ? newValue : "");
                    setcsTaxValue("0.00");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Tax Type"
                      value={csTaxType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="configuration-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  id="TaxApplicableOn"
                  value={
                    applicableOnList.find((v) => v === csTaxApplicableOn) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    setcsTaxApplicableOn(newValue ? newValue : "");
                  }}
                  options={applicableOnList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Applicable On"
                      value={csTaxApplicableOn}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                <div className="view-configuration-drawer-title">Tax Type</div>
                <div className="view-configuration-drawer-description">
                  {csTaxType}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-configuration-drawer config-input-flex-1">
                <div className="view-configuration-drawer-title">Tax ApplicableOn</div>
                <div className="view-configuration-drawer-description">
                  {csTaxApplicableOn}
                </div>
              </div>
            )}
            </div> */}
          </div>
          {action !== "view" && (
            <div>
              {/* <p className="update-msg">Kindly wait for 10 mins to get your expected result,after update</p> */}
              <Button
                variant="outlined"
                disabled={isApprovalBtnDisabled ? true : false}
                color="primary"
                size="large"
                style={{
                  textTransform: "none",
                  marginLeft: "25px",
                  marginRight: "25px",
                  marginBottom: "25px",
                  width: "50%",
                }}
                type="submit"
              >

                {action === "edit" ? "Update" : "Create"}
                {/* {showLoader &&
            <RiseLoading client={showLoader} />
          } */}
              </Button>
              <Button
                disabled={
                  action === "new" || isApprovalBtnDisabled ? true : false
                }
                variant="outlined"
                color="primary"
                size="large"
                style={{
                  textTransform: "none",
                  marginLeft: "25px",
                  marginRight: "25px",
                  marginBottom: "25px",
                  width: "50%",
                }}
                onClick={handleSendApproval}
              >
                Send for Approval
              </Button>
            </div>
          )}
        </ValidatorForm>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {action === "new" ? (
          <Alert className="mx-4 my-3" severity="error">
            Please add the client First
          </Alert>
        ) : (
          <div className="users-card-container">
            <div className="invitation-tab">
              <ValidatorForm onError={(errors) => console.log(errors)} onSubmit={() => handleRegisterUser("new")} autoComplete="off">
                <input type="text" style={{ display: 'none' }} />
                <input type="password" style={{ display: 'none' }} />

                <div className="configuration-heading-container configuration-title-flex-container">
                  <div>Register User</div>
                </div>

                <div className="d-flex m-3">
                  <div className="me-3">
                    <TextField
                      style={{ width: '100px' }}
                      select
                      required
                      label="Title"
                      variant="outlined"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      autoComplete="off"
                    >
                      {titleOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="me-3">
                    <TextValidator
                      style={{ width: '250px' }}
                      required
                      label="First Name"
                      variant="outlined"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      autoComplete="off"
                      validators={['matchRegexp:^(?! )[a-zA-Z ]*(?<! )$']}
                      errorMessages={['Only alphabets and spaces (not at the beginning or end) are allowed']}
                    />
                  </div>
                  <div>
                    <TextValidator
                      style={{ width: '250px' }}
                      required
                      label="Last Name"
                      variant="outlined"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      autoComplete="off"
                      validators={['matchRegexp:^(?! )[a-zA-Z ]*(?<! )$']}
                      errorMessages={['Only alphabets and spaces (not at the beginning or end) are allowed']}
                    />
                  </div>
                </div>

                <div className="d-flex m-3 bottom-line-divider">
                  <div className="me-3">
                    <TextValidator
                      style={{ width: '250px' }}
                      required
                      label="Date of birth"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      type="date"
                      value={dob}
                      validators={['required']}
                      errorMessages={['required']}
                      InputProps={{
                        inputProps: {
                          max: maxAdultDate,
                        },
                      }}
                      onChange={(e) => setDob(e.target.value)}
                      onKeyDown={(e) => e.preventDefault()}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="d-flex m-3">
                  <div>
                    <TextValidator
                      style={{ width: '300px' }}
                      variant="outlined"
                      label="Contact Number"
                      required
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                      validators={['matchRegexp:^[0-9 ]+$']}
                      errorMessages={["Only numbers are allowed"]}
                      autoComplete="off"
                    />
                  </div>
                  <div className="ms-3">
                    <TextValidator
                      style={{ width: '300px' }}
                      required
                      label="Email"
                      variant="outlined"
                      value={subUserEmail}
                      onChange={(e) => setSubUserEmail(e.target.value)}
                      validators={['required', 'isEmail']}
                      errorMessages={['This field is required', 'Email is not valid']}
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="d-flex m-3 bottom-line-divider">
                  <div>
                    <TextValidator
                      style={{ width: '300px' }}
                      variant="outlined"
                      margin="normal"
                      size="small"
                      label="Password"
                      color="primary"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      validators={["required"]}
                      errorMessages={["This Field is Required."]}
                      onKeyUp={validatePassword}
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        )
                      }}

                    />
                    <PasswordStrengthBar minLength="4" password={password} />
                  </div>
                  <div>
                    <TextValidator
                      className="ms-3"
                      style={{ width: '300px' }}
                      type="password"
                      variant="outlined"
                      margin="normal"
                      size="small"
                      label="Confirm Password"
                      color="primary"
                      fullWidth
                      value={confirmPassword}
                      onChange={(event) => setConfirmPassword(event.target.value)}
                      validators={["required"]}
                      errorMessages={["This Field is Required."]}
                      onKeyUp={validatePassword}
                      autoComplete="new-password"
                    />
                  </div>
                </div>

                <div className="d-flex m-3">
                  <div className="me-3">
                    <Autocomplete
                      style={{ width: '300px' }}
                      id="role-select"
                      required
                      value={roleList.find((v) => v.roleId === role) || null}
                      onChange={(event, newValue) => {
                        setRole(newValue ? newValue.roleId : '');
                      }}
                      options={roleList}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          label="Role"
                          variant="outlined"
                          color="secondary"
                          required
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Autocomplete
                      style={{ width: '300px' }}
                      id="status-select"
                      value={statusOptions.find((v) => v.value === subUserStatus) || null}
                      onChange={(event, newValue) => {
                        setSubUserStatus(newValue ? newValue.value : '');
                      }}
                      options={statusOptions}
                      required
                      getOptionLabel={(option) => option.type}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          label="Is Active"
                          variant="outlined"
                          color="secondary"
                          required
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="mt-5 ms-3">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={allNotValid}
                    style={{ textTransform: 'none' }}
                    type="submit"
                  >
                    Register
                  </Button>
                  <div className={saveStatusCode === 200 ? "res-succ-msg" : "res-err-msg"}>{message}</div>
                </div>

                <div className="must-container-ud cfb mt-3">
                  {mustContainData.map((data) => (
                    <MustContainItem key={data.label} data={data} />
                  ))}
                </div>
              </ValidatorForm>

            </div>

            <UserTable
              openAddUserDrawer={openAddUserDrawer}
              setAction={setUserAction}
              userProfiles={userProfiles}
              setPage={setPage}
              getClients={getClients}
              page={page}
              flag={flag}
            ></UserTable>
            <Drawer
              anchor="right"
              open={addUserdrawer}
              onClose={toggleAddUserDrawer(false)}
              BackdropProps={{ invisible: true }}
              variant="persistent"
              className="drawer-width"
              transitionDuration={{ enter: 500, exit: 500 }}
            >
              <div className="drawer-min-width-40">
                <AddUser
                  toggleAddUserDrawer={toggleAddUserDrawer}
                  action={addUserAction}
                  selectedItem={selectedUserItem}
                  setSelectedUserItem={setSelectedUserItem}
                  setAction={setAddUserAction}
                  handleSnackbarToggle={handleSnackbarToggle}
                  getClients={getClients}
                  allRoles={""}
                  clientIdForClientStaff={clientId}
                  flag={true}
                  setaddUserdrawer={setaddUserdrawer}
                ></AddUser>
              </div>
            </Drawer>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input config-input-flex-1">
              <TextField
                variant="outlined"
                label="Client API"
                size="small"
                color="secondary"
                value={apiClientId}
                onChange={(event) => setApiClientId(event.target.value)}
              />
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">Client API</div>
              <div className="view-configuration-drawer-description ">
                {apiClientId}
              </div>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="vendor-heading-container vendor-title-flex-container">
          <div>Client Document Vault</div>
        </div>
        <ValidatorForm
          onError={(errors) => console.log(errors)}
          onSubmit={uploadFile}
        >
          <div className="vendor-view-edit-drawer-container">
            <div className="vendor-view-edit-drawer-input-container">
              <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  placeholder="Company Incorporation, PAN, GST, ect.."
                  variant="outlined"
                  label="Document Type"
                  size="small"
                  color="secondary"
                  value={documentType}
                  onChange={(event) => setdocumentType(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                />
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="vendor-view-edit-drawer-input config-input-flex-1">
                <Button
                  variant="contained"
                  component="label"
                  size="small"
                  style={{
                    textTransform: "none",
                  }}
                >
                  Choose File
                  <input type="file" hidden onChange={saveFile} />
                </Button>{" "}
                {fileName}
              </div>
            </div>
          </div>
          <div className="vendor-view-edit-drawer-input-container">
            <div>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                style={{
                  textTransform: "none",
                  marginLeft: "25px",
                  marginRight: "25px",
                  marginBottom: "25px",
                  width: "500px",
                }}
                type="submit"
                disabled={isuploadBtnDisabled}
              >
                Upload file to vault
              </Button>
            </div>
          </div>
        </ValidatorForm>
        <div className="vendor-view-edit-drawer-input-container">
          {filesList ? (
            filesList.length === 0 ? (
              <Alert className="mx-4 my-2" severity="error">
                There are no files, please upload files.
              </Alert>
            ) : (
              <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                  <TableHead>
                    <TableRow>
                      <TableCell>File</TableCell>
                      <TableCell>File Type</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesList.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            {row.clientpforile.fileName}
                          </div>
                          <div className="table-cell-description">
                            {row.clientpforile.fileBelongsTo}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            {row.clientpforile.fileType}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="right"
                        >
                          <div className="table-cell-title">
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                textTransform: "none",
                              }}
                              href={row.clientpforile.imagePath}
                              target="_blank"
                              download
                            >
                              Download
                            </Button>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : (
            <RiseLoading client={true} />
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ClientPayment
          clientId={clientId}
          PaymentType={PaymentType}
          Currency={Currency}
          action={action}
          setAction={setAction}
          getClients={getClients}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className="vendor-heading-container vendor-title-flex-container">
          <div>Client Approval History</div>
        </div>
        <div className="vendor-view-edit-drawer-input-container">
          {historyList ? (
            historyList.length === 0 ? (
              <Alert className="mx-4 my-2" severity="error">
                There is no history for this vendor.
              </Alert>
            ) : (
              <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historyList.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            {row.configurationHistory.description}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            <Chip
                              label={row.configurationHistory.historyStatus}
                              size="small"
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <div className="table-cell-description">
                            Created by:{" "}
                            <span style={{ color: "black" }}>
                              {row.createdBy}
                            </span>
                          </div>
                          <div className="table-cell-description">
                            Created on:{" "}
                            {row.configurationHistory.created.replace(
                              "T",
                              " , "
                            )}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : (
            <RiseLoading client={true} />
          )}
        </div>
      </TabPanel>
      <div className="vendorDrawerSnackbar">
        <Snackbar
          open={alert.open}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAlert({ ...alert, open: false })}
          autoHideDuration={10000}
        >
          <Alert
            onClose={() => setAlert({ ...alert, open: false })}
            severity={alert.severity}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </div>
      {showLoader && <RiseLoading client={showLoader} />}
      <Dialog
        open={otpPopUp}
        // onClose={() => setOtpPopUp(false)}
        onClose={handleOtpPopClose}
      >
        <Otp
          setOtpPopUp={setOtpPopUp}
          otp={otp}
          setOtp={setOtp}
          subUserStatus={subUserStatus}
          updateUserRole={handleRegisterUser}
          resOtpTime={resOtpTime}
          setResOtpTime={setResOtpTime}
          setExpired={setExpired}
          resMsg={resMsg}
          setResMsg={setResMsg}
          notValid={notValid}
          setNotValid={setNotValid}
          disableOtpInput={disableOtpInput}
          setDisableOtpInput={setDisableOtpInput}
          userName={subUserEmail}
          retryCount={retryCount}
          setRetryCount={setRetryCount}
        />
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Suggestions from "./Suggestions/Suggestions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "./SearchBar.css";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { formatDate } from "../../../services/dateFormatter";
import OfflineImportReport from "../../OfflineImportReport/OfflineImportReport";
import { useLocation } from "react-router-dom";
export default function SearchBar(props) {
  //let [q, setQ] = useState("");
  let [suggestions, setSuggestions] = useState([]);
  let [showSuggestions, setShowSuggestions] = useState(false);
  const location = useLocation();
  const onSearchHandler = () => {
    props.setCurrentPage(1);
    props.postSearchHandler(props.q, props.inputDateFrom, props.inputDateTo, false);
    // props.setPageNav(false);
    // setShowSuggestions(false);
  };
  const onChangeHandler = (event) => {
    props.setQ((q) => event.target.value);
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     const body = {
  //       q: q,
  //       top: 5,
  //       suggester: "sg",
  //     };

  //     const headers = {
  //       "Content-Type": "application/json",
  //     };

  //     if (q === "") {
  //       setSuggestions([]);
  //     } else {
  //       axios
  //         .post(
  //           process.env.REACT_APP_CORE_URL +
  //             "api/Configuration/OrderQueue/Suggest",
  //           body,
  //           {
  //             headers,
  //           }
  //         )
  //         .then((response) => {
  //           console.log(JSON.stringify(response.data));
  //           setSuggestions(response.data.suggestions);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           setSuggestions([]);
  //         });
  //     }
  //   }, 300);
  //   return () => clearTimeout(timer);
  // }, [q]);
  useEffect(() => {
    console.log("Q", props.q);
  }, [props.q]);
  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   const trackPageView = (path) => {
  //     window.dataLayer.push({
  //       event: 'page_view',
  //       page_path: path,
  //       user_id: user.user_id,
  //       user_email: user.email_id,
  //     });
  //   };
  //   trackPageView(location.pathname); // Track page load

  //   return () => {
  //     trackPageView(location.pathname); // Track navigation change
  //   };
  // }, [location]);
  const clearSearch = () => {
    document.getElementById("search-box").value = "";
    props.setPageNav(false);
    setShowSuggestions(false);
    props.setDateFrom((DateFrom) => null);
    props.setinputDateFrom((inputDateTo) => null);
    props.setDateTo((DateFrom) => null);
    props.setinputDateTo((inputDateTo) => null);
    props.setFilters((filters) => []);
    props.setQ((q) => "");
  };

  useEffect(() => {
    if (
      props.filters.length == 0 &&
      props.isPageNav == false &&
      props.initialLoad == true
    ) {
      (props.q !== "") ? props.postSearchHandler(props.q, null, null, false) : props.postSearchHandler("", null, null, false);
    }
  }, [props.filters]);

  // var suggestionDiv;
  // if (showSuggestions) {
  //   suggestionDiv = (
  //     <Suggestions
  //       suggestions={suggestions}
  //       suggestionClickHandler={(s) => suggestionClickHandler(s)}
  //     ></Suggestions>
  //   );
  // } else {
  //   suggestionDiv = "";
  // }

  return (
    <div className="trans-client-container">
      <div className="trans-client-container-label trans-client-container-labelLeft">
        Search
      </div>
      <div className="suggestions trans-client-container-item2 debit-transaction-panel">
        <TextField
          variant="filled"
          autoComplete="off"
          type="text"
          id="search-box"
          style={{ width: "300px" }}
          placeholder={
            props?.data?.includes("ClientName")
              ? "ClientName, ClientId, VendorName, PCC"
              : "What are you looking for?"
          }
          onChange={onChangeHandler}
          value={props.q}
          defaultValue={props.q}
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          }
          required
        />
        {/* {suggestionDiv} */}
      </div>
      {/* <div className="input-group" onKeyDown={(e) => onEnterButton(e)}> */}
      <div className="trans-client-container-label">From Date</div>
      <div className="trans-client-container-item2">
        <Fragment>
          <KeyboardDatePicker
            maxDate={new Date()}
            id="DateFrom"
            autoOk
            margin="normal"
            variant="filled"
            format="dd/MM/yyyy"
            size="small"
            value={props.DateFrom !== undefined ? props.DateFrom : null}
            inputValue={props.DateFrom !== undefined ? props.DateFrom : null}
            InputAdornmentProps={{
              position: "start",
              color: "primary",
            }}
            onChange={(date) => {
              props.setDateFrom(date ? formatDate(date) : null);
              props.setinputDateFrom(date ? date : null);
            }}
            required
          />
        </Fragment>
      </div>
      <div className="trans-client-container-label">To Date</div>
      <div className="trans-client-container-item2">
        <Fragment>
          <KeyboardDatePicker
            maxDate={new Date()}
            id="DateTo"
            autoOk
            margin="normal"
            variant="filled"
            format="dd/MM/yyyy"
            size="small"
            value={props.DateTo !== undefined ? props.DateTo : null}
            inputValue={props.DateTo !== undefined ? props.DateTo : null}
            InputAdornmentProps={{
              position: "start",
              color: "primary",
            }}
            onChange={(date) => {
              props.setDateTo(date ? formatDate(date) : null);
              props.setinputDateTo(date ? date : null);
            }}
            required
          />
        </Fragment>
      </div>
      {props.flag === "OfflineImportReport" ? (
        <Button
          className="trans-client-container-search-button"
          onClick={props.myqueueExportExcel}
          disabled={!props.DateFrom && !props.DateTo}
        >
          Export Data
        </Button>
      ) : (
        <Button
          className="trans-client-container-button"
          style={{ marginLeft: "5px" }}
          color="primary"
          type="submit"
          onClick={onSearchHandler}
          variant="contained"
        >
          Search
        </Button>
      )}
      <Button
        className="trans-client-container-button"
        style={{
          marginLeft: "5px",
          color: "white",
          backgroundColor: "#9f155a",
        }}
        type="submit"
        onClick={clearSearch}
        variant="contained"
      >
        Clear
      </Button>
    </div>
  );
}

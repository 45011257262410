//import * as React from "react";
import React, { useState, useEffect } from "react";
import "./ViewCancellation.css";
import { Row } from "react-bootstrap/esm";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { checkIfUserStaff } from "../../services/AuthService";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import RiseLoading from "../Loader/RiseLoader";
import Input from "@mui/joy/Input";
import dayjs from "dayjs";
import Button from "@mui/joy/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import findAirlinebyCode from "../../functions/findAirlineByCode";
import findAirportbyCode from "../../functions/findAirportByCode";
import getAirportByCode from "../../functions/getAirportByCode";
import Divider from "@mui/joy/Divider";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useHistory } from "react-router-dom";
import ViewCancellationDetails from "./ViewCancellationDetails";
import { formatDate } from "../../services/dateFormatter";
import TablePagination from "@material-ui/core/TablePagination";
import { IconButton } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Autocomplete from "@mui/material/Autocomplete";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function ViewCancellation() {
  const today = dayjs();
  const history = useHistory();
  let CancellationStatusOptions = [];
  const [selectedRef, setSelectedRef] = useState("cancel");
  const [selecteddate, setselecteddate] = useState("");
  const [cancelViewDetails, setCancelViewDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMsgTkt, seterrorMsgTkt] = useState("");
  const [updateProcess, setupdateProcess] = useState(false);
  const [gfsinputValue, setgfsInputValue] = useState("");
  const [gxinputValue, setgxInputValue] = useState("");
  const [FromDate, setFromDateValue] = useState(today);
  const [ToDate, setToDateValue] = useState(today);
  const [cancelRemarks, setCancelRemarks] = useState("");
  const [selectedcancelstatus, setCancelSelectedstatus] = useState("None");
  const [selectedservice, setSelectedService] = useState("");
  const [clientIdvalue, setClientIdValue] = useState("");
  const [bookingDetails, setbookingDetails] = useState([]);
  const [historyDetail, setHistoryDetail] = useState([]);
  const [refundConfrimDialogOpen, setRefundConfrimDialogOpen] = useState(false);
  const [totalFare, settotalFare] = useState([]);
  const [cancelchargeData, setCancellationCharge] = useState([]);
  const [supplierservicecharge, setSupplierServiceCharge] = useState([]);
  const [staffremarks, setstaffRemarks] = useState("");
  const [stfcharge, setSTFCharge] = useState([]);
  const [rafcharge, setRAFCharge] = useState([]);
  const [gfServiceFeeChange, setGFServiceFeeChange] = useState([]);
  const [netrefundCharge, setNetRefundCharge] = useState([]);
  const [clientCancellationCharge, setClientCancellationCharge] = useState([]);
  const [clientRefundAmount, setClientRefundAmount] = useState([]);
  const [selectedviewStatus, setViewSelectedStatus] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [tableData, setTableData] = useState([]);
  const [clientProfiles, setClientProfiles] = useState(null);


  const handleChangePage = (event, newPage, page, rowsPerPage) => {
    setPage(newPage + 1);
  };


  useEffect(() => {

    if (cancelViewDetails) {
      const startIndex = (page - 1) * rowsPerPage;
      const endIndex = page * rowsPerPage;
      setTableData(cancelViewDetails.slice(startIndex, endIndex));
    }
    else {
      setTableData([]);
    }
  }, [cancelViewDetails, page])


  useEffect(() => {
    if (checkIfUserStaff()) {
      getClients();
    }
  }, []);

  useEffect(() => {
    if (clientProfiles) {
      loadViewCancellationDetails();
    }
  }, [clientProfiles])


  const handleViewStatusChange = (event, newValue) => {
    setViewSelectedStatus(newValue);
  };
  const handleFromDateChange = (event, newValue) => {
    setFromDateValue(newValue);
  };
  const handleToDateChange = (event, newValue) => {
    setToDateValue(newValue);
  };
  const handleServicesChange = (event, newValue) => {
    setSelectedService(newValue);
  };
  const handlerefNoChange = (event, newValue) => {

    setSelectedRef(newValue);
  };

  useEffect(() => {
    selectedRef === "gfs" ? setselecteddate("Booking_Date") : setselecteddate("Cancel_Date");
    setgxInputValue("");
    setgfsInputValue("");
  }, [selectedRef])


  const viewcancel = (event) => {
    if (FromDate > ToDate) {
      alert(`From date shouldn't be greater than to date`);
    } else {
      event.preventDefault();
      loadViewCancellationDetails();
    }

  };
  const handlegxInputChange = (e) => {
    setgxInputValue(e.target.value);
  };
  const handlegfsInputChange = (e) => {
    setgfsInputValue(e.target.value);
  };
  const handleClientInputChange = (e) => {
    setClientIdValue(e.target.value);
  };
  const handleDateChange = (event, newValue) => {
    setselecteddate(newValue);
  };

  function handleStatusClick(orderRefId, cancelRefId, cancelStatus) {


    const filteredPaxIds = cancelViewDetails
      .filter((item) => item.cancelrefno === cancelRefId)
      .map((item) => item.paxId);
    const filteredSegGroupId = cancelViewDetails
      .filter((item) => item.cancelrefno === cancelRefId)
      .map((item) => item.seggroupId);


    setShowLoader(true);

    sessionStorage.setItem('orderRefId', orderRefId);
    sessionStorage.setItem('cancelRefId', cancelRefId || 0);

    window.open("/flight/ViewCancellationDetails", '_blank');

    setShowLoader(false);
  }


  async function loadViewCancellationDetails() {

    setPage(1)
    // setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/GetViewCancellation",
        JSON.stringify({
          orderefno: selectedRef === "gfs" ? gfsinputValue : null,
          cancelrefno: selectedRef === "cancel" ? gxinputValue : null,
          clientId: (gfsinputValue || gxinputValue) ? 0 : clientIdvalue || 0,
          fromDate: (gfsinputValue || gxinputValue) ? null : FromDate || null,
          toDate: (gfsinputValue || gxinputValue) ? null : ToDate || null,
          DateChoosen: selecteddate || null,
          status: (gfsinputValue || gxinputValue) ? null : selectedviewStatus || null,
          services: (gfsinputValue || gxinputValue) ? null : selectedservice || null,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (response?.data[0]?.cancelrefno) {
          let viewcancel = response.data;
          const sortedDetails =
            viewcancel &&
            viewcancel.sort((a, b) => {
              return new Date(b.cancelreqdate) - new Date(a.cancelreqdate);
            });

          setCancelViewDetails(sortedDetails);
          setShowLoader(false);
        }
        else {
          setCancelViewDetails([]);
          setShowLoader(false);
        }

      })
      .catch((err) => {
        setErrMsg(err);
        setShowLoader(false);
        console.log(err);
      });
  }


  async function getClients() {
    setShowLoader(true);
    await axios
      // .get(process.env.REACT_APP_CORE_URL + "api/Configuration/ClientProfile")
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/GetClientList?sorts=created&filters=isactive==true"
      )
      .then((response) => {
        // console.log("clientProfiles", response);
        let tempArray = response.data.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );
        setClientProfiles((clientProfiles) => structuredClone(tempArray));
      }).catch((err) => {
        setShowLoader(false);
      });
  }

  return (
    <div>
      <div className="calcellationSelectionParentContainer">
        <h5 style={{ textAlign: "left", padding: "30px", color: "#a0155a" }}>
          View Cancellation
        </h5>
        <div className="calcellationSelectionContainer">
          <div className="view-cancel">
            <div className="cancellationDelectionRow">
              <div colSpan="4" className="padLR2vw padTB1vw">
                <Select
                  defaultValue={"cancel"}
                  variant="outlined"
                  onChange={handlerefNoChange}
                >
                  <Option value="gfs">GFS RefNo</Option>
                  <Option value="cancel">Cancellation RefNo</Option>
                </Select>
              </div>
              <div colSpan="4" className="padLR2vw padTB1vw">
                {selectedRef === "gfs" ? (
                  <Input
                    placeholder="Search Ref No"
                    size="md"
                    style={{
                      "&:focus, &:hover": {},
                    }}
                    value={gfsinputValue}
                    onChange={handlegfsInputChange}
                  />
                ) : (
                  <Input
                    placeholder="Free Text Search"
                    size="md"
                    style={{
                      "&:focus, &:hover": {
                        borderColor: "#a0155a",
                      },
                    }}
                    value={gxinputValue}
                    onChange={handlegxInputChange}
                  />
                )}
              </div>
              {checkIfUserStaff() ? (
                <div colSpan="4" className="padLR2vw padTB1vw">
                  {/* <Input
                    placeholder="Client Id"
                    size="md"
                    style={{
                      "&:focus, &:hover": {
                        borderColor: "#a0155a",
                      },
                    }}
                    value={clientIdvalue}
                    onChange={handleClientInputChange}
                  /> */}
                  <ValidatorForm
                    onError={errors => console.log(errors)}
                  >
                    <Autocomplete

                      style={{ width: "200px", backgroundColor: "#d3d3d317" }}
                      id="Client select"
                      value={
                        clientProfiles !== null
                          ? clientProfiles.find((v) => v.id === clientIdvalue)
                          : null
                      }
                      onChange={(event, newValue) => {
                        setClientIdValue(newValue ? newValue.id : "");
                      }}
                      options={clientProfiles || []}
                      getOptionLabel={(option) => `${option.clientName} (${option.id})`}
                      filterOptions={(options, { inputValue }) =>
                        options.filter((option) =>
                          option.clientName.toLowerCase().includes(inputValue.toLowerCase().trim()) ||
                          option.id.toString().includes(inputValue.trim())
                        )
                      }
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          className="rounded"
                          label="Select Client"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          validators={["required"]}
                          errorMessages={["Client is required"]}
                        />
                      )}
                    />
                  </ValidatorForm>
                </div>
              ) : null}
              <div colSpan="4" className="padLR2vw padTB1vw">
                <Select defaultValue="None" onChange={handleViewStatusChange}>
                  <Option value="None">Status</Option>
                  <Option value="Cancel_Requested">Cancel Requested</Option>
                  <Option value="Cancelled">Cancelled</Option>
                  {checkIfUserStaff() && <Option value="Refund_Initiated">Refund Initiated</Option>}
                  {checkIfUserStaff() && <Option value="Voided">Voided</Option>}
                  <Option value="Refunded">Refunded</Option>
                  <Option value="Cancel_Rejected">Cancel_Rejected</Option>
                </Select>
              </div>
              <div colSpan="4" className="padLR2vw padTB1vw">
                <Select
                  defaultValue="All Services"
                  onChange={handleServicesChange}
                >
                  <Option value="All Services">All Services</Option>
                  <Option value="Flight">Flight</Option>
                </Select>
              </div>
            </div>
            <div className="cancellationDelectionRow">
              <div colSpan="4" className="padLR2vw padTB1vw">
                <Select
                  value={selecteddate}
                  // defaultValue={"Cancel_Date"}
                  variant="outlined"
                  onChange={handleDateChange}
                  placeholder="Date Type "
                >
                  <Option value="Booking_Date">Booking Date</Option>
                  <Option value="Cancel_Date">Cancel Date</Option>
                </Select>
              </div>
              <div colSpan="4" className="padLR2vw padTB1vw">
                {selecteddate === "Booking_Date" ? (
                  <div className="add-search-if-items-container">
                    <div className="add-search-if-item flex-1-input margin-right-16">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(newValue) => {
                            setFromDateValue(newValue.$d ? newValue.$d : null);
                          }}
                          defaultValue={today}
                          disableFuture
                          format="DD/MM/YYYY"
                          maxDate={today}
                          value={FromDate ? dayjs(FromDate) : dayjs()}
                          label="From Date"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="add-search-if-item flex-1-input margin-right-16">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(newValue) => {
                            setToDateValue(newValue.$d ? newValue.$d : null);
                          }}
                          defaultValue={today}
                          disableFuture
                          format="DD/MM/YYYY"
                          minDate={dayjs(FromDate)}
                          maxDate={today}
                          value={ToDate ? dayjs(ToDate) : dayjs()}
                          label="To Date"
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                ) : (
                  <div className="add-search-if-items-container">
                    <div className="add-search-if-item flex-1-input margin-right-16">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(newValue) => {
                            setFromDateValue(newValue.$d ? newValue.$d : null);
                          }}
                          defaultValue={today}
                          disableFuture
                          format="DD/MM/YYYY"
                          maxDate={today}
                          value={FromDate ? dayjs(FromDate) : dayjs()}
                          label="From Date"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="add-search-if-item flex-1-input margin-right-16">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(newValue) => {
                            setToDateValue(newValue.$d ? newValue.$d : null);
                          }}
                          defaultValue={today}
                          disableFuture
                          format="DD/MM/YYYY"
                          minDate={dayjs(FromDate)}
                          maxDate={today}
                          value={ToDate ? dayjs(ToDate) : dayjs()}
                          label="To Date"
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
              </div>
              {/* <div colSpan="4" className="padLR2vw padTB1vw">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker defaultValue={today} />
              </LocalizationProvider>
              </div> */}


              <div colSpan="4" className="padLR2vw padTB1vw">
                <Button
                  variant="contained"
                  style={{
                    background: "#a0155a",
                    color: "white",
                    fontSize: "16px",
                  }}
                  onClick={viewcancel}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
        {showLoader ? (
          <RiseLoading isviewBooking={showLoader} />
        ) : (
          <div className="cancellationViewContainer">
            {tableData && tableData[0] ? (
              <>
                <h5 style={{ color: "#a0155a", margin: "10px" }}>
                  Cancellation Details List
                </h5>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {checkIfUserStaff() ? (
                          <TableCell>
                            <Typography
                              variant="h5"
                              style={{ fontSize: "18px", color: "green" }}
                            >
                              Client ID
                            </Typography>
                          </TableCell>
                        ) : null}
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            CancelRequestDate
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            BookingRefNo
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            User
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            CancelRefNo
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            Services
                          </Typography>{" "}
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            Airline
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            PassengerName
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            BookingStatus
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(tableData) &&
                        tableData.map((canceldetail, index) => (
                          <TableRow key={index}>
                            {checkIfUserStaff() ? (
                              <TableCell>{canceldetail.clientid}</TableCell>
                            ) : null}
                            <TableCell>
                              {formatDate(canceldetail.cancelreqdate)}
                            </TableCell>
                            <TableCell>{canceldetail.orderrefid}</TableCell>
                            <TableCell>{canceldetail.user}</TableCell>
                            <TableCell>{canceldetail.cancelrefno}</TableCell>
                            <TableCell>{canceldetail.services}</TableCell>
                            <TableCell>{canceldetail.airline}</TableCell>
                            <TableCell>{canceldetail.paxname}</TableCell>
                            {/* <TableCell>{canceldetail.status}</TableCell> */}
                            <TableCell
                              onClick={() =>
                                handleStatusClick(canceldetail.orderrefid, canceldetail.cancelrefno,
                                  ((!checkIfUserStaff() && canceldetail.status === "Refund_Initiated") ? 'Cancelled' : (!checkIfUserStaff() && canceldetail.status === "Void_Refunded") ? 'Refunded' : (!checkIfUserStaff() && canceldetail.status === "Voided") ? 'Cancelled' : canceldetail.status))
                              }
                              style={{
                                cursor: "pointer",
                                color: "#a0155a",
                                transition: "color 0.3s ease",
                              }}
                              className="change-hover-style"
                            >
                              {(!checkIfUserStaff() && canceldetail.status === "Refund_Initiated") ? 'Cancelled' : (!checkIfUserStaff() && canceldetail.status === "Void_Refunded") ? 'Refunded' : (!checkIfUserStaff() && canceldetail.status === "Voided") ? 'Cancelled' : canceldetail.status}

                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25]}
                  component="div"

                  count={cancelViewDetails?.length}
                  rowsPerPage="25"
                  page={page - 1}
                  onChangePage={(event, newPage) => handleChangePage(event, newPage, page, rowsPerPage)}
                  ActionsComponent={TablePaginationActions}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "50px",
                    color: "grey",
                  }}
                >
                  <SearchIcon fontSize="large"></SearchIcon>
                </div>
                <p className="text-center">No result found for your search</p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "./Vendor.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import AddVendorCard from "./AddVendorCard/AddVendorCard";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import VendorTable from "./VendorTable/VendorTable";
import VendorDrawer from "./VendorDrawer/VendorDrawer";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Radio, RadioGroup } from "@mui/material";

// import { isUserHasPermission } from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Vendor() {
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [vendorName, setVendorName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [bookingSwitches, setBookingSwitches] = useState([]);
  const [alertText, setalertText] = useState("");
  const [mode, setMode] = useState("");
  const history = useHistory();
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [countryAndStationData, setCountryAndStationData] = useState([]);
  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  useEffect(() => {
    // if (isUserHasPermission('ClientRead')) {
    loadVendor();
    //  }else {
    //    history.push("/Unauthorized");
    //  }
  }, []);

  useEffect(() => { }, [alertText, mode]);

  async function loadVendor() {
    let filter = "";

    if (vendorName !== "") {
      filter = "vendorName==" + vendorName;
    }
    if (contactEmail !== "") {
      if (filter === "") {
        filter = "contactEmail==" + contactEmail;
      } else {
        filter = filter + "&contactEmail==" + contactEmail;
      }
    }
    if (filter !== "") filter = "?filters=" + filter;

    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor" + filter)
      .then((response) => {
        LoadCountryAndStationData();
        response.data.map((item) => {
          if (item.bookingSwitches.isActive) {
            item.bookingSwitches.status = "active";
          } else {
            item.bookingSwitches.status = "inactive";
          }
        });
        setBookingSwitches(response.data);
      })
      .catch((error) => console.log(error));
  }

  const filteredVendors = bookingSwitches?.filter((profile) => {

    const isActive = profile.bookingSwitches.isActive;

    if (selectedFilter === "all") {
      return true;
    } else if (selectedFilter === "active") {
      return isActive;
    } else if (selectedFilter === "inactive") {
      return !isActive;
    }

    return true;
  });

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };


  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
    // if (value === false) {
    //   loadVendor();
    // }
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };



  const handleSnackbarToggle = (text, mode) => {
    setalertText(text);
    setMode(mode);
    setDrawer(false);
    setSnackbarOpen(true);
    loadVendor();
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const LoadCountryAndStationData = async () => {
    await axios
      .get(process.env.REACT_APP_RAIL_API_URL + "Rail/Stations/v1/LoadCountryAndStationDataFromCSV")
      .then((response) => {
        setCountryAndStationData(response.data);
      })
      .catch((error) => console.log(error));
  }


  return (
    <div className="vendor-container">
      <div className="vendor-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-vendor-container">
              <div className="vendor-table-container">
                <div className="vendor-title-add-button-container">
                  <div className="vendor-heading">Vendor Details</div>
                  <div className="search-icon-button-container">
                    {/* <SearchIcon
                      fontSize="small"
                      className="search-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon> */}
                    {isUserHasPermission("VendorOnBoarding") ? (
                      <div
                        className="add-vendor-button mt-0px"
                        onClick={() => {
                          setAction("new");
                          setDrawer(true);
                        }}
                      >
                        Add New Vendor
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="">
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="position" row>
                      <FormControlLabel
                        value="all"
                        control={
                          <Radio
                            checked={selectedFilter === "all"}
                            onChange={() => handleFilterChange("all")}
                          />
                        }
                        label="All"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="active"
                        control={
                          <Radio
                            name="active"
                            checked={selectedFilter === "active"}
                            onChange={() => handleFilterChange("active")}
                          />
                        }
                        label="Active"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="inactive"
                        control={
                          <Radio
                            name="inactive"
                            checked={selectedFilter === "inactive"}
                            onChange={() => handleFilterChange("inactive")}
                          />
                        }
                        label="In Active"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="vendor-card-container">
                    <AddVendorCard
                      setVendorName={setVendorName}
                      setContactEmail={setContactEmail}
                      loadVendor={loadVendor}
                    ></AddVendorCard>
                  </div>
                </Collapse>
                <VendorTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  bookingSwitches={filteredVendors}
                  selectedFilter={selectedFilter}
                  loadVendor={loadVendor}
                ></VendorTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-87">
          <VendorDrawer
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
            countryAndStationData={countryAndStationData}
          ></VendorDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
      >
        {alertText && mode == "error" ? (
          <Alert onClose={handleSnackBarClose} severity="error">
            {alertText}
          </Alert>
        ) : (
          <Alert onClose={handleSnackBarClose} severity="success">
            {alertText}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}

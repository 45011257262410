import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import './Void.css'

const Void = ({ voidPopUp, setVoidPopUp }) => {
    const [itineraries, setItineraries] = useState([
        {
            id: 1,
            from: "JFK",
            to: "BOM",
            date: "29 Jan 2023",
            flight: "EK 597",
            pnr: "XxXxXx",
            passengers: [
                { id: 1, name: "Mohan Thalaiva", type: "Adult", ticketNumber: "0981234567890", status: "Confirmed" },
                { id: 2, name: "Suriya Rockstar", type: "Child", ticketNumber: "0981234567891", status: "Confirmed" }
            ]
        },
        {
            id: 2,
            from: "BOM",
            to: "JFK",
            date: "29 Mar 2023",
            flight: "EK 765",
            pnr: "XxXxXx",
            passengers: [
                { id: 1, name: "Mohan Thalaiva", type: "Adult", ticketNumber: "09812345678970", status: "Confirmed" },
                { id: 2, name: "Suriya Rockstar", type: "Child", ticketNumber: "0981234567891", status: "Confirmed" }
            ]
        }
    ]);

    const [selectedPax, setSelectedPax] = useState({});
    const [disabledPax, setDisabledPax] = useState({});
    const [wholeItinerarySelected, setWholeItinerarySelected] = useState(false);
    const [refundDetails, setRefundDetails] = useState({ bookingAmount: 0, cancellationFee: 0, serviceFee: 0, refund: 0 });


    const handleSelectPax = (itineraryId, paxId, ticketNumber) => {
        debugger
        const newSelectedPax = { ...selectedPax };
        const newDisabledPax = { ...disabledPax };
        const itinerary = itineraries.find(it => it.id === itineraryId);

        const isSelected = newSelectedPax[itineraryId]?.includes(paxId);

        if (isSelected) {
            newSelectedPax[itineraryId] = newSelectedPax[itineraryId].filter(id => id !== paxId);

            itineraries.forEach(itinerary => {
                itinerary.passengers.forEach(pax => {
                    if (pax.ticketNumber === ticketNumber) {
                        newDisabledPax[itinerary.id] = newDisabledPax[itinerary.id]?.filter(id => id !== pax.id) || [];
                        newSelectedPax[itinerary.id] = newDisabledPax[itinerary.id]?.filter(id => id !== pax.id) || [];
                    }
                });
            });
        } else {
            if (!newSelectedPax[itineraryId]) {
                newSelectedPax[itineraryId] = [];
            }
            newSelectedPax[itineraryId].push(paxId);

            itinerary.passengers.forEach(pax => {
                itineraries.forEach(otherItinerary => {
                    if (otherItinerary.id !== itineraryId) {
                        otherItinerary.passengers.forEach(otherPax => {
                            if (otherPax.ticketNumber === ticketNumber) {
                                if (!newDisabledPax[otherItinerary.id]) {
                                    newDisabledPax[otherItinerary.id] = [];
                                    newSelectedPax[otherItinerary.id] = [];
                                }
                                if (!newDisabledPax[otherItinerary.id].includes(otherPax.id)) {
                                    newDisabledPax[otherItinerary.id].push(otherPax.id);
                                    newSelectedPax[otherItinerary.id].push(otherPax.id);
                                }
                            }
                        });
                    }
                });
            });
        }

        setSelectedPax(newSelectedPax);
        setDisabledPax(newDisabledPax);
    };

    const handleSelectAll = (itineraryId) => {

        let newSelectedPax = { ...selectedPax };
        let newDisabledPax = { ...disabledPax };

        const itinerary = itineraries.find(it => it.id === itineraryId);

        if (newSelectedPax[itineraryId]?.length === itinerary.passengers.length) {
            newSelectedPax = {};
            newDisabledPax = {};
        } else {
            newSelectedPax[itineraryId] = itinerary.passengers.map(pax => pax.id);
            newDisabledPax[itineraryId] = itinerary.passengers.map(pax => pax.id);

            itinerary.passengers.forEach(pax => {
                itineraries.forEach(otherItinerary => {
                    if (otherItinerary.id !== itineraryId) {
                        otherItinerary.passengers.forEach(otherPax => {
                            if (otherPax.ticketNumber === pax.ticketNumber) {
                                if (!newDisabledPax[otherItinerary.id]) {
                                    newDisabledPax[otherItinerary.id] = [];
                                    newSelectedPax[otherItinerary.id] = [];
                                }
                                if (!newDisabledPax[otherItinerary.id].includes(otherPax.id)) {
                                    newDisabledPax[otherItinerary.id].push(otherPax.id);
                                    newSelectedPax[otherItinerary.id].push(otherPax.id);
                                }
                            }
                        });
                    }
                });
            });
        }

        setSelectedPax(newSelectedPax);
        setDisabledPax(newDisabledPax);
    };


    const handleWholeItinerarySelection = () => {
        const newSelectedPax = {};

        if (!wholeItinerarySelected) {
            itineraries.forEach(itinerary => {
                newSelectedPax[itinerary.id] = itinerary.passengers.map(pax => pax.id);
            });
        }

        setSelectedPax(newSelectedPax);
        setWholeItinerarySelected(!wholeItinerarySelected);
    };

    const calculateRefund = () => {
        const refundAmount = 78800;
        const cancellationFee = 1000;
        const serviceFee = 200;
        setRefundDetails({
            bookingAmount: refundAmount + cancellationFee + serviceFee,
            cancellationFee,
            serviceFee,
            refund: refundAmount
        });
    };

    useEffect(() => {
        calculateRefund();
    }, [selectedPax]);

    return (
        <>
            <Dialog className="" style={{ inset: "40px" }} open={voidPopUp} onClose={() => setVoidPopUp(false)} maxWidth="md" fullWidth >
                <DialogTitle>
                    <div className='d-flex justify-content-between'>
                        <div></div>
                        <div><Button onClick={() => setVoidPopUp(false)} color="inherit">
                            <CloseIcon />
                        </Button>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className=''>
                    <div className="void-booking">
                        <div className='d-flex justify-content-between'>
                            <div className='text-bold'>Void the Itinerary</div>
                            <div>
                                <Checkbox
                                    onChange={handleWholeItinerarySelection}
                                    checked={wholeItinerarySelected}
                                /> Select Whole Itinerary
                            </div>
                        </div>

                        <div >
                            {itineraries.map(itinerary => (
                                <div key={itinerary.id} className="itinerary mt-3 mb-3">
                                    <div className='d-flex justify-content-between border border-1 border-danger p-2 rounded'>
                                        <div>{itinerary.from} ---&gt; {itinerary.to}</div>
                                        <div>{itinerary.date}</div>
                                        <div> {itinerary.flight}</div>
                                        <div>PNR- {itinerary.pnr}</div>
                                        <div>
                                            <Checkbox
                                                onChange={() => handleSelectAll(itinerary.id)}
                                                checked={selectedPax[itinerary.id]?.length === itinerary.passengers.length}
                                                disabled={wholeItinerarySelected}
                                            /> Select All
                                        </div>
                                    </div>
                                    {itinerary.passengers.map(pax => (
                                        <div key={pax.id} className='d-flex justify-content-between mt-3 mb-3'>
                                            <div>
                                                <Checkbox
                                                    onChange={() => handleSelectPax(itinerary.id, pax.id, pax.ticketNumber)}
                                                    checked={selectedPax[itinerary.id]?.includes(pax.id) || false}
                                                    disabled={
                                                        wholeItinerarySelected ||
                                                        (disabledPax[itinerary.id]?.includes(pax.id)) // Disable based on ticket number logic
                                                    }
                                                />
                                            </div>
                                            <div>{pax.name} ({pax.type})</div>
                                            <div> Ticket Number: {pax.ticketNumber} </div>
                                            <div className='text-success'>{pax.status}</div>
                                        </div>
                                    ))}

                                </div>
                            ))}
                        </div>
                        <Divider sx={{ opacity: 1, borderBottomWidth: "medium" }} className='mt-5' />
                        <div className="refund mt-3">
                            <div className='text-bold'>Void Refund</div>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Booking Amount</TableCell>
                                            <TableCell align="center">Void/Cancellation Fee</TableCell>
                                            <TableCell align="center">Service Fee</TableCell>
                                            <TableCell align="center">Refund</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center">{refundDetails.bookingAmount}</TableCell>
                                            <TableCell align="center">{refundDetails.cancellationFee}</TableCell>
                                            <TableCell align="center">{refundDetails.serviceFee}</TableCell>
                                            <TableCell align="center">{refundDetails.refund}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>

                        <div className='mt-3'>
                            <p>
                                <span className='text-danger'>Note:</span><br></br>
                                1. If whole itinerary selected, then it will Auto Void the Tickets,<br></br>
                                2. Partial Void will create offline request
                            </p>
                        </div>

                        <div>
                            <div className='text-bold'>Remark</div>
                            <TextField className='border border-2 rounded'
                                sx={{ height: "6rem" }}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </div>

                        <div className='d-flex justify-content-between mt-3'>
                            <div>I accept the Terms and Conditions by clicking on Void Now</div>
                            <Button onClick={() => alert('Void Now clicked')} className='srn-btn'>Void Now</Button>
                        </div>

                    </div>
                </DialogContent >
            </Dialog >
        </>
    );
};

export default Void;
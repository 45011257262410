// import "./OfflineBooking.css";
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Button from "@material-ui/core/Button";
import axios from "axios";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import supplierList from "../../data/Supplier";
import findAirlinebyCode from "../../functions/findAirlineByCode";
import findAirportbyCode from "../../functions/findAirportByCode";
import getAirportByCode from "../../functions/getAirportByCode";
import getTax from "../../functions/getTax";
import getPaxTotal from "../../functions/getPaxTotal";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import AddPassengerDrawer from "./addPassengerDrawer";
import Drawer from "@material-ui/core/Drawer";
import Skeleton from "@material-ui/lab/Skeleton";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import cabinClass from "../../data/CabinClass";
import RiseLoading from "../Loader/RiseLoader";
import { isUserHasPermission } from "../../services/AuthService";
import Stack from '@mui/material/Stack';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FareDetailsSupplierTableComponent from "./fareDetailsSupplierTableComponent";
import FareDetailsClientTableComponent from "./fareDetailsClientTableComponent";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SupplierCard from "./supplierCard";
import SegmentForm from "./segmentForm";
import AddIcon from "@mui/icons-material/Add";
import PassengerForm from "./passengerForm";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Link from "@mui/material/Link";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { loadCreditCardDetails } from "../../services/apiService";
import loadFop from "../../functions/loadFop";
import { encryptData } from "../../providers/dataEncryptDecryptor";
import endEmailMandatoryClients from "../../data/EndEmailMandatoryClients";
import PreviewOfflineBooking from "./PreviewOfflineBooking";




dayjs.extend(utc);
dayjs.extend(timezone);
const today = dayjs.utc().tz("Europe/London");
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const initialBagagge = {
  checkInBag: "",
  cabinBag: "",
  cityPair: "",
  amount: 0,
};
const initialPassenger = {
  title: "",
  firstName: "",
  lastName: "",
  dob: "",
  ticketNumber: "",
  genderType: "",
  paxType: "ADT",
  passportNumber: "",
  baggage: [initialBagagge],
};
const initialFare = {
  paxType: "ADT",
  baseFare: 0,
  tax: 0,
  baggage: 0,
  markup: 0,
  vendorfee: 0,
  vendorTax: 0,
  totalFare: 0,
  vendorIATA: 0,
  vendorPLB: 0,
  clientIATA: 0,
  clientPLB: 0,
};
export default function OfflineBooking() {
  const [supplierName, setsupplierName] = useState("");
  const [pcc, setPCC] = useState("");
  const [pnr, setPNR] = useState("");
  const [clientId, setClientId] = useState("");
  const [bookingDetails, setbookingDetails] = useState(null);
  const [flights, setFlights] = useState(null);
  const [passengers, setPassengers] = useState([]);
  const [baggages, setBaggages] = useState([]);
  const [fareRows, setFareRows] = useState([]);
  const [clientProfiles, setClientProfiles] = useState(null);
  const [pccList, setPccList] = useState([]);
  const [adultCount, setadultCount] = useState(0);
  const [childCount, setChildCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);
  const [adultBase, setadultBase] = useState(0);
  const [childBase, setchildBase] = useState(0);
  const [infantBase, setinfantBase] = useState(0);
  const [adultTax, setadultTax] = useState(0);
  const [childTax, setchildTax] = useState(0);
  const [infantTax, setinfantTax] = useState(0);
  const [adultBaggage, setadultBaggage] = useState(0);
  const [childBaggage, setchildBaggage] = useState(0);
  const [infantBaggage, setinfantBaggage] = useState(0);
  const [adultBookingfee, setadultBookingfee] = useState(0);
  const [childBookingfee, setchildBookingfee] = useState(0);
  const [infantBookingfee, setinfantBookingfee] = useState(0);
  const [adultMarkup, setadultMarkup] = useState(0);
  const [childMarkup, setchildMarkup] = useState(0);
  const [infantMarkup, setinfantMarkup] = useState(0);
  const [adultIata, setadultIata] = useState(0);
  const [childIata, setchildIata] = useState(0);
  const [infantIata, setinfantIata] = useState(0);
  const [adultPlb, setadultPlb] = useState(0);
  const [childPlb, setchildPlb] = useState(0);
  const [infantPlb, setinfantPlb] = useState(0);
  const [adultTotal, setadultTotal] = useState(0);
  const [childTotal, setchildTotal] = useState(0);
  const [infantTotal, setinfantTotal] = useState(0);
  const [adultVendorTotal, setadultVendorTotal] = useState(0);
  const [childVendorTotal, setchildVendorTotal] = useState(0);
  const [infantVendorTotal, setinfantVendorTotal] = useState(0);
  const [TotalFare, setTotalFare] = useState(0);
  const [iataType, setiataType] = useState("");
  const [vendorIataType, setVendorIataType] = useState("");
  const [plbType, setPlbType] = useState("");
  const [vendorPlbType, setvendorPlbType] = useState("");
  const [clientBalanceSH, setClientBalanceSH] = useState(false);
  const [clientAvailableBalance, setClientAvailableBalance] = useState(0);
  const [clientTotalWithClientROE, setClientTotalWithClientROE] = useState(0);
  const [vendorTotal, setVendorTotal] = useState(0);
  const [clientTotal, setClientTotal] = useState(0);
  const [vendorTotalWithClientROE, setVendorTotalWithClientROE] = useState(0);
  const [adultVendorIata, setadultVendorIata] = useState(0);
  const [childVendorIata, setchildVendorIata] = useState(0);
  const [infantVendorIata, setinfantVendorIata] = useState(0);
  const [adultVendorPlb, setadultVendorPlb] = useState(0);
  const [childVendorPlb, setchildVendorPlb] = useState(0);
  const [infantVendorPlb, setinfantVendorPlb] = useState(0);
  const [adultVendorIssuanceFee, setadultVendorIssuanceFee] = useState(0);
  const [childVendorIssuanceFee, setchildVendorIssuanceFee] = useState(0);
  const [infantVendorIssuanceFee, setinfantVendorIssuanceFee] = useState(0);
  const [adultVendorTax, setadultVendorTax] = useState(0);
  const [childVendorTax, setchildVendorTax] = useState(0);
  const [infantVendorTax, setinfantVendorTax] = useState(0);
  const [pgCharge, setPGCharge] = useState("");
  const [mco, setMCO] = useState("");
  const [mcoParted, setMCOParted] = useState("");
  const [mcoRemark, setMCORemark] = useState("");
  const [fop, setFOP] = useState("");
  const [clientROE, setClientROE] = useState(1);
  const cabinOptions = cabinClass;
  const [openErrorMessageDialog, setOpenErrorMessageDialog] = useState(false);
  const [searchPNR, setSearchPNR] = useState(false);
  const [isImportBtnDisabled, setisImportBtnDisabled] = useState(
    (isImportBtnDisabled) => false
  );

  const [validateForm, setValidateForm] = useState(false)

  const [openErrorMessageHTML, setOpenErrorMessageHTML] = useState("");
  const [passengerTemplate, setPassengerTemplate] = useState(initialPassenger);
  const [fareTemplate, setFareTemplate] = useState({});
  const [selectedItem, setSelectedItem] = useState();
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("");
  const [currentTicket, setcurrentTicket] = useState({});
  const [selectedFlightIndex, setSelectedFlightIndex] = useState(0);
  const [salesStaff, setsalesStaff] = useState("");
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [caseID, setCaseID] = useState("");
  const [panCard, setpanCard] = useState("");
  const [fema, setfema] = useState("");
  const [selectedPassengerIndex, setSelectedPassengerIndex] = useState(0);
  const [segGroups, setSegGroups] = useState([]);
  const femaList = ["Corporate", "Retailer"];
  const [editSupplierName, setEditSupplierName] = useState("");
  const [editPCC, setEditPCC] = useState("");
  const [editClientId, setEditClientId] = useState("");
  const [userProfiles, setuserProfiles] = useState(null);
  const [expiryDate, setexpiryDate] = useState(null);
  const [cardNumber, setcardNumber] = useState(null);
  const [bookingRefNo, setBookingRefNo] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [vendorDetail, setVendorDetail] = useState(null);
  const [clientCurrency, setClientCurrency] = useState(null);
  const [vendorArray, setVendorArray] = useState([]);
  const [vendorOrgarray, setVendorOrgarray] = useState([]);
  const [pccOrgarray, setPccOrgarray] = useState([]);
  const ticketTypeList = ["Issuance", "ReIssuance", "Ancillary", "Group"];
  const [importPNRRemarks, setimportPNRRemarks] = useState("");
  const [onwardSegments, setOnwardSegments] = useState([]);
  const [returnSegments, setReturnSegments] = useState([]);
  const [selectedTripType, setSelectedTripType] = useState("onward");
  const [selectedType, setSelectedType] = useState("onward");
  const [reissue, setReissue] = useState(false);
  const [value, setValue] = useState("Fresh");
  const [disableImportBtn, setDisableImportBtn] = useState(false);
  const [bookingType, setBookingType] = useState("fitBooking");


  const [fopOptions, setFopOption] = useState([]);

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [selectDate, setSelectDate] = useState({
    departureDate: "",
    today: "",
    childLimit: "",
    infantLimit: "",
  });
  const [currentClientCountryCode, setcurrentClientCountryCode] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const sourceList = ["Email", "Call", "WhatsApp"];
  const [source, setSource] = useState("");


  const history = useHistory();
  const addFareRows = () => {
    // Get unique passenger types
    let paxTypes = [...new Set(passengers.map(p => p.paxType))];

    if (fareRows.length < paxTypes.length) {
      let temp = structuredClone(initialFare);
      temp.paxType = paxTypes[fareRows.length];
      let array = structuredClone(fareRows);
      array.push(temp);
      setFareRows(array);
    }
  };

  const deleteFareRows = (index) => {
    const updatedFareRows = fareRows.filter((_, i) => i !== index);
    setFareRows(updatedFareRows);
  };

  useEffect(() => {
    setisImportBtnDisabled((isImportBtnDisabled) => false);
    getClients();
    loadCreditCards();
    getStaffUsers();
    CalculateTotalFare();
    setPassengerTemplate((passengerTemplate) => initialPassenger);
    setFareTemplate({
      id: 0,
      flightId: 0,
      paxType: "",
      baseFare: 0,
      taxes: [
        {
          id: 0,
          amt: 0,
          code: "Other",
        },
      ],
    });
    let departureDate =
      bookingDetails?.flights[0]?.segGroups[0]?.departureOn.length > 0
        ? bookingDetails?.flights[0]?.segGroups[0].departureOn.split("T")[0]
        : new Date();
    let tempChildLimit = new Date(departureDate);
    let tempInfantLimit = new Date(departureDate);

    tempChildLimit.setFullYear(tempChildLimit.getFullYear() - 12);
    tempInfantLimit.setFullYear(tempInfantLimit.getFullYear() - 2);

    setSelectDate({
      departureDate: departureDate,
      today: new Date().toISOString().slice(0, 10),
      childLimit: tempChildLimit.toISOString().slice(0, 10),
      infantLimit: tempInfantLimit.toISOString().slice(0, 10),
    });
    // loadPNRDetails();
  }, []);

  useEffect(() => {
    getROE();
  }, [clientId, vendorDetail]);

  async function getROE() {
    if (vendorDetail && vendorDetail.currencyCode && clientCurrency) {
      setShowLoader((showLoader) => true);
      await axios
        .get(
          process.env.REACT_APP_IDENTITY_SERVER +
          "api/Configuration/ClientProfile/GetROEByClientId?id=" +
          clientId +
          "&suppCurrencyCode=" +
          vendorDetail.currencyCode
        )
        .then((response) => {
          setShowLoader((showLoader) => false);
          setClientROE((clientROE) => (response.data ? response.data : 1));
        })
        .catch((error) => {
          setShowLoader((showLoader) => false);
        });
    }
  }
  async function getStaffUsers() {
    let filter = "ClinetId==" + 0;
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "ids/User?filters=" + filter)
      .then((response) => {
        var newArray = response.data.filter(function (el) {
          return (
            el.emailConfirmed == true &&
            el.firstName != undefined &&
            el.lastName != undefined &&
            el.title != undefined
          );
        });
        setuserProfiles((userProfiles) => newArray);
      });

  }

  async function loadPNRDetails() {
    setSearchPNR(true);
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };

    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/RetrievePNR",

        JSON.stringify({
          PNR: pnr,
          Supplier:
            supplierName === "Travel Fusion" ? "Travelfusion" : supplierName,
          PCC: pcc,
          ClientId: clientId,
        }),
        { headers }
      )
      .then((res) => {
        console.log(res);
        setShowLoader(false);
        if (res.status === 204) {
          setAlert({
            open: true,
            message: "oops!! NO details for this PNR",
            severity: "warning",
          });
        }
        // setBookingFareDetails(res.data);
        // setbookingDetails((bookingDetails) => res.data);
        // setSegGroups((segGroups) => res?.data?.flights[0]?.segGroups);
        // setEditSupplierName((editSupplierName) => supplierName);
        // setEditPCC((editPCC) => pcc);
        // console.log(bookingDetails);
        // setSearchPNR((searchPNR) => false);
        // setShowLoader((showLoader) => false);
      })
      .catch((err) => {
        let errorMessage =
          err.response &&
            err.response.data &&
            err.response.data.error_description
            ? err.response.data.error_description
            : err.response && err.response.data
              ? err.response.data
              : err.response
                ? err.response
                : err;
        console.log(err);
        setAlert({
          open: true,
          message: "oops!! " + errorMessage,
          severity: "error",
        });
        setSearchPNR((searchPNR) => false);
        setShowLoader((showLoader) => false);
      });
  }
  const loadCreditCards = async () => {
    var fop = await loadFop();
    setFopOption(fop);
  };
  useEffect(() => {
    if (clientId) {
      let temp = clientProfiles?.find((v) => v?.id === clientId);
      if (temp) {
        setClientCurrency((clientCurrency) => temp.currencycode);
      }
    }
  }, [clientId, clientProfiles]);

  useEffect(() => {

    let vendorTotalFare = 0;
    let clientTotalFare = 0;

    fareRows?.map(row => {
      if (row.paxType === "ADT") {
        vendorTotalFare += adultVendorTotal;
      } else if (row.paxType === "CHD") {
        vendorTotalFare += childVendorTotal;
      } else if (row.paxType === "INF") {
        vendorTotalFare += infantVendorTotal;
      }
    });

    fareRows?.map(row => {
      if (row.paxType === "ADT") {
        clientTotalFare += adultTotal;
      } else if (row.paxType === "CHD") {
        clientTotalFare += childTotal;
      } else if (row.paxType === "INF") {
        clientTotalFare += infantTotal;
      }
    });

    setClientTotalWithClientROE((clientTotalWithClientROE) =>
      (clientTotalFare * clientROE).toFixed(2)
    );
    setVendorTotal((vendorTotal) => vendorTotalFare.toFixed(2));

  }, [fareRows]);

  async function getClients() {
    await axios
      // .get(process.env.REACT_APP_CORE_URL + "api/Configuration/ClientProfile")
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/GetClientList?sorts=created&filters=isactive==true"
      )
      .then((response) => {
        // console.log("clientProfiles", response);
        let tempArray = response.data.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );
        setClientProfiles((clientProfiles) => structuredClone(tempArray));
      });
  }

  async function loadPCCDetails(supplier) {
    let vendorarray = [];
    let selectedSupplier =
      supplier === "Travel Fusion" ? "Travelfusion" : supplier;
    if (selectedSupplier !== "") {
      await axios
        .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            vendorarray.push({
              id: response.data[i].bookingSwitches.id,
              name: String(
                response.data[i].bookingSwitches.vendorName
              ).toLowerCase(),
            });
          }

          let temp = [];
          temp = response.data.map((item, i) => {
            return item.bookingSwitches;
            // let name = String(
            //   response.data[i].bookingSwitches.vendorName
            // ).toLowerCase();
            // if (name === "travel fusion") {
            //   name = "travelfusion";
            // }
            // if (name === selectedSupplier.toLowerCase()) {
            //   setvendorDetail((vendorDetail) => item.bookingSwitches);
            //   console.log("vendorDetail", vendorDetail);
            // }
          });
          setVendorOrgarray((vendorOrgarray) => temp);
          axios
            .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
            .then((response) => {
              let tmpArray = [];
              let pccarray = [];
              for (var i = 0; i < response.data.length; i++) {
                let t = i;
                pccarray.push(response.data[t].pccConfigs);
                if (
                  response.data[t].pccConfigs.supplierName ===
                  selectedSupplier &&
                  response.data[t].pccConfigs.isActive === true
                ) {
                  if (
                    vendorarray.some(
                      (item) => item.id === response.data[t].pccConfigs.vendorId
                    )
                  ) {
                    let temp = vendorArray.find(
                      (st) => st.id === response.data[t].pccConfigs.vendorId
                    );
                    let name;
                    if (temp && temp.name === "travel fusion") {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (Travel Fusion)";
                    } else if (
                      response.data[t].pccConfigs.supplierName ===
                      "Travelfusion"
                    ) {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (Travel Fusion)";
                    } else if (temp && temp.name) {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (" +
                        temp.name +
                        ")";
                    } else {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (" +
                        response.data[t].pccConfigs.supplierName +
                        ")";
                    }
                    tmpArray.push({
                      value: response.data[t].pccConfigs.pccCode,
                      label: name,
                    });
                  } else {
                    tmpArray.push({
                      value: response.data[t].pccConfigs.pccCode,
                      label: response.data[t].pccConfigs.pccCode,
                    });
                  }
                }
              }

              if (tmpArray.length === 0) {
                setAlert({
                  open: true,
                  message: "There are no Pseudo Codes for this Supplier",
                  severity: "warning",
                });
              }
              setPccOrgarray((pccOrgarray) => pccarray);
              console.log("tmpArray", tmpArray);
              console.log("vendorarray", vendorarray);
              setVendorArray((vendorArray) => vendorarray);
              setPccList((pccList) => tmpArray);
            });
        });
    }
  }

  function CalculateTotalFare(totalPaxFare, getclientROE) {
    let getClientROEVal = 0;
    if (getclientROE !== undefined) {
      getClientROEVal = getclientROE;
    } else {
      getClientROEVal = clientROE;
    }
    const getTotalVendorIata = (
      adultVendorIata * adultCount +
      childVendorIata * childCount +
      infantVendorIata * infantCount
    ).toFixed(2);
    const getTotalVendorPLB = (
      adultVendorPlb * adultCount +
      childVendorPlb * childCount +
      infantVendorPlb * infantCount
    ).toFixed(2);

    let vendorTotalFare = 0;
    let clientTotalFare = 0;

    fareRows?.map(row => {
      if (row.paxType === "ADT") {
        vendorTotalFare += adultVendorTotal;
      } else if (row.paxType === "CHD") {
        vendorTotalFare += childVendorTotal;
      } else if (row.paxType === "INF") {
        vendorTotalFare += infantVendorTotal;
      }
    });

    fareRows?.map(row => {
      if (row.paxType === "ADT") {
        clientTotalFare += adultTotal;
      } else if (row.paxType === "CHD") {
        clientTotalFare += childTotal;
      } else if (row.paxType === "INF") {
        clientTotalFare += infantTotal;
      }
    });

    setClientTotal((clientTotal) => clientTotalFare.toFixed(2));
    setVendorTotal((vendorTotal) => vendorTotalFare.toFixed(2));
    setClientTotalWithClientROE((clientTotalWithClientROE) =>
      (clientTotalFare * getClientROEVal).toFixed(2)
    );
    setVendorTotalWithClientROE((vendorTotalFare) =>
      (vendorTotalFare * getClientROEVal).toFixed(2)
    );
  }

  useEffect(() => {
    CalculateTotalFare();
  }, [adultTotal, childTotal, infantTotal, adultVendorTotal, childVendorTotal, infantVendorTotal])


  async function OnChangeCalculateTotalFare(event, paxtype, field) {
    setiataType("value");
    setPlbType("value");
    setVendorIataType("value");
    setvendorPlbType("value");
    if (field === "iatatype") {
      let iataAdtValue = adultIata;
      let plbAdtValue = adultPlb;
      let iataChdValue = childIata;
      let plbChdValue = childPlb;
      if (event === "pct") {
        iataAdtValue = (adultBase * iataAdtValue) / 100;
        plbAdtValue = (adultBase * plbAdtValue) / 100;
        iataChdValue = (childBase * iataChdValue) / 100;
        plbChdValue = (childBase * plbChdValue) / 100;
      }

      setadultTotal(
        (adultBase +
          adultTax +
          adultBaggage +
          adultBookingfee +
          adultMarkup +
          adultVendorIssuanceFee +
          adultVendorTax) *
        adultCount
      );
      setchildTotal(
        (childBase +
          childTax +
          childBaggage +
          childBookingfee +
          childMarkup +
          adultVendorIssuanceFee +
          adultVendorTax) *
        childCount
      );
    }
    if (paxtype === "ADT") {
      let iataValue = adultIata;
      let plbValue = adultPlb;
      let vendorplbValue = adultVendorPlb;
      let vendoriataValue = adultVendorIata;
      if (field === "base") {
        setadultBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setadultTax(event.target.value !== "" ? Number(event.target.value) : 0);
      } else if (field === "baggage") {
        setadultBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setadultBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setadultMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        iataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          iataValue = (adultBase * iataValue) / 100;
        }
        setadultIata(iataValue);
      } else if (field === "plb") {
        plbValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          plbValue = ((adultBase - iataValue) * plbValue) / 100;
        }
        setadultPlb(plbValue);
      } else if (field === "vendorIata") {
        vendoriataValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendoriataValue = (adultBase * vendoriataValue) / 100;
        }
        setadultVendorIata(vendoriataValue);
      } else if (field === "vendorPlb") {
        vendorplbValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          vendorplbValue = ((adultBase - vendoriataValue) * plbValue) / 100;
        }
        setadultVendorPlb(vendorplbValue);
      } else if (field === "vendorIssuanceFee") {
        setadultVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        setadultVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
    } else if (paxtype === "CHD") {
      let iataValue = childIata;
      let plbValue = childPlb;
      let vendorplbValue = childVendorPlb;
      let vendoriataValue = childVendorIata;
      if (field === "base") {
        setchildBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setchildTax(event.target.value !== "" ? Number(event.target.value) : 0);
      } else if (field === "baggage") {
        setchildBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setchildBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setchildMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        iataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          iataValue = (childBase * iataValue) / 100;
        }
        setchildIata(iataValue);
      } else if (field === "plb") {
        plbValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          plbValue = ((childBase - iataValue) * plbValue) / 100;
        }
        setchildPlb(plbValue);
      } else if (field === "vendorIata") {
        vendoriataValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendoriataValue = (adultBase * vendoriataValue) / 100;
        }
        setchildVendorIata(vendoriataValue);
      } else if (field === "vendorPlb") {
        vendorplbValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendorplbValue =
            ((childBase - vendoriataValue) * vendorplbValue) / 100;
        }
        setchildVendorPlb(vendorplbValue);
      } else if (field === "vendorIssuanceFee") {
        setchildVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        setchildVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
    } else if (paxtype === "INF") {
      if (field === "base") {
        setinfantBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setinfantTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "baggage") {
        setinfantBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setinfantBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setinfantMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        setinfantIata(0);
      } else if (field === "plb") {
        setinfantPlb(0);
      } else if (field === "vendorIata") {
        setinfantVendorIata(0);
        // vendoriataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        // if (iataType === "pct") {
        //   vendoriataValue = ( infantBase* vendoriataValue) / 100;
        // }
        // setinfantVendorIata(
        //   vendoriataValue
        // );
      } else if (field === "vendorPlb") {
        setinfantVendorPlb(0);
      } else if (field === "vendorIssuanceFee") {
        setinfantVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        setinfantVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
    }
    CalculateTotalFare();
  }
  useEffect(() => {
    CalculateTotalFare();
  }, [adultCount, childCount, infantCount]);

  useEffect(() => {
    let count1 = 0,
      count2 = 0,
      count3 = 0,
      adtBaggage = 0,
      chdBaggage = 0,
      infBaggage = 0;
    if (passengers && passengers.length) {
      passengers.forEach((passenger) => {
        if (passenger.paxType === "ADT") {
          count1++;
        } else if (passenger.paxType === "CHD") {
          count2++;
        } else if (passenger.paxType === "INF") {
          count3++;
        }
      });
      setadultCount((adultCount) => count1);
      setChildCount((childCount) => count2);
      setInfantCount((infantCount) => count3);
    }
    let passData = structuredClone(passengers);
    passData &&
      passData.forEach((passenger) => {
        if (passenger.baggage.length > 0) {
          let temp = [];
          temp = passenger.baggage;
          temp.forEach((item) => {
            passenger.paxType === "ADT"
              ? (adtBaggage += parseInt(item.amount))
              : passenger.paxType === "CHD"
                ? (chdBaggage += parseInt(item.amount))
                : passenger.paxType === "INF" &&
                (infBaggage += parseInt(item.amount));
            console.log("adtBaggage", adtBaggage);
          });
        }
      });
    let abag = 0,
      cbag = 0,
      ibag = 0;
    abag = parseInt(adtBaggage) / count1;
    cbag = parseInt(chdBaggage) / count2;
    ibag = parseInt(infBaggage) / count3;
    count1 && adtBaggage > 0 && setadultBaggage((adultBaggage) => abag);
    count2 && chdBaggage > 0 && setchildBaggage((childBaggage) => cbag);
    count3 && infBaggage > 0 && setinfantBaggage((infantBaggage) => ibag);

    const filteredPassengers = passengers.filter(
      (passenger) => passenger.inputType == "E"
    );
    if (filteredPassengers.length !== 0) {
      setDisableImportBtn(true);
    } else {
      setDisableImportBtn(false);
    }
  }, [passengers]);

  // useEffect(() => {
  //   console.log("vendorDetail", vendorDetail);
  // }, [vendorDetail]);
  // useEffect(() => {
  //   console.log("supplier Currency", vendorDetail?.currencyCode);
  // }, [vendorDetail]);
  // useEffect(() => {
  //   console.log("client Currency", clientCurrency);
  // }, [clientCurrency]);
  const handleChange = (event, newValue) => {
    setValue((value) => newValue);
    newValue === "Fresh"
      ? setReissue((prevalue) => false)
      : setReissue((prevalue) => true);
  };
  useEffect(() => {
    // console.log("pcc", pcc);
    let vendor;
    if (pcc) {
      pccOrgarray?.forEach((element) => {
        if (element.pccCode === pcc) {
          vendorOrgarray.forEach((item) => {
            if (item.id === element.vendorId) {
              vendor = item;
              setVendorDetail(vendor);
            }
          });
        }
      });
    }
  }, [pcc, pccOrgarray, vendorOrgarray]);

  useEffect(() => {
    let totalAudFare =
      adultBase +
      adultTax +
      adultBaggage +
      adultMarkup +
      adultVendorIssuanceFee +
      adultVendorTax - adultIata - adultPlb;
    setadultTotal(totalAudFare * adultCount);
    setadultVendorTotal(
      (adultBase +
        adultTax +
        adultBaggage +
        adultVendorIssuanceFee +
        adultVendorTax - adultVendorIata - adultVendorPlb) *
      adultCount
    );
  }, [
    adultBase,
    adultTax,
    adultBaggage,
    adultMarkup,
    adultVendorIssuanceFee,
    adultVendorTax,
    adultVendorIata,
    adultVendorPlb,
    adultIata,
    adultPlb,
    adultCount,
  ]);

  useEffect(() => {
    let totalChildFare =
      childBase +
      childTax +
      childBaggage +
      childMarkup +
      childVendorIssuanceFee +
      childVendorTax - childIata - childPlb;
    setchildTotal(totalChildFare * childCount);
    setchildVendorTotal(
      (childBase +
        childTax +
        childBaggage +
        childVendorIssuanceFee +
        childVendorTax - childVendorIata - childVendorPlb) *
      childCount
    );
  }, [
    childBase,
    childTax,
    childBaggage,
    childBookingfee,
    childMarkup,
    childVendorIssuanceFee,
    childVendorTax,
    childVendorIata,
    childVendorPlb,
    childIata,
    childPlb,
    childCount,
  ]);

  useEffect(() => {
    let totalinfantFare =
      infantBase +
      infantTax +
      infantBaggage +
      infantMarkup +
      infantVendorIssuanceFee +
      infantVendorTax - infantIata - infantPlb;
    setinfantTotal(totalinfantFare * infantCount);
    setinfantVendorTotal(
      (infantBase +
        infantTax +
        infantBaggage +
        infantVendorIssuanceFee +
        infantVendorTax - infantVendorIata - infantVendorPlb) *
      infantCount
    );
  }, [
    infantBase,
    infantTax,
    infantBaggage,
    infantBookingfee,
    infantMarkup,
    infantVendorIssuanceFee,
    infantVendorTax,
    infantVendorIata,
    infantVendorPlb,
    infantIata,
    infantPlb,
    infantCount,
  ]);

  useEffect(() => {
    console.log('childBase', childBase)
  }, [childBase]);

  const handleSubmit = () => {

    if (
      supplierName.trim() !== '' &&
      pcc.trim() !== '' &&

      clientId &&

      onwardSegments[0].airline.trim() !== '' &&
      onwardSegments[0].flightNo.trim() !== '' &&
      onwardSegments[0].originAirport.trim() !== '' &&

      onwardSegments[0].destinationAirport.trim() !== '' &&

      onwardSegments[0].depDate.$d &&
      onwardSegments[0].depTime.$d &&
      onwardSegments[0].arrDate.$d &&
      onwardSegments[0].arrTime.$d &&
      onwardSegments[0].cabinClass.trim() !== '' &&
      onwardSegments[0].bookingClass.trim() !== '' &&
      onwardSegments[0].gdsPNR.trim() !== '' &&
      onwardSegments[0].airlinePNR.trim() !== '' &&

      passengers[0].paxType.trim() !== '' &&
      passengers[0].title.trim() !== '' &&
      passengers[0].firstName.trim() !== '' &&
      passengers[0].lastName.trim() !== '' &&

      passengers[0].genderType.trim() !== '' &&
      passengers[0].ticketNumber &&
      passengers[0].passportNumber &&
      // bag.amount.trim() !== '' &&

      fop &&
      clientROE &&
      salesStaff.trim() !== ''
    ) {
      setValidateForm(true);
    } else {
      setValidateForm(false);
    }
  };



  const handleCloseErrorMessageDialog = () => {
    setOpenErrorMessageDialog(false);
  };
  const handleSnackbarToggle = () => {
    setShowLoader(true);
    console.log(passengerTemplate);
    setDrawer(false);
    setShowLoader(false);
  };
  // function setDayJSDateTime(calDate, time) {
  //
  //   let date = dayjs(calDate);
  //   let hour = dayjs(time).get("hour");
  //   let minute = dayjs(time).get("minute");
  //   let second = dayjs(time).get("second");
  //   let temp = date
  //     .set("hour", hour)
  //     .set("minute", minute)
  //     .set("second", second);
  //   return temp;
  // }
  function formatDayJsdate(date, time) {
    let caldate = dayjs(date.$d);
    let hour = dayjs(time.$d).get("hour");
    let minute = dayjs(time.$d).get("minute");
    let second = dayjs(time.$d).get("second");
    let temp = caldate
      .set("hour", hour)
      .set("minute", minute)
      .set("second", second);
    return dayjs(temp).utc(true).format();
  }
  useEffect(() => {
    setisImportBtnDisabled((isImportBtnDisabled) => true);
    if (selectedTripType === "onward" && onwardSegments.length > 0 && passengers.length > 0 && fareRows.length > 0) {
      setisImportBtnDisabled((isImportBtnDisabled) => false);
    } else if (selectedTripType === "return" && onwardSegments.length > 0 && returnSegments.length > 0 && passengers.length > 0 && fareRows.length > 0) {
      setisImportBtnDisabled((isImportBtnDisabled) => false);
    }
  }, [onwardSegments, returnSegments, passengers, fareRows, selectedTripType])
  async function CreateImportBooking() {
    setisImportBtnDisabled((isImportBtnDisabled) => true);
    let flightFareList = [];
    let purchaseIds = [];
    // console.log("importPNRRemarks", importPNRRemarks);
    // bookingDetails.flights?.map((item) => {
    //   purchaseIds.push(item.purchaseId);
    // });
    let onwardSeg = [];
    let returnSeg = [];
    let clonedOnwardSegments = structuredClone(onwardSegments);
    let clonedReturnSegments = structuredClone(returnSegments);
    clonedOnwardSegments.forEach((item) => {
      const segment = {
        airline: "",
        flightNo: "",
        originAirport: "",
        originTerminal: "",
        destinationAirport: "",
        destinationTerminal: "",
        depDate: null,
        arrDate: null,
        cabinClass: "",
        bookingClass: "",
        gdsPNR: "",
        airlinePNR: "",
      };
      let temp = structuredClone(item);
      temp.arrDate = formatDayJsdate(
        structuredClone(item.arrDate),
        structuredClone(item.arrTime)
      );
      temp.depDate = formatDayJsdate(
        structuredClone(item.depDate),
        structuredClone(item.depTime)
      );
      segment.airline = temp.airline;
      segment.flightNo = temp.flightNo;
      segment.originAirport = temp.originAirport;
      segment.originTerminal = temp.originTerminal;
      segment.destinationAirport = temp.destinationAirport;
      segment.destinationTerminal = temp.destinationTerminal;
      segment.depDate = temp.depDate;
      segment.arrDate = temp.arrDate;
      segment.cabinClass = temp.cabinClass;
      segment.bookingClass = temp.bookingClass;
      segment.gdsPNR = temp.gdsPNR;
      segment.airlinePNR = temp.airlinePNR;
      onwardSeg.push(segment);
    });
    clonedReturnSegments.forEach((item) => {
      const segment = {
        airline: "",
        flightNo: "",
        originAirport: "",
        originTerminal: "",
        destinationAirport: "",
        destinationTerminal: "",
        depDate: null,
        arrDate: null,
        cabinClass: "",
        bookingClass: "",
        gdsPNR: "",
        airlinePNR: "",
      };
      let temp = structuredClone(item);
      temp.arrDate = formatDayJsdate(
        structuredClone(item.arrDate),
        structuredClone(item.arrTime)
      );
      temp.depDate = formatDayJsdate(
        structuredClone(item.depDate),
        structuredClone(item.depTime)
      );
      segment.airline = temp.airline;
      segment.flightNo = temp.flightNo;
      segment.originAirport = temp.originAirport;
      segment.originTerminal = temp.originTerminal;
      segment.destinationAirport = temp.destinationAirport;
      segment.destinationTerminal = temp.destinationTerminal;
      segment.depDate = temp.depDate;
      segment.arrDate = temp.arrDate;
      segment.cabinClass = temp.cabinClass;
      segment.bookingClass = temp.bookingClass;
      segment.gdsPNR = temp.gdsPNR;
      segment.airlinePNR = temp.airlinePNR;
      returnSeg.push(segment);
    });
    if (adultCount > 0) {
      flightFareList.push({
        paxtype: "ADT",
        base: adultBase,
        tax: adultTax,
        baggage: adultBaggage,
        markup: adultMarkup,
        bookingfee: adultBookingfee,
        iata: adultIata,
        plb: adultPlb,
        vendorIATA: adultVendorIata,
        vendorPLB: adultVendorPlb,
        vendorIssuanceFee: adultVendorIssuanceFee,
        vendorTax: adultVendorTax,
      });
    }

    if (childCount > 0) {
      flightFareList.push({
        paxtype: "CHD",
        base: childBase,
        tax: childTax,
        baggage: childBaggage,
        markup: childMarkup,
        bookingfee: childBookingfee,
        iata: childIata,
        plb: childPlb,
        vendorIATA: childVendorIata,
        vendorPLB: childVendorPlb,
        vendorIssuanceFee: childVendorIssuanceFee,
        vendorTax: childVendorTax,
      });
    }

    if (infantCount > 0) {
      flightFareList.push({
        paxtype: "INF",
        base: infantBase,
        tax: infantTax,
        baggage: infantBaggage,
        markup: infantMarkup,
        bookingfee: infantBookingfee,
        iata: 0,
        plb: 0,
        vendorIATA: infantVendorIata,
        vendorPLB: 0,
        vendorIssuanceFee: infantVendorIssuanceFee,
        vendorTax: infantVendorTax,
      });
    }

    setShowLoader(true);

    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/CreatePNR/v1/OfflineBooking",
        JSON.stringify({
          flightFare: flightFareList,
          pgCharge: parseFloat(pgCharge),
          mco: parseFloat(mco),
          mcoParted: parseFloat(mcoParted),
          mcoRemark: mcoRemark,
          fop: fop,
          caseID: caseID,
          salesStaff: salesStaff,
          clientEmail: clientEmail,
          clientName: clientName,
          fema: fema,
          isCorporate: fema === "Corporate" ? true : false,
          panCard: panCard,
          clientROE: clientROE,
          totalFare: clientTotalWithClientROE,
          purchaseIds: purchaseIds,
          passengers: passengers,
          onwardSegments: onwardSeg,
          returnSegments: returnSeg,
          supplierName: supplierName,
          PCC: pcc,
          clientId: clientId,
          expiryDate: expiryDate ? expiryDate : "",
          cardNumber: cardNumber ? cardNumber : "",
          info: importPNRRemarks?.trim() + (source ? " %%%" + source + "%%%" : ""),
          ticketType: reissue ? "R" : "F",
          bookingType: bookingType === "fitBooking" ? 1 : 2
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (typeof response.body === "string") {
          if (response.data.orderId) {
            sessionStorage.setItem("orderId", encryptData(response.data.orderId));

            history.push("/flight/view-booked-tickets");
          }
        } else {
          if (response.data.orderId) {
            sessionStorage.setItem("orderId", encryptData(response.data.orderId));

            history.push("/flight/view-booked-tickets");
          }
        }
        setisImportBtnDisabled((isImportBtnDisabled) => false);
        setShowLoader(false);
      })
      .catch((error) => {
        setisImportBtnDisabled((isImportBtnDisabled) => false);
        console.log(error);
        setOpenErrorMessageHTML(error.response.data.detail);
        setOpenErrorMessageDialog(true);
        setShowLoader(false);
      });
  }

  const toggleDrawer = (value) => (event) => {
    console.log("toggleDrawer");
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(value);
    // if (value === false) {
    //   if (action === 'edit') {
    //     editPassenger();
    //   } else{
    //   addPassenger();
    //   }
    // }
  };
  const handlecabinClassChange = (
    event,
    value,
    flightIndex,
    segGroupIndex,
    segmentIndex
  ) => {
    const temp = [...segGroups];
    temp[segGroupIndex].segments[segmentIndex].cabinClass = value;
    setSegGroups(temp);
  };
  const handlePNRChange = (event, flightIndex, segGroupIndex, segmentIndex) => {
    const temp = [...segGroups];
    temp[segGroupIndex].segments[segmentIndex].pnr = event.target.value;
    setSegGroups(temp);
  };
  const openAddDrawer = () => {
    setSelectedItem("");
    setDrawer(true);
  };
  const openEditDrawer = (index2) => {
    setSelectedPassengerIndex((selectedPassengerIndex) => index2);
    setSelectedItem((selectedItem) => passengers[index2]);
    setDrawer(true);
  };

  async function deletePassenger(index2) {
    let temp;
    if (passengers.length > 1) {
      temp = structuredClone(passengers);
      temp.splice(index2, 1);
    } else {
      temp = [];
    }
    await setPassengers((passengers) => temp);
    console.log("passengers", passengers);
  }
  useEffect(() => {
    if (excelData.length > 0) {
      setPassengers((prevPassengers) => [...prevPassengers, ...excelData]);
    }
  }, [excelData]);
  useEffect(() => {
    modpass();
  }, [passengerTemplate]);
  function modpass() {
    let temp = structuredClone(passengers);
    if (action === "add" || action === "edit") {
      if (passengerTemplate.id !== undefined) {
        if (action === "add") {
          temp?.push(passengerTemplate);
        } else if (action === "edit") {
          temp[selectedPassengerIndex] = passengerTemplate;
        }
        setPassengers((passengers) => temp);
      }
    }
  }
  useEffect(() => {
    console.log("editSupplierName", editSupplierName);
    console.log("editPCC", editPCC);
    console.log("editClientId", editClientId);
  }, [editSupplierName, editPCC, editClientId]);

  useEffect(() => {
    if (passengers && passengers.length > 0) {
      const paxTypes = [...new Set(passengers.map(p => p.paxType))];

      const updatedFareRows = paxTypes.map((type, index) => {
        const existingRow = fareRows.find(row => row.paxType === type);
        if (existingRow) {
          return existingRow;
        } else {
          const newFareRow = structuredClone(initialFare);
          newFareRow.paxType = type;
          return newFareRow;
        }
      });

      setFareRows(updatedFareRows);
    }
    else {
      setFareRows([])
    }
  }, [passengers]);

  const handleClose = () => {
    setValidateForm(false);
  };




  return (
    <div className="offline-booking-container">
      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={10000}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Tabs
        value={value}
        textColor="primary"
        indicatorColor="primary"
        onChange={handleChange}
        variant="fullWidth"
      >
        <Tab
          className="review-itenary-container-header-details"
          value="Fresh"
          label="Fresh"
        />
        <Tab
          className="review-itenary-container-header-details"
          value="ReIssue"
          label="ReIssue "
        />
      </Tabs>
      <Grid item xs={12} style={{ maxWidth: "90vw", margin: "0px 5vw" }}>
        <div className="offline-flight-itenary">
          <Paper
            elevation={1}
            className="OfflineBookingPaper"
            sx={{ padding: "0px" }}
            style={{ padding: "0px" }}
          >
            <div className="flight-itenary-header">
              <div className="flight-itenary-title-container">
                Offline Booking
              </div>
            </div>
            {clientProfiles !== undefined && clientProfiles?.length > 0 ? (
              <div className="importbooking-search-search-container">
                <div className="importbooking-search-table-container">
                  <div className="search-title-add-button-container">
                    <div className="search-heading"></div>
                  </div>
                  <div className="importbooking-search-card-container">
                    <SupplierCard
                      setsupplierName={setsupplierName}
                      setPCC={setPCC}
                      setPNR={setPNR}
                      setClientId={setClientId}
                      clientProfiles={clientProfiles}
                      setClientProfiles={setClientProfiles}
                      pccList={pccList}
                      loadPNRDetails={loadPNRDetails}
                      loadPCCDetails={loadPCCDetails}
                      supplierName={supplierName}
                      clientId={clientId}
                      pcc={pcc}
                      pnr={pnr}
                      setClientAvailableBalance={setClientAvailableBalance}
                      clientAvailableBalance={clientAvailableBalance}
                      clientBalanceSH={clientBalanceSH}
                      showLoader={showLoader}
                      setShowLoader={setShowLoader}
                      clientCurrency={clientCurrency}
                      setBookingType={setBookingType}
                      bookingType={bookingType}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="table-loading">
                  <div style={{ width: "100%", margin: "5px" }}>
                    <Skeleton width="100%" height={24} animation="wave" />
                  </div>
                  <div style={{ width: "100%", margin: "5px" }}>
                    <Skeleton width="100%" height={24} animation="wave" />
                  </div>
                  <div style={{ width: "100%", margin: "5px" }}>
                    <Skeleton width="100%" height={24} animation="wave" />
                  </div>
                  <div style={{ width: "100%", margin: "5px" }}>
                    <Skeleton width="100%" height={24} animation="wave" />
                  </div>
                </div>
              </div>
            )}
          </Paper>
          {clientProfiles !== undefined && clientProfiles?.length > 0 ? (
            <Paper className="OfflineBookingPaper" elevation={1}>
              <div className="">
                <ValidatorForm
                  // onSubmit={CreateImportBooking}
                  onSubmit={handleSubmit}
                  onError={(errors) => console.log(errors)}
                >
                  <div className="offline-itenary-container">
                    <SegmentForm
                      onwardSegments={onwardSegments}
                      returnSegments={returnSegments}
                      selectedTripType={selectedTripType}
                      selectedType={selectedType}
                      setOnwardSegments={setOnwardSegments}
                      setReturnSegments={setReturnSegments}
                      setSelectedTripType={setSelectedTripType}
                      setSelectedType={setSelectedType}
                    />
                  </div>
                  <PassengerForm
                    onwardSegments={onwardSegments}
                    returnSegments={returnSegments}
                    setAction={setAction}
                    openAddDrawer={openAddDrawer}
                    passengers={passengers}
                    openEditDrawer={openEditDrawer}
                    deletePassenger={deletePassenger}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    selectedPassengerIndex={selectedPassengerIndex}
                    setSelectedPassengerIndex={setSelectedPassengerIndex}
                    drawer={drawer}
                    toggleDrawer={toggleDrawer}
                    setPassengerTemplate={setPassengerTemplate}
                    handleSnackbarToggle={handleSnackbarToggle}
                    selectedTripType={selectedTripType}
                    action={action}
                    selectDate={selectDate}
                    setDrawer={setDrawer}
                    showLoader={showLoader}
                    setShowLoader={setShowLoader}
                    setPassengers={setPassengers}
                    excelData={excelData}
                    setExcelData={setExcelData}
                    disableImportBtn={disableImportBtn}
                    addFareRows={addFareRows}
                  />

                  <div className="offline-itenary-container">
                    <FareDetailsSupplierTableComponent
                      fareRows={fareRows}
                      addFareRows={addFareRows}
                      deleteFareRows={deleteFareRows}
                      CalculateTotalFare={CalculateTotalFare}
                      OnChangeCalculateTotalFare={OnChangeCalculateTotalFare}
                      adultBase={adultBase}
                      adultTax={adultTax}
                      adultBaggage={adultBaggage}
                      childBase={childBase}
                      childTax={childTax}
                      childBaggage={childBaggage}
                      infantBase={infantBase}
                      infantTax={infantTax}
                      infantBaggage={infantBaggage}
                      adultVendorIssuanceFee={adultVendorIssuanceFee}
                      childVendorIssuanceFee={childVendorIssuanceFee}
                      infantVendorIssuanceFee={infantVendorIssuanceFee}
                      adultMarkup={adultMarkup}
                      childMarkup={childMarkup}
                      infantMarkup={infantMarkup}
                      adultVendorTax={adultVendorTax}
                      childVendorTax={childVendorTax}
                      infantVendorTax={infantVendorTax}
                      adultVendorTotal={adultVendorTotal}
                      childVendorTotal={childVendorTotal}
                      infantVendorTotal={infantVendorTotal}
                      adultIata={adultIata}
                      childIata={childIata}
                      infantIata={infantIata}
                      adultVendorIata={adultVendorIata}
                      childVendorIata={childVendorIata}
                      infantVendorIata={infantVendorIata}
                      adultPlb={adultPlb}
                      childPlb={childPlb}
                      infantPlb={infantPlb}
                      adultVendorPlb={adultVendorPlb}
                      childVendorPlb={childVendorPlb}
                      infantVendorPlb={infantVendorPlb}
                      passengers={passengers}
                      currency={
                        vendorDetail && vendorDetail.currencyCode
                          ? vendorDetail.currencyCode
                          : ""
                      }
                    />
                  </div>

                  <div className="offline-itenary-container">
                    <FareDetailsClientTableComponent
                      fareRows={fareRows}
                      addFareRows={addFareRows}
                      deleteFareRows={deleteFareRows}
                      CalculateTotalFare={CalculateTotalFare}
                      OnChangeCalculateTotalFare={OnChangeCalculateTotalFare}
                      adultBase={adultBase}
                      adultTax={adultTax}
                      adultBaggage={adultBaggage}
                      childBase={childBase}
                      childTax={childTax}
                      childBaggage={childBaggage}
                      infantBase={infantBase}
                      infantTax={infantTax}
                      infantBaggage={infantBaggage}
                      adultVendorIssuanceFee={adultVendorIssuanceFee}
                      childVendorIssuanceFee={childVendorIssuanceFee}
                      infantVendorIssuanceFee={infantVendorIssuanceFee}
                      adultMarkup={adultMarkup}
                      childMarkup={childMarkup}
                      infantMarkup={infantMarkup}
                      adultVendorTax={adultVendorTax}
                      childVendorTax={childVendorTax}
                      infantVendorTax={infantVendorTax}
                      adultTotal={adultTotal}
                      childTotal={childTotal}
                      infantTotal={infantTotal}
                      adultIata={adultIata}
                      childIata={childIata}
                      infantIata={infantIata}
                      adultVendorIata={adultVendorIata}
                      childVendorIata={childVendorIata}
                      infantVendorIata={infantVendorIata}
                      adultPlb={adultPlb}
                      childPlb={childPlb}
                      infantPlb={infantPlb}
                      adultVendorPlb={adultVendorPlb}
                      childVendorPlb={childVendorPlb}
                      infantVendorPlb={infantVendorPlb}
                      currency={clientCurrency ? clientCurrency : ""}
                      ROE={clientROE}
                    />
                  </div>

                  <div className="import-booking-mco mco-dtls-bg mb-4">
                    <Row className="mx-1">
                      <div
                        className="col-md-3 FOP"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <Autocomplete
                          id="FOP-select"
                          variant="outlined"
                          value={
                            fop != ""
                              ? fopOptions.find((v) => v.id === fop)
                              : null
                          }
                          onChange={(event, newValue) => {
                            setFOP(newValue ? newValue.id : "");
                          }}
                          options={fopOptions}
                          getOptionLabel={(option) => option.des}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label="FOP"
                              required
                              value={fop}
                              variant="filled"
                              color="secondary"
                              size="small"
                              validators={["required"]}
                              errorMessages={["required"]}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </div>
                      {fop == "Passenger Card" && (
                        <div
                          className="col-md-3"
                          style={{
                            marginTop: "10px",
                            marginBottom: "15px",
                          }}
                        >
                          <TextValidator
                            id="outlined-clientROE-input"
                            required={
                              fop == "Passenger Card" ? ["required"] : false
                            }
                            label="Card number"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            value={cardNumber}
                            onChange={(event) =>
                              setcardNumber(event.target.value)
                            }
                            validators={["required"]}
                            errorMessages={["required"]}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      )}
                      {fop == "Passenger Card" && (
                        <div
                          className="col-md-3"
                          style={{
                            marginTop: "10px",
                            marginBottom: "15px",
                          }}
                        >
                          <TextValidator
                            id="outlined-clientROE-input"
                            required={
                              fop == "Passenger Card" ? ["required"] : false
                            }
                            label="Expiry Date"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            value={expiryDate}
                            onChange={(event) =>
                              setexpiryDate(event.target.value)
                            }
                            validators={["required"]}
                            errorMessages={["required"]}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      )}
                      <div
                        className="col-md-3"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <TextValidator
                          id="outlined-clientROE-input"
                          label="Client ROE"
                          required
                          variant="outlined"
                          size="small"
                          color="secondary"
                          value={clientROE}
                          onBlur={() => CalculateTotalFare()}
                          onChange={(event) => {
                            setClientROE((clientROE) => event.target.value);
                          }}
                          validators={["required"]}
                          errorMessages={["required"]}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div
                        className="col-md-3"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <TextValidator
                          id="outlined-clientROE-input"
                          label="Case ID"
                          variant="outlined"
                          size="small"
                          required
                          color="secondary"
                          value={caseID}
                          onChange={(event) => setCaseID(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          validators={["required", 'matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$']}
                          errorMessages={["This field is required", "This field cannot be blank or contain only spaces"]}
                        />
                      </div>
                      <div
                        className="col-md-3 salesStaff"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        {/* <TextValidator
                                id="outlined-clientROE-input"
                                label="Sales Staff"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={salesStaff}
                                onChange={(event) =>
                                  setsalesStaff(event.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              /> */}
                        <Autocomplete
                          id="FOP-select"
                          variant="outlined"
                          value={
                            salesStaff !== undefined &&
                            userProfiles?.find((el) => el.id === salesStaff)
                          }
                          onChange={(event, newValue) => {
                            setsalesStaff(newValue ? newValue.id : "");
                          }}
                          options={userProfiles}
                          getOptionLabel={(option) =>
                            option.title +
                            "." +
                            option.firstName +
                            " " +
                            option.lastName
                          }
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label="Sales Staff"
                              required
                              // salesStaff !== undefined ? userProfiles.find((v) => v.id == salesStaff) : ""
                              value={salesStaff}
                              variant="filled"
                              color="secondary"
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              validators={["required"]}
                              errorMessages={["required"]}
                            />
                          )}
                        />
                      </div>
                      <div
                        className="col-md-3"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <TextValidator
                          required={
                            endEmailMandatoryClients.includes(clientId.toString())
                          }
                          id="outlined-clientROE-input"
                          label="End Client Name"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          value={clientName}
                          onChange={(event) =>
                            setclientName(event.target.value)
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div
                        className="col-md-3"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <TextValidator
                          // required={
                          //   endEmailMandatoryClients.includes(clientId.toString())
                          // }
                          required
                          id="outlined-clientROE-input"
                          label="END Client Email / Akbar Online OD"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          value={clientEmail}
                          onChange={(event) =>
                            setclientEmail(event.target.value)
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          validators={["required", 'matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$']}
                          errorMessages={["This field is required", "This field cannot be blank or contain only spaces"]}
                        />
                      </div>
                      <div
                        className="col-md-3 fema"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <Autocomplete
                          id="FOP-select"
                          variant="outlined"
                          value={fema && femaList.find((el) => el === fema)}
                          onChange={(event, newValue) => {
                            setfema(newValue ? newValue : "");
                          }}
                          options={femaList}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label="Corporate/Retailer"
                              value={fema}
                              variant="filled"
                              color="secondary"
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </div>
                      <div
                        className="col-md-3"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <TextValidator
                          required={fema === "Retailer" ? ["required"] : false}
                          id="outlined-clientROE-input"
                          label="Pan Card"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          value={panCard}
                          onChange={(event) => setpanCard(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          validators={[
                            "matchRegexp:^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
                          ]}
                          errorMessages={[
                            "Not in valid format - 1st 5 Letters in CAPS, then Next 4 Letters in Numbers & Last Letter in CAPS",
                          ]}
                        />
                      </div>

                      {/* MCO fields */}

                      <div
                        className="col-md-3"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <TextValidator
                          id="outlined-mco-input"
                          label="MCO Number"
                          type="number"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          value={mco}
                          onChange={(event) => setMCO(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                      </div>
                      <div
                        className="col-md-3"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <TextValidator
                          id="outlined-pgcharge-input"
                          label="MCO Charge"
                          type="number"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          value={pgCharge}
                          onChange={(event) => setPGCharge(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                      </div>
                      <div
                        className="col-md-3 fema"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <TextValidator
                          id="outlined-mcoParted-input"
                          label="MCO Parting"
                          type="number"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          value={mcoParted}
                          onChange={(event) => setMCOParted(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                      </div>
                      <div
                        className="col-md-3"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <TextValidator
                          id="outlined-mcoRemark-input"
                          label="MCO Remark"
                          type="text"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          value={mcoRemark}
                          onChange={(event) => setMCORemark(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>

                      {/* MCO fields */}
                      <div
                        className="col-md-3"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <Autocomplete
                          id="FOP-select"
                          variant="outlined"
                          value={source && sourceList.find((el) => el === source)}
                          onChange={(event, newValue) => {
                            setSource(newValue ? newValue : "");
                          }}
                          options={sourceList}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label="Source of Booking"
                              required
                              value={source}
                              variant="filled"
                              color="secondary"
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              validators={["required"]}
                              errorMessages={["required"]}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row className="mx-1">
                      <div
                        className="col-md-3 align-self-center"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      ></div>
                      <div
                        className="col-md-3 align-self-center"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      ></div>
                      <div
                        className="col-md-3 align-self-center"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="import-booking-fare-item-total">
                          {" "}
                          <span style={{ marginRight: "5px" }}>
                            Client Payable:
                          </span>{" "}
                          {clientCurrency ? clientCurrency : " "}{" "}
                          {clientTotalWithClientROE}
                        </div>
                      </div>
                      <div
                        className="col-md-3 align-self-center"
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="import-booking-fare-item-total">
                          <span style={{ marginRight: "5px" }}>
                            Supplier Payable:
                          </span>{" "}
                          {vendorDetail && vendorDetail.currencyCode
                            ? vendorDetail.currencyCode
                            : " "}{" "}
                          {vendorTotal}
                        </div>
                      </div>
                    </Row>
                    <div className="import-booking-remarks">
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Remarks"
                          multiline
                          maxRows={4}
                          placeholder="Max 300 Characters"
                          sx={{
                            whiteSpace: "pre-wrap",
                            maxWidth: "98.5%",
                            minHeight: "100px !important",
                          }}
                          variant="outlined"
                          value={importPNRRemarks}
                          inputProps={{ maxLength: 300 }}
                          onChange={(event) => {
                            setimportPNRRemarks(
                              (importPNRRemarks) => event?.target?.value
                            );
                          }}
                        />
                      </Box>
                    </div>
                    {clientTotalWithClientROE > clientAvailableBalance ? (
                      <>
                        <Row>
                          <div className="col-6 offset-md-3 mt-4 mb-1 warning-msg-txt text-center">
                            Unable to create booking, due insufficient client
                            balance.
                          </div>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {clientTotalWithClientROE < clientAvailableBalance ? (
                    <>
                      {/* <div className="create-booking-button-container">
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          style={{
                            textTransform: "none",
                            //marginLeft: "415px",
                          }}
                          type="submit"
                          disabled={isImportBtnDisabled}
                        >
                          Create Booking
                        </Button>
                      </div> */}

                      <div className="create-booking-button-container">
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          style={{
                            textTransform: "none",
                            marginBottom: "50px",
                            marginRight: "100px",
                          }}
                          type="submit"
                          disabled={isImportBtnDisabled}

                        // onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* </Paper> */}
                </ValidatorForm>
              </div>
            </Paper>
          ) : (
            <div>
              <div className="table-loading">
                <div style={{ width: "100%", margin: "5px" }}>
                  <Skeleton width="100%" height={24} animation="wave" />
                </div>
                <div style={{ width: "100%", margin: "5px" }}>
                  <Skeleton width="100%" height={24} animation="wave" />
                </div>
                <div style={{ width: "100%", margin: "5px" }}>
                  <Skeleton width="100%" height={24} animation="wave" />
                </div>
                <div style={{ width: "100%", margin: "5px" }}>
                  <Skeleton width="100%" height={24} animation="wave" />
                </div>
              </div>
            </div>
          )}
        </div>
      </Grid>
      {/* No Sufficient Client Balance Dialog Box */}
      <Dialog
        open={openErrorMessageDialog}
        onClose={handleCloseErrorMessageDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
        className="error-dialog-box"
      >
        <DialogTitle id="error-dialog-title" className="main-heading-cls">
          Notification
        </DialogTitle>
        <DialogContent className="mt-3 py-2">
          <DialogContentText id="error-dialog-description">
            <Row>
              <div className="col-12 sub-heading-cls mb-2">
                Dear Travel Partner
              </div>
              <div className="normal-txt-cls">
                <p className="sub-heading-cls">{openErrorMessageHTML}</p>
                <p>
                  Kindly contact our Customer Success Group at{" "}
                  <a href="mailto:customersuccess@getfares.com">
                    customersuccess@getfares.com
                  </a>{" "}
                  to assist you and proceed towards a successful booking.
                </p>
              </div>
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="error-dialog-action text-center my-2">
          <Button variant="outlined" onClick={handleCloseErrorMessageDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={validateForm}
        onClose={() => setValidateForm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false} // Disable default maxWidth
        fullWidth={true} // Enable full width

      >




        <DialogContent style={{ maxHeight: '70vh', overflow: 'auto' }} >
          <PreviewOfflineBooking
            supplierName={supplierName}
            pcc={pcc}
            pnr={pnr}
            clientId={clientId}
            onwardSegments={onwardSegments}
            returnSegments={returnSegments}
            passengers={passengers}
            adultBase={adultBase}
            adultTax={adultTax}
            adultBaggage={adultBaggage}
            adultMarkup={adultMarkup}
            adultVendorIssuanceFee={adultVendorIssuanceFee}
            adultVendorTotal={adultVendorTotal}
            adultVendorIata={adultVendorIata}
            adultVendorTax={adultVendorTax}
            adultVendorPlb={adultVendorPlb}
            adultIata={adultIata}
            adultPlb={adultPlb}
            adultCount={adultCount}
            childBase={childBase}
            childTax={childTax}
            childBaggage={childBaggage}
            childMarkup={childMarkup}
            childVendorIssuanceFee={childVendorIssuanceFee}
            childVendorTax={childVendorTax}
            childVendorTotal={childVendorTotal}
            childVendorIata={childVendorIata}
            childVendorPlb={childVendorPlb}
            childIata={childIata}
            childPlb={childPlb}
            childCount={childCount}
            infantMarkup={infantMarkup}
            infantBase={infantBase}
            infantTax={infantTax}
            infantBaggage={infantBaggage}
            infantVendorIssuanceFee={infantVendorIssuanceFee}
            infantVendorTotal={infantVendorTotal}
            infantVendorIata={infantVendorIata}
            infantVendorTax={infantVendorTax}
            adultTotal={adultTotal}
            childTotal={childTotal}
            infantTotal={infantTotal}
            infantVendorPlb={infantVendorPlb}
            infantIata={infantIata}
            infantPlb={infantPlb}
            infantCount={infantCount}
            fareRows={fareRows}
            fop={fop}
            fopOptions={fopOptions}
            clientROE={clientROE}
            caseID={caseID}
            salesStaff={salesStaff}
            clientEmail={clientEmail}
            fema={fema}
            cardNumber={cardNumber}
            expiryDate={expiryDate}
            panCard={panCard}
            mco={mco}
            pgCharge={pgCharge}
            mcoParted={mcoParted}
            mcoRemark={mcoRemark}
            importPNRRemarks={importPNRRemarks}
            bookingType={bookingType}
            selectedTripType={selectedTripType}
            clientProfiles={clientProfiles}
            userProfiles={userProfiles}
            clientAvailableBalance={clientAvailableBalance}
            clientTotalWithClientROE={clientTotalWithClientROE}
            vendorTotal={vendorTotal}
            clientCurrency={clientCurrency}
            vendorCurrencyCode={vendorDetail && vendorDetail.currencyCode}
            value={value}
            cabinClass={cabinClass}
            endClientName={clientName}
            setValidateForm={setValidateForm}
            clientsCurrency={clientCurrency}
            currency={
              vendorDetail && vendorDetail.currencyCode
                ? vendorDetail.currencyCode
                : ""
            }
            source={source}
          />
        </DialogContent>
        <DialogActions style={{ marginRight: '100px' }}>

          <Stack direction="row" spacing={2} >
            <Button variant="contained" onClick={() => setValidateForm(false)}>Edit</Button>
            <Button variant="contained" color="primary" autoFocus onClick={() => CreateImportBooking()}>Create Booking</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}
